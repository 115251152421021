import React, { useContext, useEffect, useState } from "react";
import { Form, Table, Input, Col, Row, Select, Button } from "antd";
import axios from "axios";
import { loginAccess } from "../..";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";

export default function WorkHistory() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Search } = Input;
  const [employeeHistory, setEmployeeHistory] = useState([]);
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [appSection, setAppSection] = useState([]);
  const [formName, setFormName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [tableRecords, setTableRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/employee/employeeHistory/${isLoggedIn?.loggedInUser?.employee_id}/`,
        {
          headers: authHeader(),
        }
      )
      .then((data) => setEmployeeHistory(data?.data));
  }, [isLoggedIn]);
  useEffect(() => {
    axios
      .get(
        `${baseUrl}/retailer/orderTakingEmployeeHistory/${isLoggedIn?.loggedInUser?.employee_id}`,
        {
          headers: authHeader(),
          params: {
            page: currentPage,
            filter: filter,
          },
        }
      )
      .then((response) => {
        setTableRecords(response?.data.results || []);
        setTotalItems(response?.data.count || 0);
      })
      .catch((error) => console.log(error));
  }, [currentPage, filter, isLoggedIn?.loggedInUser?.employee_id]);

  const handleAppSection = (value) => {
    setAppSection(value);
    setFormName([]);
  };

  const handleRegistrationHistory = (value) => {
    setFormName(value);
    setLoading(true);
    if (value.toLowerCase() === "lsp") {
      const lspRecords = employeeHistory.filter(
        (element) => element.lsp !== null
      );
      setTableRecords(lspRecords);
      if (lspRecords.length > 0) {
        setLoading(false);
        const lspColumns = [
          {
            title: `LSP Registration Details`,
            dataIndex: "lsp",
            render: (record) => {
              const name = record?.name;
              const phone = record?.phone;
              const Institution = record?.institution_name;
              return (
                <>
                  <p>
                    <strong>Name: {name}</strong>
                    <br />
                    Phone: 0{phone}
                    <br />
                    Institution: {Institution}
                  </p>
                </>
              );
            },
          },
          {
            title: "Date",
            dataIndex: "created_at",
            render: (record) => {
              const date = record;
              const dateFormat = new Date(date).toLocaleDateString();
              return <p>{dateFormat}</p>;
            },
          },
        ];
        setColumns(lspColumns);
      } else {
        console.log("Not found");
        setColumns([]);
      }
    }
    if (value.toLowerCase() === "farmer") {
      const farmerRecords = employeeHistory.filter(
        (element) => element.farmer !== null
      );
      setTableRecords(farmerRecords);
      console.log(tableRecords);
      if (farmerRecords.length > 0) {
        setLoading(false);
        const farmerColumns = [
          {
            title: `Farmer Registration Details`,
            dataIndex: "farmer",
            render: (farmer) => {
              const name = farmer?.name;
              const phone = farmer?.phone;
              return (
                <>
                  <p>
                    <strong>Name: {name}</strong>
                    <br />
                    Phone: 0{phone}
                  </p>
                </>
              );
            },
          },
          {
            title: "Date",
            dataIndex: "created_at", // Use "created_at" as dataIndex to access the date
            render: (record) => {
              const date = record;
              const dateFormat = new Date(date).toLocaleDateString();
              return <p>{dateFormat}</p>;
            },
          },
        ];

        setColumns(farmerColumns); // Set the columns for the Table
      } else {
        setColumns([]);
      }
    }
    if (value.toLowerCase() === "asp") {
      const aspRecords = employeeHistory.filter(
        (element) => element.asp !== null
      );
      setTableRecords(aspRecords);
      if (aspRecords.length > 0) {
        setLoading(false);
        const aspRecords = [
          {
            title: `ASP Registration Details`,
            dataIndex: "asp",
            render: (asp) => {
              const name = asp?.name;
              const phone = asp?.phone;
              return (
                <>
                  <p>
                    <strong>Name: {name}</strong>
                    <br />
                    Phone: 0{phone}
                  </p>
                </>
              );
            },
          },
          {
            title: "Date",
            dataIndex: "created_at", // Use "created_at" as dataIndex to access the date
            render: (record) => {
              const date = record;
              const dateFormat = new Date(date).toLocaleDateString();
              return <p>{dateFormat}</p>;
            },
          },
        ];

        setColumns(aspRecords); // Set the columns for the Table
      } else {
        setColumns([]);
      }
    }

    if (value.toLowerCase() === "trader") {
      const traderRecords = employeeHistory.filter(
        (element) => element.trader !== null
      );
      setTableRecords(traderRecords);
      if (traderRecords.length > 0) {
        setLoading(false);
        const traderRecords = [
          {
            title: `Trader Registration Details`,
            dataIndex: "trader",
            render: (trader) => {
              const name = trader?.name;
              const phone = trader?.phone;
              return (
                <>
                  <p>
                    <strong>Name: {name}</strong>
                    <br />
                    Phone: 0{phone}
                  </p>
                </>
              );
            },
          },
          {
            title: "Date",
            dataIndex: "created_at", // Use "created_at" as dataIndex to access the date
            render: (record) => {
              const date = record;
              const dateFormat = new Date(date).toLocaleDateString();
              return <p>{dateFormat}</p>;
            },
          },
        ];

        setColumns(traderRecords); // Set the columns for the Table
      } else {
        setColumns([]);
      }
    }
    if (value.toLowerCase() === "retailer") {
      const retailerRecords = employeeHistory.filter(
        (element) => element.retailer !== null
      );
      setTableRecords(retailerRecords);
      if (retailerRecords.length > 0) {
        setLoading(false);
        const retailerRecords = [
          {
            title: `Retailer Registration Details`,
            dataIndex: "retailer",
            render: (retailer) => {
              const name = retailer?.name;
              const phone = retailer?.phone;
              return (
                <>
                  <p>
                    <strong>Name: {name}</strong>
                    <br />
                    Phone: 0{phone}
                  </p>
                </>
              );
            },
          },
          {
            title: "Date",
            dataIndex: "created_at", // Use "created_at" as dataIndex to access the date
            render: (record) => {
              const date = record;
              const dateFormat = new Date(date).toLocaleDateString();
              return <p>{dateFormat}</p>;
            },
          },
        ];

        setColumns(retailerRecords); // Set the columns for the Table
      } else {
        setColumns([]);
      }
    }
  };

  const handleOrderTakingHistory = (value) => {
    setFormName(value);
    setLoading(true);
    if (value.toLowerCase() === "order taking") {
      if (tableRecords.length > 0) {
        setLoading(false);
        const orderTakingColumns = [
          {
            title: `Order Taking Details`,
            dataIndex: "",
            render: (record) => {
              const order_id = record?.order_id;
              const order_type = record?.order_type;
              const ordered_products = record?.ordered_products
                ?.map(
                  (item) =>
                    item.product.product_name_english +
                    " -- " +
                    item.quantity +
                    " pc"
                )
                .join(", ");
              return (
                <>
                  <p>
                    <strong>Order ID: {order_id}</strong>
                    <br />
                    <strong>Order Type:</strong> {order_type}
                    <br />
                    <strong>Ordered Products:</strong> {ordered_products}
                  </p>
                </>
              );
            },
          },
          {
            title: "Date",
            dataIndex: "created_at",
            render: (record) => {
              const date = record;
              const dateFormat = new Date(date).toLocaleDateString();
              return <p>{dateFormat}</p>;
            },
          },
        ];
        setColumns(orderTakingColumns);
      } else {
        console.log("Not found");
        setColumns([]);
      }
    }
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container" style={{ marginTop: "80px" }}>
      <Form className="register-form" form={form} layout="vertical">
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item hasFeedback label="App Section">
              <Select
                placeholder="Select app section"
                onChange={handleAppSection}
              >
                <Option value="Registration">Registration</Option>
                <Option value="KYC">KYC</Option>
                <Option value="Survey">Survey</Option>
                <Option value="Consultation">Consultation</Option>
                <Option value="Sourcing">Sourcing</Option>
                <Option value="Contract Farming">Contract Farming</Option>
                <Option value="Order Taking">Order Taking</Option>
              </Select>
            </Form.Item>
          </Col>
          {appSection.includes("Registration") && (
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item hasFeedback label="Form Name">
                <Select
                  placeholder="Select form name"
                  onChange={handleRegistrationHistory}
                >
                  <Option value="Farmer">Farmer</Option>
                  <Option value="Retailer">Retailer</Option>
                  <Option value="LSP">LSP</Option>
                  <Option value="ASP">ASP</Option>
                  <Option value="Trader">Trader</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          {appSection.includes("KYC") && (
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item hasFeedback label="Form Name">
                <Select placeholder="Select form name">
                  <Option value="Farmer">Farmer</Option>
                  <Option value="Retailer">Retailer</Option>
                  <Option value="LSP">LSP</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          {appSection.includes("Survey") && (
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item hasFeedback label="Form Name">
                <Select placeholder="Select form name">
                  <Option value="Price App">Price App</Option>
                  <Option value="Rice Mill">Rice Mill</Option>
                  <Option value="Dairy">Dairy</Option>
                  <Option value="Beef">Beef</Option>
                  <Option value="Agro Processor">Agro Processor</Option>
                  <Option value="Crop">Crop</Option>
                  <Option value="Poultry">Poultry</Option>
                  <Option value="Fishery">Fishery</Option>
                  <Option value="Warehouse">Warehouse</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          {appSection.includes("Sourcing") && (
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item hasFeedback label="Form Name">
                <Select placeholder="Select form name">
                  <Option value="Work Order">Work Order</Option>
                  <Option value="Product Sourcing">Product Sourcing</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          {appSection.includes("Contract Farming") && (
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item hasFeedback label="Form Name">
                <Select placeholder="Select form name">
                  <Option value="Vaccine Demand">Vaccine Demand</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          {appSection.includes("Order Taking") && (
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item hasFeedback label="Form Name">
                <Select
                  placeholder="Select form name"
                  onChange={handleOrderTakingHistory}
                >
                  <Option value="Order Taking">Order Taking</Option>
                  <Option value="Order Fulfillment">Order Fulfillment</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item>
            <Search
              placeholder="search here..."
              allowClear
              onSearch={handleFilterChange}
              enterButton={
                <Button
                  type="primary"
                  style={{ backgroundColor: "#38512e", borderColor: "#38512e" }}
                >
                  Search
                </Button>
              }
            />
          </Form.Item>
        </Col>
      </Form>
      {(formName.includes("Farmer") ||
        formName.includes("LSP") ||
        formName.includes("Trader") ||
        formName.includes("ASP") ||
        formName.includes("Retailer") ||
        formName.includes("Order Taking")) && (
        <Table
          columns={columns}
          dataSource={tableRecords}
          loading={loading}
          rowKey={(record) => record.id}
          pagination={{
            current: currentPage,
            total: totalItems,
            pageSize: 10, // Set the number of items per page
            onChange: handlePageChange,
          }}
        />
      )}
    </div>
  );
}
