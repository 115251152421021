import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Space,
  Upload,
  Divider,
} from "antd";
import { loginAccess } from "../../..";
import Swal from "sweetalert2";
import "./Order.css";
import awsS3 from "aws-sdk/clients/s3";
import { UploadOutlined } from "@ant-design/icons";

export default function OrderFulfillment() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState([]);
  const [orderTypeWiseData, setOrderTypeWiseData] = useState([]);
  const [viewInvoiceField, setViewInvoiceField] = useState(false);
  const [viewSelectOrderField, setViewSelectOrderField] = useState(false);
  const [viewOrderTaken, setViewOrderTaken] = useState(false);
  const [viewOrderFulfillment, setOrderFulfillment] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [salesInfoLoading, setSalesInfoLoading] = useState(false);

  const today = new Date();
  const todaysDate = new Date(today);
  todaysDate.setDate(today.getDate());
  const orderFulfillmentDate = todaysDate.toISOString().slice(0, 10);

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/retailer/orderTakingTypeData/?order_type=${orderType}`, {
        headers: authHeader(),
      })
      .then((response) => {
        setOrderTypeWiseData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching order type data:", error);
      });
  }, [orderType]);

  const handleOrderTypeChange = (value) => {
    setOrderType(value);
    const excludedFields = [
      "order_date",
      "order_type",
      "employee_full_name",
      "employee_id",
    ];
    const formFields = form.getFieldsValue();
    const resetFields = Object.keys(formFields).filter(
      (field) => !excludedFields.includes(field)
    );
    form.resetFields(resetFields);
    setViewInvoiceField(false);
    setViewSelectOrderField(true);
  };
  const handleOrderSelect = (value) => {
    const selectedOrderDetails = orderTypeWiseData?.find(
      (order) => order.order_id === value
    );
    const modifiedOrderTakenProducts =
      selectedOrderDetails?.ordered_products?.map((product) => ({
        ...product,
        product_name: product.product.product_name_english,
      }));
    setViewOrderTaken(true);
    if (orderType.toLowerCase() === "farmer order") {
      form.setFieldsValue({
        farmer_name: selectedOrderDetails?.farmer?.name,
        phoneNumber: "0" + selectedOrderDetails?.farmer?.phone,
        farmer_id: selectedOrderDetails?.farmer?.farmer_id,
        district: selectedOrderDetails?.district,
        order_taken_products: modifiedOrderTakenProducts,
      });
    }
    if (orderType.toLowerCase() === "retailer order") {
      form.setFieldsValue({
        retailer_name: selectedOrderDetails?.retailer?.name,
        phoneNumber: "0" + selectedOrderDetails?.retailer?.phone,
        retailer_id: selectedOrderDetails?.retailer?.retailer_id,
        district: selectedOrderDetails?.district,
        order_taken_products: modifiedOrderTakenProducts,
      });
    }
    if (orderType.toLowerCase() === "agrigate center order") {
      form.setFieldsValue({
        agrigate_center_name: selectedOrderDetails?.agrigate_center_name,
        district: selectedOrderDetails?.district,
        order_taken_products: modifiedOrderTakenProducts,
      });
    }
    setViewInvoiceField(true);
  };

  const handleSalesInfo = (e) => {
    const sale_id = e.target.value;
    if (sale_id.length === 12) {
      setSalesInfoLoading(true);
      axios
        .get(`${baseUrl}/inventory/sales_data/${sale_id}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          console.log(data);
          setSalesInfoLoading(false);
          if (data?.data != null) {
            setOrderFulfillment(true);
            const invoiceData = data?.data;
            const modifiedProducts = invoiceData?.invoice_products?.map(
              (product) => ({
                ...product,
                product_name: product.product.product_name,
              })
            );
            form.setFieldsValue({
              products: modifiedProducts,
              total_sales_amount: invoiceData?.total_price,
              total_due: invoiceData?.due,
              total_paid: invoiceData?.total_paid,
            });
          } else {
            setOrderFulfillment(false);
            form.setFieldsValue({
              products: null,
              total_discount: null,
              total_sales_amount: null,
              total_due: null,
              total_paid: null,
            });
            Swal.fire({
              title: "Error",
              text: "Invoice not found",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          console.log(error)
          setOrderFulfillment(false);
          setSalesInfoLoading(false);
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.error,
            icon: "error",
            confirmButtonText: "OK",
          });
          
        });
    } else {
      setOrderFulfillment(false);
      setSalesInfoLoading(false);
      form.setFieldsValue({
        products: null,
        total_discount: null,
        total_sales_amount: null,
        total_due: null,
        total_paid: null,
      });
    }
  };

  const handleSubmit = (data) => {
    data.invoice_image = imageUrl;
    setLoading(true);
    axios
      .post(
        `${baseUrl}/retailer/orderFulfillment/`,
        {
          data: data,
        },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        console.log(response?.data);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: "Order fulfill successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.resetFields(resetFields);
            setOrderType([]);
            setOrderTypeWiseData([]);
            setViewSelectOrderField(false);
            setViewInvoiceField(false);
            setViewOrderTaken(false);
            setOrderFulfillment(false);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Order Fulfillment Form
      </header>
      <Spin tip="Loading..." spinning={salesInfoLoading}>
        <div className="container m-auto">
          <Form
            className="register-form"
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Order Fulfillment Date"
                  name="fulfillment_date"
                  rules={[
                    {
                      required: true,
                      message: "Order Fulfillment date required",
                    },
                  ]}
                  initialValue={orderFulfillmentDate}
                >
                  <Input placeholder="Order Taking Date" readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Order Type"
                  name="order_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select order type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select order type"
                    onChange={handleOrderTypeChange}
                  >
                    <Option value="Retailer Order">Retailer Order</Option>
                    <Option value="Farmer Order">Farmer Order</Option>
                    <Option value="Agrigate Center Order">
                      Agrigate Center Order
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              {viewSelectOrderField && (
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Select Order"
                    name="order_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select order",
                      },
                    ]}
                  >
                    <Select
                      placeholder="select order"
                      onChange={handleOrderSelect}
                      showSearch
                    >
                      {orderTypeWiseData?.map((item, index) => (
                        <Option value={item?.order_id} key={index}>
                          {item?.order_id}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {viewInvoiceField && (
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Sale ID"
                    name="sale_id"
                    rules={[
                      {
                        required: true,
                        message: "Please enter sale id",
                      },
                    ]}
                  >
                    <Input placeholder="Sale ID" onChange={handleSalesInfo} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {orderType.includes("Farmer Order") && (
              <>
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      hasFeedback
                      label="Farmer Name"
                      name="farmer_name"
                      rules={[
                        {
                          required: true,
                          message: "Farmer name required",
                        },
                      ]}
                    >
                      <Input placeholder="Farmer Name" readOnly />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      hasFeedback
                      label="Farmer Phone Number"
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter farmer's phone number",
                        },
                      ]}
                    >
                      <Input placeholder="Phone Number" readOnly />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      hasFeedback
                      label="Farmer ID"
                      name="farmer_id"
                      rules={[
                        {
                          required: true,
                          message: "Farmer ID required",
                        },
                      ]}
                    >
                      <Input placeholder="Farmer ID" type="tel" readOnly />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      hasFeedback
                      label="District"
                      name="district"
                      rules={[
                        {
                          required: true,
                          message: "District is required",
                        },
                      ]}
                    >
                      <Input placeholder="District" readOnly />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {orderType.includes("Retailer Order") && (
              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Retailer Name"
                    name="retailer_name"
                    rules={[
                      {
                        required: true,
                        message: "Retailer's name required",
                      },
                    ]}
                  >
                    <Input placeholder="Retailer Name" readOnly />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Retailer Phone Number"
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Retailer's phone number required",
                      },
                    ]}
                  >
                    <Input placeholder="Retailer Phone Number" readOnly />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Retailer ID"
                    name="retailer_id"
                    rules={[
                      {
                        required: true,
                        message: "Retailer ID is required",
                      },
                    ]}
                  >
                    <Input placeholder="Retailer ID" readOnly />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="District"
                    name="district"
                    rules={[
                      {
                        required: true,
                        message: "District is required",
                      },
                    ]}
                  >
                    <Input placeholder="District" readOnly />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {orderType.includes("Agrigate Center Order") && (
              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="District"
                    name="district"
                    rules={[
                      {
                        required: true,
                        message: "District required",
                      },
                    ]}
                  >
                    <Input placeholder="District" readOnly />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Agrigate Center Name"
                    name="agrigate_center_name"
                    rules={[
                      {
                        required: true,
                        message: "Agrigate center name required",
                      },
                    ]}
                  >
                    <Input placeholder="Agrigate center name" readOnly />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {viewOrderTaken && (
              <p>
                <strong>Order Taken Details:</strong>
              </p>
            )}
            <Form.List name="order_taken_products">
              {(fields) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Space
                      key={key}
                      style={{
                        marginBottom: 20,
                        border: "dashed 1px gray",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Row gutter={[8, 16, 24, 32]}>
                        <Col xs={18} sm={8} md={8} lg={6}>
                          <Form.Item
                            {...restField}
                            label="Product Name"
                            name={[name, `product_name`]}
                            rules={[
                              {
                                required: true,
                                message: "Product name is required",
                              },
                            ]}
                          >
                            <Input placeholder="Product name" readOnly />
                          </Form.Item>
                        </Col>
                        <Col xs={18} sm={8} md={8} lg={6}>
                          <Form.Item
                            {...restField}
                            label="Quantity"
                            name={[name, `quantity`]}
                            rules={[
                              {
                                required: true,
                                message: "Quantity is required",
                              },
                            ]}
                          >
                            <Input placeholder="Quantity" readOnly />
                          </Form.Item>
                        </Col>
                        <Col xs={18} sm={8} md={8} lg={6}>
                          <Form.Item
                            {...restField}
                            label="Product Total"
                            name={[name, `product_total_price`]}
                            rules={[
                              {
                                required: true,
                                message: "Product Total Price required",
                              },
                            ]}
                          >
                            <Input placeholder="Product Total Price" readOnly />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  ))}
                </>
              )}
            </Form.List>

            {viewOrderFulfillment && (
              <p>
                <strong>Order Fullfilment Details:</strong>
              </p>
            )}
            <Form.List name="products">
              {(fields) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Space
                      key={key}
                      style={{
                        marginBottom: 20,
                        border: "dashed 1px gray",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Row gutter={[8, 16, 24, 32]}>
                        <Col xs={18} sm={8} md={8} lg={6}>
                          <Form.Item
                            {...restField}
                            label="Product Name"
                            name={[name, `product_name`]}
                            rules={[
                              {
                                required: true,
                                message: "Product name is required",
                              },
                            ]}
                          >
                            <Input placeholder="Product name" readOnly />
                          </Form.Item>
                        </Col>
                        <Col xs={18} sm={8} md={8} lg={6}>
                          <Form.Item
                            {...restField}
                            label="Quantity"
                            name={[name, `quantity`]}
                            rules={[
                              {
                                required: true,
                                message: "Quantity is required",
                              },
                            ]}
                          >
                            <Input placeholder="Quantity" readOnly />
                          </Form.Item>
                        </Col>
                        <Col xs={18} sm={8} md={8} lg={6}>
                          <Form.Item
                            {...restField}
                            label="Product Total"
                            name={[name, `product_total_price`]}
                            rules={[
                              {
                                required: true,
                                message: "Product Total Price required",
                              },
                            ]}
                          >
                            <Input placeholder="Product Total Price" readOnly />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Total Price"
                  name="total_sales_amount"
                  rules={[
                    {
                      required: true, // Regular expression to match English characters and spaces
                      message: "Total price required",
                    },
                  ]}
                >
                  <Input placeholder="Total Price" readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Total Paid"
                  name="total_paid"
                  rules={[
                    {
                      required: true, 
                      message: "Total paid required",
                    },
                  ]}
                >
                  <Input placeholder="Total Paid" readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Balance"
                  name="total_due"
                  initialValue={0}
                >
                  <Input placeholder="Due balance" readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Invoice Image"
                  name="invoice_image"
                  valuePropName="fileList"
                  placeholder="Upload invoice image"
                  getValueFromEvent={(e) => e.fileList}
                  rules={[
                    { required: true, message: "Please upload invoice image" },
                    {
                      validator: (_, value) => {
                        if (value && value.length > 0) {
                          const file = value[0];
                          const fileType = file.type;
                          const acceptedFormats = [
                            "image/jpeg",
                            "image/png",
                            "image/jpg",
                            "image/heic",
                          ];
                          if (acceptedFormats.includes(fileType)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                            );
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={() => false}
                    onChange={(info) => handleImageUpload(info, setImageUrl)}
                    listType="picture"
                  >
                    <Button
                      type="primary"
                      icon={<UploadOutlined />}
                      loading={uploading}
                    >
                      {uploading ? "Uploading" : "Upload Invoice Image"}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Remarks"
                  name="remarks"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^[A-Za-z\s]+$/, // Regular expression to match English characters and spaces
                      message: "Only English Characters",
                    },
                  ]}
                >
                  <Input placeholder="Remarks" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              {/* Field Officer Name */}
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Field Officer Name"
                  name="employee_full_name"
                  rules={[{ required: true }]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              {/* Field Officer ID */}
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Field Officer ID"
                  name="employee_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select field officer ID",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item>
                <Button type="primary" htmlType="submit" readOnly={loading}>
                  Submit
                  {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </div>
      </Spin>
    </div>
  );
}
