import React, { useContext, useEffect, useState } from "react";
import "../Form.css";
import { loginAccess } from "../../..";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Space,
  Upload,
  Spin,
  Select,
} from "antd";
import Address from "../../CommonProperty/Address";
import {
  EnvironmentOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import awsS3 from "aws-sdk/clients/s3";
import Swal from "sweetalert2";
import authHeader from "../../Authentication/authHeader";
import baseUrl from "../../../config";
import axios from "axios";

export default function ASPRegistrationForm() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [aspProfileImage, setAspProfileImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [interested, setInterested] = useState([]);
  const [machineServiceName, setMachineServiceName] = useState([]);

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          form.setFieldsValue({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleInterested = (value) => {
    setInterested(value);
  };
  const handleMachineNameChange = (value, index) => {
    const updatedMachineServiceName = [...machineServiceName];
    console.log(updatedMachineServiceName, "1");
    updatedMachineServiceName[index] = value;
    console.log(updatedMachineServiceName, "2");
    setMachineServiceName(updatedMachineServiceName);
    console.log(machineServiceName, "3");
  };

  const handleSubmit = (data) => {
    if (data.services) {
      data.asp_image = aspProfileImage;
      setLoading(true);
      axios
        .post(
          `${baseUrl}/asp/aspRegistration/`,
          {
            data: data,
          },
          {
            headers: {
              ...authHeader(),
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response?.status === 200) {
            Swal.fire({
              title: "Success",
              text: "ASP Registration Success",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              const excludedFields = ["employee_id", "employee_full_name"]; 
              const formFields = form.getFieldsValue();
              const resetFields = Object.keys(formFields).filter(
                (field) => !excludedFields.includes(field)
              );
              form.resetFields(resetFields);
              setMachineServiceName([]);
              setInterested([]);
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.error_message,
            icon: "error",
            confirmButtonText: "Retry",
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        title: "error",
        text: "Please add at least one agri-machine service",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        ASP Registration Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Trader name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ASP Name"
                name="asp_name"
                rules={[
                  { required: true, message: "Please enter ASP name" },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="ASP Name" />
              </Form.Item>
            </Col>
            {/* trader phone */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ASP's Phone Number"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please enter ASP's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="ASP's Phone Number" type="tel" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Trader NID*/}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="National ID"
                name="nationalID"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+$/, // Regular expression to match English characters and spaces
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="National ID" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ASP Image"
                name="asp_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Profile image required",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) =>
                    handleImageUpload(info, setAspProfileImage)
                  }
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload ASP image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Address />
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="Village"
              name="asp_village"
              initialValue=""
              rules={[
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Only English characters",
                },
              ]}
            >
              <Input placeholder="Village Name" />
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* GPS */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="latitude"
                label="Latitude"
                rules={[
                  {
                    required: true,
                    message: "Latitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Latitude" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="longitude"
                label="Longitude"
                rules={[
                  {
                    required: true,
                    message: "Longitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Longitude" />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button
                type="primary"
                icon={<EnvironmentOutlined />}
                onClick={handleGetCurrentLocation}
              >
                Get Current Location
              </Button>
            </Form.Item>
          </Col>
          <Form.List name="services">
            {(serviceFields, { add, remove }) => (
              <>
                {serviceFields.map((serviceField, index) => (
                  <Space
                    key={serviceField.key}
                    style={{
                      marginBottom: 20,
                      border: "dashed 1px gray",
                      padding: "12px",
                      borderRadius: "5px",
                    }}
                  >
                    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...serviceField}
                          label="Name of the Agri-Machine"
                          name={[serviceField.name, "agri_machine_name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select agri-machine name",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select agri-machine name"
                            onChange={(value) =>
                              handleMachineNameChange(value, index)
                            }
                          >
                            <Option value="Tractor Service">
                              Tractor Service
                            </Option>
                            <Option value="Irrigation Pump Service">
                              Irrigation Pump Service
                            </Option>
                            <Option value="Sprayer Service">
                              Sprayer Service
                            </Option>
                            <Option value="Rice Thresher Service">
                              Rice Thresher Service
                            </Option>
                            <Option value="Corn Thresher Service">
                              Corn Thresher Service
                            </Option>
                            <Option value="Soybean Thresher Service">
                              Soybean Thresher Service
                            </Option>
                            <Option value="Combined Thresher Service">
                              Combined Thresher Service
                            </Option>
                            <Option value="Other">Other Service</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {machineServiceName[index] === "Other" && (
                        <Col xs={24} sm={12} md={8} lg={6}>
                          <Form.Item
                            {...serviceField}
                            label="Other Service Name"
                            name={[serviceField.name, "other_service_name"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter other service name",
                              },
                              {
                                pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                                message: "Only English characters",
                              },
                            ]}
                          >
                            <Input placeholder="Other Service Name" />
                          </Form.Item>
                        </Col>
                      )}

                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...serviceField}
                          label="Brand Name"
                          name={[serviceField.name, "brand_name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter brand name",
                            },
                            {
                              pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                              message: "Only English characters",
                            },
                          ]}
                        >
                          <Input placeholder="Brand name" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...serviceField}
                          label="Available quantity"
                          name={[serviceField.name, "available_quantity"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter available quantity",
                            },
                            {
                              pattern: /^[0-9]+$/,
                              message: "Only English numbers",
                            },
                          ]}
                        >
                          <Input placeholder="Available quantity" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...serviceField}
                          label="HP (Horse Power)"
                          name={[serviceField.name, "hp"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter HP",
                            },
                            {
                              pattern: /^[0-9]+(\.[0-9]+)?$/,
                              message: "Only English numbers",
                            },
                          ]}
                        >
                          <Input placeholder="HP (Horse Power)" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...serviceField}
                          label="Service Price"
                          name={[serviceField.name, "service_price"]}
                          initialValue={0}
                          rules={[
                            {
                              pattern: /^[0-9]+(\.[0-9]+)?$/,
                              message: "Only English numbers",
                            },
                          ]}
                        >
                          <Input placeholder="Service Price"  inputMode="numeric"/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item>
                          <Button
                            onClick={() => {
                              remove(serviceField.name);
                              setMachineServiceName((prevServiceNames) => {
                                const updatedServiceNames = [...prevServiceNames];
                                updatedServiceNames.splice(index, 1); 
                                return updatedServiceNames;
                              });
                            }}
                            style={{
                              backgroundColor: "#c21807",
                              color: "#ffffff",
                            }}
                          >
                            <strong>Remove</strong>
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))}

                <Col xs={24} sm={12} md={8} lg={11}>
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={() => {
                        add();
                        // setMachineServiceName([]);
                      }}
                      block
                      size="small"
                      icon={<PlusOutlined />}
                      style={{ color: "#000000" }}
                    >
                      Available Agri-Machines
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Agrigate এর সাথে কাজ করতে আগ্রহী?"
                name="interested_to_work_with_agrigate"
                rules={[
                  {
                    required: true,
                    message: "Please select interest",
                  },
                ]}
              >
                <Select
                  placeholder="Select interest"
                  onChange={handleInterested}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {interested.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Policy?"
                  name="policy"
                  rules={[
                    {
                      required: true,
                      message: "Please select policy",
                    },
                  ]}
                >
                  <Select placeholder="Select policy">
                    <Option value="Credit">বাকি</Option>
                    <Option value="Cash">নগদ</Option>
                    <Option value="Both">উভয়</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          {/* Remarks */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="Remarks"
              name="remarks"
              initialValue=""
              rules={[
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Only English characters",
                },
              ]}
            >
              <Input placeholder="Remarks" />
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
