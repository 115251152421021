import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import FarmerRegistrationForm from "./Registration/FarmerRegistrationForm";
import RetailerRegistrationForm from "./Registration/RetailerRegistrationForm";
import LSPRegistrationForm from "./Registration/LSPRegistrationForm";
import TradersForm from "./Registration/TradersForm";
import Wholesaler from "./Registration/InstitutionalBuyer";
import { loginAccess } from "../..";
import ASPRegistrationForm from "./Registration/ASPRegistrationForm";
import useMediaQuery from '@mui/material/useMediaQuery';
import InstitutionalBuyer from "./Registration/InstitutionalBuyer";


const MainRegistrationForm = () => {
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // Initialize the value state with your desired index
  const [value, setValue] = useState(0); // You can set this to any index you want

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 

  return (
    
    <div className="page-container">
      <div className="content-container">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant={isMobile ? "scrollable" : "standard"} // Use scrollable on mobile
              scrollButtons={isMobile ? "auto" : false} // Show scroll buttons on mobile
            >
              {userPermissions?.includes("view_farmer_reg_tab") && (
                <Tab value={0} label="Farmer" {...a11yProps(0)} />
              )}
              {userPermissions?.includes("view_retailer_reg_tab") && (
                <Tab value={1} label="Retailer" {...a11yProps(1)} />
              )}
              {userPermissions?.includes("view_lsp_reg_tab") && (
                <Tab value={2} label="LSP" {...a11yProps(2)} />
              )}
              {userPermissions?.includes("view_asp_reg_tab") && (
                <Tab value={3} label="ASP" {...a11yProps(3)} />
              )}
              {userPermissions?.includes("view_trader_reg_tab") && (
                <Tab value={4} label="Trader" {...a11yProps(4)} />
              )}
              {userPermissions?.includes("view_wholesaler_reg_tab") && (
                <Tab value={5} label="Institutional Buyer" {...a11yProps(5)} />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {userPermissions?.includes("view_farmer_reg_tab") && (
              <FarmerRegistrationForm />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {userPermissions?.includes("view_retailer_reg_tab") && (
              <RetailerRegistrationForm />
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {userPermissions?.includes("view_lsp_reg_tab") && (
              <LSPRegistrationForm />
            )}
          </TabPanel>

          {userPermissions?.includes("view_asp_reg_tab") && (
            <TabPanel value={value} index={3}>
              <ASPRegistrationForm />
            </TabPanel>
          )}

          {userPermissions?.includes("view_trader_reg_tab") && (
            <TabPanel value={value} index={4}>
              <TradersForm />
            </TabPanel>
          )}
          {userPermissions?.includes("view_wholesaler_reg_tab") && (
            <TabPanel value={value} index={5}>
              <InstitutionalBuyer />
            </TabPanel>
          )}
        </Box>
      </div>
    </div>
  );
};

function TabPanel(props) {
  let { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

MainRegistrationForm.propTypes = {
  children: PropTypes.node,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default MainRegistrationForm;
