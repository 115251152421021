import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import MainRegistrationForm from "./components/Forms/MainRegistrationForm";
import MainConsultationForm from "./components/Forms/MainConsultationForm";
import MainKYCForm from "./components/Forms/MainKYCForm";
import MainSurveyForm from "./components/Forms/MainSurveyForm";
import Home from "./components/HomePage";
import LoginPage from "./components/login/LoginForm";
import SidebarLayout from "./components/Navigation/sidebarLayout";
import { createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import WorkHistory from "./components/CommonProperty/WorkHistory";
import SourcingForm from "./components/Forms/SourcingForm";
import OrderTakingForm from "./components/Forms/OrderTakingForm";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MainContractFarmingForm from "./components/Forms/MainContractFarmingForm";
import MainHelpDesk from "./components/Forms/MainHelpDesk";
import MainSalesForm from "./components/Forms/MainSalesForm";

export const loginAccess = createContext();
const container = document.getElementById("root");
const root = createRoot(container);

function getLocalStorage(key) {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}
const App = () => {
  const [userPermissions, setUserPermissions] = useState(getLocalStorage("user_permissions"));
  const [isLoggedIn,setIsLoggedIn] = useState({
    isLoggedIn: getLocalStorage("isLoggedIn"),
    loggedInUser: getLocalStorage("loggedInUser"),
  });

  const contextValues = {
    isLoggedIn,
    userPermissions,
    setIsLoggedIn,
    setUserPermissions
  };
  const theme = createTheme();

  return (
    <loginAccess.Provider value={contextValues}>
      <ThemeProvider theme={theme}>
      <Router>
        {isLoggedIn?.isLoggedIn ? (
          <SidebarLayout>
            <Switch>
              <PrivateRoute exact path="/">
                <Home />
              </PrivateRoute>
              <PrivateRoute path="/home">
                <Home />
              </PrivateRoute>
              <PrivateRoute path="/registration">
                <MainRegistrationForm />
              </PrivateRoute>
              <PrivateRoute path="/consultation">
                <MainConsultationForm />
              </PrivateRoute>
              <PrivateRoute path="/kyc">
                <MainKYCForm />
              </PrivateRoute>
              <PrivateRoute path="/survey">
                <MainSurveyForm />
              </PrivateRoute>
              <PrivateRoute path="/sourcing">
                <SourcingForm />
              </PrivateRoute>
              <PrivateRoute path="/contractfarming">
                <MainContractFarmingForm />
              </PrivateRoute>
              {/* <PrivateRoute path="/sales">
                <MainSalesForm />
              </PrivateRoute> */}
              <PrivateRoute path="/ordertaking">
                <OrderTakingForm />
              </PrivateRoute>
              <PrivateRoute path="/helpdesk">
                <MainHelpDesk />
              </PrivateRoute>
              <PrivateRoute path="/history">
                <WorkHistory />
              </PrivateRoute>
            </Switch>
          </SidebarLayout>
        ) : (
          <Switch>
            <Route path="/login">
              {isLoggedIn?.isLoggedIn ? <Redirect to="/" /> : <LoginPage />}
            </Route>
            <Redirect to="/login" />
          </Switch>
        )}
      </Router>
      </ThemeProvider>
    </loginAccess.Provider>
  );
};

root.render(<App />);
