import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import OrderTaking from "./OrderTaking/OrderTaking";
import './mainPage.css';
import { loginAccess } from "../..";
import OrderFulfillment from "./OrderTaking/OrderFullfillment";
import useMediaQuery from '@mui/material/useMediaQuery';

const OrderTakingForm = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
  useContext(loginAccess);
  return (
    <div className="page-container">
      <div className="content-container">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant={isMobile ? "scrollable" : "standard"} // Use scrollable on mobile
              scrollButtons={isMobile ? "auto" : false} // Show scroll buttons on mobile
            >
            {userPermissions?.includes("view_order_taking_app") && (<Tab value={0} label="Order Taking" {...a11yProps(0)} />)}
            {userPermissions?.includes("view_order_fulfillment_app") && (<Tab value={1} label="Order Fulfillment" {...a11yProps(1)} />)}
           
            </Tabs>
          </Box>
          {userPermissions?.includes("view_order_taking_app") && (
          <TabPanel value={value} index={0}>
            <OrderTaking/>
          </TabPanel>
          )}
          {userPermissions?.includes("view_order_fulfillment_app") && (
          <TabPanel value={value} index={1}>
            <OrderFulfillment />
          </TabPanel>
          )}

        </Box>
      </div>
    </div>
  );
};

function TabPanel(props) {
  let { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

OrderTakingForm.propTypes = {
  children: PropTypes.node,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default OrderTakingForm;
