import React, { useContext, useEffect, useState } from "react";
import "../Form.css";
import { loginAccess } from "../../..";
import { Form, Input, Button, Row, Col, Spin } from "antd";
import axios from "axios";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";
import Swal from "sweetalert2";

export default function InstitutionalBuyer() {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleSubmit = (data) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/trader/ibRegistration/`,
        {
          data: data,
        },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Institutional Buyer Registration Success",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"];
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.resetFields(resetFields);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error_message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Institutional Buyer Registration
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Institutional Buyer Name"
                name="institutional_buyer_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter institutional buyer name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Institutional Buyer Name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Institutional Buyer's Phone Number"
                name="institutional_buyer_phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter institutional buyer's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input
                  placeholder="Institutional Buyer's Phone Number"
                  type="tel"
                />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item
              hasFeedback
              label="Institutional Buyer's Address"
              name="institutional_buyer_address"
              rules={[
                {
                  required: true,
                  message: "Please enter institutional buyer's address",
                },
              ]}
            >
              <TextArea rows={2} placeholder="Institutional Buyer's Address" />
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="contact_person_name"
                label="Contact Person Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter contact person name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Contact Person Name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Remarks"
                name="remarks"
                rules={[
                  {
                    pattern: /^[A-Za-z0-9\s\-,.;]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Remarks" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
