import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Divider,
} from "antd";
import { loginAccess } from "../../..";
import Swal from "sweetalert2";
import authHeader from "../../Authentication/authHeader";
import baseUrl from "../../../config";
import axios from "axios";

const LSPKYCForm = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [specifiedFeeForVaccination, setSpecifiedFeeForVaccination] = useState(
    []
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleLspInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/lsp/lsp_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          console.log(data);
          form.setFieldsValue({
            lsp_name: data?.data?.name,
            lsp_id: data?.data?.lsp_id,
            district: data?.data?.district?.district_name,
            upazila: data?.data?.upazila?.upazila_name,
            union: data?.data?.union?.union_name,
            village: data?.data?.village,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            lsp_name: undefined,
            lsp_id: undefined,
            district: undefined,
            upazila: undefined,
            union: undefined,
            village: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "LSP Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };
  const handleSpecifiedFeeForVaccination = (value) => {
    setSpecifiedFeeForVaccination(value);
  };

  const handleSubmit = (data) => {
    setLoading(true);
    fetch(
      "https://script.google.com/macros/s/AKfycbyLfcDn-JLTZyW2KFZuGD8LBA6tY1zQTTdKz9cFRsVjfHxqLDAmAaXBbT8sVKd1z33d4Q/exec",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status.toLowerCase() === "success") {
          Swal.fire({
            title: "Success",
            text: "LSP Kyc Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );

            form.resetFields(resetFields);
            setSpecifiedFeeForVaccination([]);
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Error",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        LSP KYC Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="LSP Phone Number"
                name="phoneNumber"
                onChange={handleLspInfo}
                rules={[
                  {
                    required: true,
                    message: "Please enter LSP's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="LSP Phone Number" type="tel" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="LSP ID"
                name="lsp_id"
                rules={[
                  {
                    required: true,
                    message: "LSP ID required",
                  },
                ]}
              >
                <Input placeholder="LSP ID" type="tel" disabled />
              </Form.Item>
            </Col>
            {/* Farmer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="LSP Name"
                name="lsp_name"
                rules={[{ required: true, message: "LSP name required" }]}
              >
                <Input placeholder="LSP Name" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                rules={[{ required: true, message: "District required" }]}
              >
                <Input placeholder="District" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Upazila"
                name="upazila"
                rules={[{ required: true, message: "Upazila required" }]}
              >
                <Input placeholder="Upazila" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Union"
                name="union"
                rules={[{ required: true, message: "Union required" }]}
              >
                <Input placeholder="Union" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Village"
                name="village"
                rules={[{ required: true, message: "Village required" }]}
              >
                <Input placeholder="Village" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Training Name"
                name="training_name"
                initialValue=""
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Training Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Training Provider Institution Name"
                name="training_institution_name"
                initialValue=""
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Training Institution Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Training Duration"
                name="training_duration"
                initialValue={0}
                rules={[
                    {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Training Duration"  inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Working Experience (Years)"
                name="working_experience"
                rules={[
                  {
                    required: true,
                    message: "Pleaser enter working experience",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Working Experience" />
              </Form.Item>
            </Col>
          </Row>
          <header className="my-3">
            <strong>Official service provider Info:</strong>
          </header>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Institution Name"
                name="institution_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter institution name",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Institution Name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Designation"
                name="designation"
                rules={[
                  {
                    required: true,
                    message: "Please enter designation",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Designation" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Employment Duration"
                name="employment_duration"
                rules={[
                  {
                    required: true,
                    message: "Please enter employment euration",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Employment Duration" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Official Service Type"
                name="official_service_type"
                rules={[
                  {
                    required: true,
                    message: "Please enter official service type",
                  },
                ]}
              >
                <Select
                  placeholder="Select official service type"
                  mode="multiple"
                >
                  <Option value="Primary Veterinary Care">
                    Primary Veterinary Care
                  </Option>
                  <Option value="Vaccination">Vaccination</Option>
                  <Option value="Artificial Reproduction">
                    Artificial Reproduction
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <header className="my-3">
            <strong>Unofficial service provider Info:</strong>
          </header>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              label="Unofficial Service Type"
              name="unofficial_service_type"
              rules={[
                {
                  required: true,
                  message: "Please enter unofficial service type",
                },
              ]}
            >
              <Select
                placeholder="Select unofficial service type"
                mode="multiple"
              >
                <Option value="Primary Veterinary Care">
                  Primary Veterinary Care
                </Option>
                <Option value="Vaccination">Vaccination</Option>
              </Select>
            </Form.Item>
          </Col>
          <Divider />
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Vaccine Source"
                name="vaccine_source"
                rules={[
                  { required: true, message: "Please select vaccine source" },
                ]}
              >
                <Select placeholder="Vaccine Source">
                  <Option value="Governmental">Governmental</Option>
                  <Option value="Non-Governmental">Non-Governmental</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Vaccine Storing Method"
                name="vaccine_storing_method"
                rules={[
                  {
                    required: true,
                    message: "Pleaser enter sorting method",
                  },
                ]}
              >
                <Select placeholder="Select sorting method">
                  <Option value="Flask">Flask</Option>
                  <Option value="Refrigerator">Refrigerator</Option>
                  <Option value="Traditional Method">Traditional Method</Option>
                  <Option value="Cool Box">Cool Box</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Do you charge a fee for vaccination?"
                name="charge_fee_for_vaccination"
                rules={[{ required: true, message: "Please select" }]}
              >
                <Select placeholder="Fee for vaccination">
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Is there any specified fee for vaccination?"
                name="specified_fee_for_vaccination"
                rules={[
                  {
                    required: true,
                    message: "Pleaser select specified fee for vaccination",
                  },
                ]}
              >
                <Select
                  placeholder="Select specified fee "
                  onChange={handleSpecifiedFeeForVaccination}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {specifiedFeeForVaccination.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Fee for Cow"
                  name="fee_for_cow"
                  rules={[
                    { required: true, message: "Please enter fee for cow" },
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="Fee for Cow" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Fee for Goat"
                  name="fee_for_goat"
                  rules={[
                    {
                      required: true,
                      message: "Pleaser enter fee for goat",
                    },
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="Fee for Goat" />
                </Form.Item>
              </Col>
            </Row>
          )}

          {specifiedFeeForVaccination.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Fee for Buffalo"
                  name="fee_for_buffalo"
                  rules={[
                    { required: true, message: "Please enter fee for buffalo" },
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="Fee for Buffalo" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Fee for Duck & Chickens"
                  name="fee_for_duck_chickens"
                  rules={[
                    {
                      required: true,
                      message: "Pleaser enter fee for duck & chickens",
                    },
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="Fee for Duck & Chickens" />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="How much fee do governmental employees charge for semen, including its cost?"
                name="governmental_employees_charge_for_semen"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Fee do governmental employees charge for semen?" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="How much fee do non governmental employees charge for semen, including its cost?"
                name="non_governmental_employees_charge_for_semen"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Fee do non governmental employees charge for semen?"  inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Semen Type"
                name="semen_type"
                rules={[
                  { required: true, message: "Please select semen type" },
                ]}
              >
                <Select placeholder="Select semen type">
                  <Option value="Frozen">Frozen</Option>
                  <Option value="Liquid">Liquid</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Interested to work with agrigate?"
                name="interested_to_work_with"
                rules={[{ required: true, message: "Please select interest" }]}
              >
                <Select placeholder="Select interest">
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default LSPKYCForm;
