import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Modal, Form, Input, Button, Spin, Select, Upload } from "antd";
import awsS3 from "aws-sdk/clients/s3";
import axios from "axios";
import Swal from "sweetalert2";
import baseUrl from "../../../../config";
import authHeader from "../../../Authentication/authHeader";

export default function LivestockFollowupModal({
  visible,
  onCancel,
  data,
  fetchFoLivestockFollowup,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });
  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      opening_date: data?.ticket?.opening_date,
      ticket_id: data?.ticket?.ticket_id,
      district: data?.ticket?.district,
      name: data?.farmer?.name,
      phone: "0" + data?.farmer?.phone,
      farmer_id: data?.farmer?.farmer_id,
      issue_type: data?.ticket?.issue_type?.issue_type,
      ticket_status: data?.ticket?.status,
      advice: data?.advice,
      followup_date: data?.followup_date,
    });
  }, [data]);

  const handleCancel = () => {
    form.setFieldsValue({
      issue_image: null,
      issue_details: null,
      present_management: null,
    })
    onCancel()
  }

  const handleUpdate = (value) => {
    value.livestock_consultation_id = data.livestock_consultation_id;
    value.issue_image = imageUrl;
    value.followup_status = "Completed";
    axios
      .patch(
        `${baseUrl}/consultation/livestockFollowupUpdate/${value?.livestock_consultation_id}/`,
        value,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        if (data?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Followup Update Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            handleCancel();
            fetchFoLivestockFollowup();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <Modal
      title="Crop Followup"
      centered="true"
      open={visible}
      onCancel={handleCancel}
      footer={null}
      style={{ marginTop: "70px", marginBottom: "10px" }}
    >
      <Form onFinish={handleUpdate} form={form} layout="horizontal">
        <Form.Item
          hasFeedback
          label="Date"
          name="opening_date"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Please select ticket date",
            },
          ]}
        >
          <Input placeholder="Date" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Ticket"
          name="ticket_id"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Ticket required",
            },
          ]}
        >
          <Input placeholder="Ticket ID" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Location"
          name="district"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Location required",
            },
          ]}
        >
          <Input placeholder="Location" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Farmer Name"
          name="name"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer name required",
            },
          ]}
        >
          <Input placeholder="Farmer Name" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Farmer Contact"
          name="phone"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer Contact",
            },
          ]}
        >
          <Input placeholder="Farmer Contact" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Farmer ID"
          name="farmer_id"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer Contact",
            },
          ]}
        >
          <Input placeholder="Farmer ID" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Issue Type"
          name="issue_type"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Issue type required",
            },
          ]}
        >
          <Input placeholder="Issue Type" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Issue Details"
          name="issue_details"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Please enter issue details",
            },
            {
              pattern: /^[A-Za-z0-9\s,._'"\/;-]+$/,
              message: "Only English characters",
            },
          ]}
        >
          <TextArea rows={3} placeholder="Issue details" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Issue Image"
          name="issue_image"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            const fileList = e && e.fileList;
            return fileList;
          }}
          rules={[
            {
              required: true,
              message: "Please upload issue image",
            },
            {
              validator: (_, value) => {
                if (value && value.length > 0) {
                  const file = value[0];
                  const fileType = file.type;
                  const acceptedFormats = [
                    "image/jpeg",
                    "image/png",
                    "image/jpg",
                    "image/heic",
                  ];

                  if (acceptedFormats.includes(fileType)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                    );
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={(info) => handleImageUpload(info, setImageUrl)}
            listType="picture"
            onRemove={() => setImageUrl(" ")}
          >
            <Button
              type="primary"
              icon={<UploadOutlined />}
              loading={uploading}
            >
              {uploading ? "Uploading" : "Upload issue image"}
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Present Management"
          name="present_management"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              pattern: /^[A-Za-z0-9\s,._'"\/;-]+$/,
              message: "Only English characters",
            },
          ]}
        >
          <Input placeholder="Present Management" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Advice"
          name="advice"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Please input advice",
            },
            {
              pattern: /^[A-Za-z0-9\s,._'"\/;-]+$/,
              message: "Only English characters",
            },
          ]}
        >
          <TextArea rows={3} placeholder="Advice" readOnly />
        </Form.Item>

        <Form.Item
          label="Followup Date"
          name="followup_date"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Followup date required",
            },
          ]}
        >
          <Input placeholder="Followup Date" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Ticket Status"
          name="ticket_status"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Ticket status required",
            },
          ]}
        >
          <Input placeholder="Ticket status" readOnly />
        </Form.Item>

        <Form.Item style={{ textAlign: "right", margin: 0 }}>
          <Button
            style={{ backgroundColor: "#38512e", color: "#ffffff" }}
            htmlType="submit"
            disabled={loading}
          >
            Followup
            {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
