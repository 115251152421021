import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  Row,
  Col,
  Space,
  Spin,
} from "antd";
import {
  EnvironmentOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { loginAccess } from "../../..";
import awsS3 from "aws-sdk/clients/s3";
import Swal from "sweetalert2";
import Address from "../../CommonProperty/Address";
import baseUrl from "../../../config";
import axios from "axios";
import authHeader from "../../Authentication/authHeader";

const RetailerKYCForm = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [businessType, setBusinessType] = useState("");
  const {
    isLoggedIn,
    userPermissions,
    setIsLoggedIn,
    setUserPermissions
  } = useContext(loginAccess);
  const [signboardImage, setSignboardImage] = useState("");
  const [tradeLicenseImage, setTradeLicenseImage] = useState("");
  const [feedLicenseImage, setFeedLicenseImage] = useState("");
  const [seedLicenseImage, setSeedLicenseImage] = useState("");
  const [pesticideLicenseImage, setPesticideLicenseImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleProductImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage([...productImages, imageUrl]);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleBusinessTypeChange = (value) => {
    setFeedLicenseImage(null);
    setSeedLicenseImage(null);
    setPesticideLicenseImage(null);
    setBusinessType(value);
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          form.setFieldsValue({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  const handleSubmit = (values) => {
    values.signboard_image = signboardImage;
    values.trade_license_image = tradeLicenseImage;
    values.feed_license_image = feedLicenseImage;
    values.seed_license_image = seedLicenseImage;
    values.pesticide_license_image = pesticideLicenseImage;
    values.products?.map(
      (item, index) => (item.product_image = productImages[index])
    );
    setLoading(true);
    fetch(
      "https://script.google.com/macros/s/AKfycbwMZMVWXnTihYBb_yMGVklrZILigJfNkEVfkxz-gMBKd6UIX-PxZZpi9CHhLQfVObo/exec",
      {
        method: "POST",
        body: JSON.stringify(values),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status.toLowerCase() === "success") {
          Swal.fire({
            title: "Success",
            text: "Retailer KYC Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.setFieldValue({
              district: null,
              upazila: null,
              union: null,
            });
            form.resetFields(resetFields);
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Please add at least one product",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleRetailerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/retailer/retailer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            retailer_name: data?.data?.name,
            retailer_id: data?.data?.retailer_id,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            retailer_name: undefined,
            retailer_id: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Retailer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Retailer KYC Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Retailer Phone Number"
                name="phoneNumber"
                onChange={handleRetailerInfo}
                rules={[
                  {
                    required: true,
                    message: "Required retailer's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="Retailer Phone Number" type="tel" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Retailer Name"
                name="retailer_name"
                rules={[
                  { required: true, message: "Require retailer's name" },
                  {
                    pattern: /^[A-Za-z\s]+$/, // Regular expression to match English characters and spaces
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Retailer Name" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Retailer ID"
                name="retailer_id"
                rules={[
                  {
                    required: true,
                    message: "Require retailer's id",
                  },
                ]}
              >
                <Input placeholder="Retailer ID" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Trader's Name"
                name="traders_name"
                rules={[
                  { required: true, message: "Please enter trader's name" },
                  {
                    pattern: /^[A-Za-z\s]+$/, // Regular expression to match English characters and spaces
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Trader's Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Signboard Image"
                name="signboard_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) =>
                    handleImageUpload(info, setSignboardImage)
                  }
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload signboard image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Trade License Image"
                name="trade_license_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) =>
                    handleImageUpload(info, setTradeLicenseImage)
                  }
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload trade license image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Address />

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Bazaar Name"
                name="bazaar_name"
                rules={[
                  { required: true, message: "Please enter Bazaar name" },
                  {
                    pattern: /^[A-Za-z\s]+$/, // Regular expression to match English characters and spaces
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Bazaar Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="latitude"
                label="Latitude"
                rules={[
                  {
                    required: true,
                    message: "Latitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Latitude" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="longitude"
                label="Longitude"
                rules={[
                  {
                    required: true,
                    message: "Longitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Longitude" />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button
                type="primary"
                icon={<EnvironmentOutlined />}
                onClick={handleGetCurrentLocation}
              >
                Get Current Location
              </Button>
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Years of experience"
                name="years_of_experience"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter Years of experience in managing the business establishment?",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="in managing the business establishment?"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Business  Type"
                name="business_type"
                rules={[
                  {
                    required: true,
                    message: "Please select the retailer type",
                  },
                ]}
              >
                <Select
                  value={businessType}
                  onChange={handleBusinessTypeChange}
                  placeholder="Select Business Type"
                >
                  <Option value="crop">Crop</Option>
                  <Option value="livestock">Livestock</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* Livestock Info ==================================== */}
          {businessType.includes("livestock") && (
            <>
              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    label="Feed License Image"
                    name="feed_license_image"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e.fileList}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && value.length > 0) {
                            const file = value[0];
                            const fileType = file.type;
                            const acceptedFormats = [
                              "image/jpeg",
                              "image/png",
                              "image/jpg",
                              "image/heic",
                            ];
                            if (acceptedFormats.includes(fileType)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={(info) =>
                        handleImageUpload(info, setFeedLicenseImage)
                      }
                      listType="picture"
                    >
                      <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        loading={uploading}
                      >
                        {uploading ? "Uploading" : "Upload feed license image"}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {businessType.includes("crop") && (
            <>
              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    label="Seed License Image"
                    name="seed_license_image"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && value.length > 0) {
                            const file = value[0];
                            const fileType = file.type;
                            const acceptedFormats = [
                              "image/jpeg",
                              "image/png",
                              "image/jpg",
                              "image/heic",
                            ];
                            if (acceptedFormats.includes(fileType)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={(info) =>
                        handleImageUpload(info, setSeedLicenseImage)
                      }
                      listType="picture"
                    >
                      <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        loading={uploading}
                      >
                        {uploading ? "Uploading" : "Upload seed license image"}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    label="Pesticide License Image"
                    name="pesticide_license_image"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && value.length > 0) {
                            const file = value[0];
                            const fileType = file.type;
                            const acceptedFormats = [
                              "image/jpeg",
                              "image/png",
                              "image/jpg",
                              "image/heic",
                            ];
                            if (acceptedFormats.includes(fileType)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={(info) =>
                        handleImageUpload(info, setPesticideLicenseImage)
                      }
                      listType="picture"
                    >
                      <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        loading={uploading}
                      >
                        {uploading
                          ? "Uploading"
                          : "Upload pesticide license image"}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Form.List name="products">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{
                      marginBottom: 20,
                      border: "dashed 1px gray",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Row gutter={[8, 16, 24, 32]}>
                      <Col xs={18} sm={8} md={8} lg={12}>
                        <Form.Item
                          {...restField}
                          hasFeedback
                          label="Product Image"
                          name={[name, `product_image`]}
                          valuePropName="fileList"
                          getValueFromEvent={(e) => e.fileList}
                          rules={[
                            {
                              required: true,
                              message: "Missing product image",
                            },
                            {
                              validator: (_, value) => {
                                if (value && value.length > 0) {
                                  const file = value[0];
                                  const fileType = file.type;
                                  const acceptedFormats = [
                                    "image/jpeg",
                                    "image/png",
                                    "image/jpg",
                                    "image/heic",
                                  ];
                                  if (acceptedFormats.includes(fileType)) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                                    );
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Upload
                            beforeUpload={() => false}
                            onChange={(info) =>
                              handleProductImageUpload(info, setProductImages)
                            }
                            onRemove={() => {
                              const newImages = [...productImages];
                              newImages.splice(index, 1);
                              setProductImages(newImages);
                            }}
                            listType="picture"
                          >
                            <Button
                              type="primary"
                              icon={<UploadOutlined />}
                              loading={uploading}
                            >
                              {uploading
                                ? "Uploading"
                                : "Upload product image image"}
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>

                      <Col xs={18} sm={8} md={8} lg={12}>
                        <Form.Item
                          {...restField}
                          label="Daily Sales Volume (KG)"
                          name={[name, `daily_sales_volume`]}
                          rules={[
                            {
                              required: true,
                              message: "Missing daily sales volume",
                            },
                            {
                              pattern: /^[0-9]+$/,
                              message: "Only english number",
                            },
                          ]}
                        >
                          <Input placeholder="Daily Sales Volume" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item>
                          <Button
                            onClick={() => {
                              const newImages = [...productImages];
                              newImages.splice(index, 1);
                              setProductImages(newImages);
                              remove(name);
                            }}
                            style={{
                              backgroundColor: "#c21807",
                              color: "#ffffff",
                            }}
                          >
                            <strong>Remove</strong>
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))}
                <Col xs={24} sm={12} md={8} lg={11}>
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={() => add()}
                      block
                      size="small"
                      icon={<PlusOutlined />}
                      style={{ color: "#000000" }}
                    >
                      Add product
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={7}>
              <Form.Item
                label="Which companies' products do you sell the most?"
                name="most_selling_product_company"
                rules={[
                  { required: true, message: "Please select an option" },
                  {
                    pattern: /^[A-Za-z\s]+$/, // Regular expression to match English characters and spaces
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Most selling products company name" />
              </Form.Item>
            </Col>
            {/* Interested to work with Agrigate */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Interested to work with Agrigate?"
                name="interested_to_work_with_agrigate"
                rules={[{ required: true, message: "Please select an option" }]}
              >
                <Select placeholder="Interested to work with Agrigate?">
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* Remarks */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="Remarks"
              name="remarks"
              rules={[
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Only English characters",
                },
              ]}
            >
              <Input placeholder="Remarks" />
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6} style={{ display: "flex" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default RetailerKYCForm;
