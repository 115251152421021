import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Button, Col, Row, Spin, Upload } from "antd";
import { loginAccess } from "../../..";
import Swal from "sweetalert2";
import awsS3 from "aws-sdk/clients/s3";
import { UploadOutlined } from "@ant-design/icons";

export default function VaccineDemand() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = (data) => {
    setLoading(true);
    data.issue_image = imageUrl;
    fetch(
      "https://script.google.com/macros/s/AKfycbzPzDryZmvpxTPxdRPdt0_3hrsX090761pRreByZ2suVbBS42wuL3mRvilYWv6kb-VC/exec",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status.toLowerCase() === "success") {
          Swal.fire({
            title: "Success",
            text: "Help Desk Data Submitted Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.resetFields(resetFields);
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Error",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Help Desk
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Issue Occurred"
                name="issue_occurred"
                rules={[
                  {
                    required: true,
                    message: "Select issue required",
                  },
                ]}
              >
                <Select placeholder="Select issue occurred">
                  <Option value="Registration">Registration</Option>
                  <Option value="Sourcing">Sourcing</Option>
                  <Option value="Consultation">Consultation</Option>
                  <Option value="Contract Farming">Contract Farming</Option>
                  <Option value="Survey">Survey</Option>
                  <Option value="Order Taking">Order Taking</Option>
                  <Option value="KYC">KYC</Option>
                  <Option value="Work History">Work History</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Issue Details"
                name="issue_details"
                rules={[
                  {
                    required: true,
                    message: "Please enter issue details",
                  },
                ]}
              >
                <Input placeholder="Issue Details" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Suggestions"
                name="suggestions"
                rules={[
                  {
                    required: true,
                    message: "Please enter suggestions",
                  },
                ]}
              >
                <Input placeholder="Suggestions" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Issue Related Image"
                name="issue_image"
                valuePropName="fileList"
                placeholder="Upload issue related image"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  { required: true, message: "Please upload issue related image" },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setImageUrl)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload issue related image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* Submit Button */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
