import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import PriceSurveyForm from "./Survey/PriceSurveyForm";
import DairySurvey from "./Survey/DairySurvey";
import BeefSurvey from "./Survey/BeefSurvey";
import { loginAccess } from "../..";
import WareHouseSurvey from "./Survey/WareHouseSurvey";
import useMediaQuery from "@mui/material/useMediaQuery";
import CropSurvey from "./Survey/CropSurvey";
import PoultrySurvey from "./Survey/PoultrySurvey";
import AgroProcessorSurvey from "./Survey/AgroProcessorSurvey";

const MainSurveyForm = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <div className="page-container">
      <div className="content-container">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant={isMobile ? "scrollable" : "standard"} // Use scrollable on mobile
              scrollButtons={isMobile ? "auto" : false} // Show scroll buttons on mobile
            >
              {userPermissions?.includes("view_price_survey_tab") && (
                <Tab value={0} label="Price App" {...a11yProps(0)} />
              )}
              {userPermissions?.includes("view_agro_processor_survey_tab") && (
                <Tab
                  value={1}
                  label="Agro Processor Survey"
                  {...a11yProps(1)}
                />
              )}
              {/* {userPermissions?.includes("view_ricemill_survey_tab") && (
                <Tab value={1} label="Rice Mill" {...a11yProps(1)} />
              )} */}
              {userPermissions?.includes("view_dairy_survey_tab") && (
                <Tab value={2} label="Dairy Survey" {...a11yProps(2)} />
              )}
              {userPermissions?.includes("view_beef_survey_tab") && (
                <Tab value={3} label="Beef Survey" {...a11yProps(3)} />
              )}

              {userPermissions?.includes("view_crop_survey_tab") && (
                <Tab value={4} label="Crop Survey" {...a11yProps(5)} />
              )}
              {userPermissions?.includes("view_poultry_survey_tab") && (
                <Tab value={5} label="Poultry Survey" {...a11yProps(6)} />
              )}
              {userPermissions?.includes("view_fishery_survey_tab") && (
                <Tab value={6} label="Fishery Survey" {...a11yProps(7)} />
              )}
              {userPermissions?.includes("view_warehouse_survey_tab") && (
                <Tab value={7} label="Warehouse Survey" {...a11yProps(8)} />
              )}
            </Tabs>
          </Box>
          {userPermissions?.includes("view_price_survey_tab") && (
            <TabPanel value={value} index={0}>
              <PriceSurveyForm />
            </TabPanel>
          )}
          {/* {userPermissions?.includes("view_ricemill_survey_tab") && (
            <TabPanel value={value} index={1}>
              <RiceMill />
            </TabPanel>
          )} */}
           {userPermissions?.includes("view_agro_processor_survey_tab") && (
            <TabPanel value={value} index={1}>
              <AgroProcessorSurvey />
            </TabPanel>
          )}
          {userPermissions?.includes("view_dairy_survey_tab") && (
            <TabPanel value={value} index={2}>
              <DairySurvey />
            </TabPanel>
          )}
          {userPermissions?.includes("view_beef_survey_tab") && (
            <TabPanel value={value} index={3}>
              <BeefSurvey />
            </TabPanel>
          )}
         
          {userPermissions?.includes("view_crop_survey_tab") && (
            <TabPanel value={value} index={4}>
              <CropSurvey />
            </TabPanel>
          )}
          {userPermissions?.includes("view_poultry_survey_tab") && (
            <TabPanel value={value} index={5}>
              <PoultrySurvey />
            </TabPanel>
          )}
          {userPermissions?.includes("view_fishery_survey_tab") && (
            <TabPanel value={value} index={6}>
              <PoultrySurvey />
            </TabPanel>
          )}
          {userPermissions?.includes("view_warehouse_survey_tab") && (
            <TabPanel value={value} index={7}>
              <WareHouseSurvey />
            </TabPanel>
          )}
        </Box>
      </div>
    </div>
  );
};

function TabPanel(props) {
  let { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

MainSurveyForm.propTypes = {
  children: PropTypes.node,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default MainSurveyForm;
