import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  DatePicker,
  Col,
  Row,
  Radio,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import awsS3 from "aws-sdk/clients/s3";
import { loginAccess } from "../../..";
import axios from "axios";
import authHeader from "../../Authentication/authHeader";
import Swal from "sweetalert2";
import baseUrl from "../../../config";
import Address from "../../CommonProperty/Address";

const FarmerRegistrationForm = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const {
    isLoggedIn,
    userPermissions,
    setIsLoggedIn,
    setUserPermissions
  } = useContext(loginAccess);
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const handleSubmit = (data) => {
    data.farmer_image = imageUrl;
    if (!data.nationalID) {
      data.nationalID = 0;
    }
    axios
      .post(`${baseUrl}/api/farmerRegistration/`, {
        data: data,
        headers: authHeader(),
      })
      .then((response) => {
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Farmer Registration Success",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.resetFields(resetFields);
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error_message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };
  const genderOptions = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
  ];

  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Farmer Registration Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          {/* Name */}
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter farmer's name" },
                  {
                    pattern: /^[A-Za-z\s]+$/, // Regular expression to match English characters and spaces
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>

            {/* Phone Number */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter farmer's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="Phone Number" type="tel" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Gender */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Gender"
                name="gender"
                rules={[
                  { required: true, message: "Please select farmer's gender" },
                ]}
              >
                <Select placeholder="Gender">
                  {genderOptions.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* Date of Birth */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Date of Birth"
                name="dateOfBirth"
                rules={[
                  {
                    required: true,
                    message: "Please select farmer's date of birth",
                  },
                ]}
              >
                <DatePicker placeholder="Date of Birth" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Image */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer's Image"
                name="farmer_image"
                valuePropName="fileList"
                placeholder="Upload farmer's image"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  { required: true, message: "Please upload farmer's image" },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setImageUrl)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload Farmer's Image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            {/* National ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="National ID"
                name="nationalID"
                rules={[
                  {
                    pattern: /^[0-9]+$/, // Regular expression to match English characters and spaces
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="National ID" />
              </Form.Item>
            </Col>
          </Row>
          <Address />
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Village"
                name="village"
                rules={[
                  { required: true, message: "Please enter farmer's village" },
                  {
                    pattern: /^[A-Za-z\s]+$/, // Regular expression to match English characters and spaces
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Village" />
              </Form.Item>
            </Col>

            {/* Agricultural Activities */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Agricultural Activities"
                name="agriculturalActivities"
                rules={[
                  {
                    required: true,
                    message: "Please select agricultural activity/activities",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="Livestock">Livestock</Radio>
                  <Radio value="Crop">Crop</Radio>
                  <Radio value="Crop, Livestock">Both</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Other Occupation */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Other Occupation"
                name="otherOccupation"
                hasFeedback
                initialValue={[]}
              >
                <Select mode="multiple" placeholder="Select">
                  <Option value="Livestock Service Provider">
                    Livestock Service Provider
                  </Option>
                  <Option value="Retailer">Retailer</Option>
                  <Option value="Agri-Machinery Service Provider">
                    Agri-Machinery Service Provider
                  </Option>
                  <Option value="Wholesale">Wholesale</Option>
                  <Option value="Trader">Trader</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Additional Details */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Additional Occupation Details"
                name="occupationDetails"
                initialValue=""
                hasFeedback
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/, // Regular expression to match English characters and spaces
                    message: "Only English characters",
                  },
                ]}
              >
                <TextArea placeholder="Enter additional details" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* Submit Button */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default FarmerRegistrationForm;
