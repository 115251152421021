import React, { useContext, useEffect, useState } from "react";
import "../Form.css";
import { loginAccess } from "../../..";
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  Row,
  Col,
  Spin,
} from "antd";
import Address from "../../CommonProperty/Address";
import axios from "axios";
import baseUrl from "../../../config";
import Swal from "sweetalert2";
import authHeader from "../../Authentication/authHeader";

const PriceSurveyForm = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleProductChange = (value) => {
    setSelectedProductName(value);
  };

  const handleSubmit = (data) => {
    setLoading(true);
    data.difference = parseFloat(
      parseFloat(data.spot_price) - parseFloat(data.yesterday_price)
    );
    axios
      .post(
        `${baseUrl}/api/createPriceApp/`,
        {
          data: data,
        },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: "Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            setSelectedProductName(null)
            form.setFieldValue({
              district: null,
              upazila: null,
              union: null,
            });
            form.resetFields(resetFields);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error?.response?.data?.message);
        Swal.fire({
          title: "error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    if (selectedProductName) {
      axios
        .get(`${baseUrl}/api/priceApp/${selectedProductName}`, {
          headers: authHeader(),
        })
        .then((response) =>
          form.setFieldsValue({
            product_category: response?.data?.product_category,
            product_subcategory: response?.data?.product_subcategory,
            product_image: response?.data?.product_image,
            price_product_id: response?.data?.price_product_id,
          })
        )
        .catch((error) => {
          form.setFieldsValue({
            product_category: undefined,
            product_subcategory: undefined,
            product_image: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Product Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  }, [form, selectedProductName]);

  const today = new Date();
  const todaysDate = new Date(today)
  todaysDate.setDate(today.getDate());
  const surveyDate = todaysDate.toISOString().slice(0,10);

  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Price App Survey Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          {/* Date */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="Survey Date"
              name="date"
              initialValue={surveyDate}
              rules={[
                { required: true, message: "Please enter survey date" },
              ]}
            >
             <Input placeholder="Date" disabled/>
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* product name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Product Name"
                name="product_name"
                rules={[
                  {
                    required: true,
                    message: "Select product name",
                  },
                ]}
              >
                <Select
                  placeholder="Select Product Name"
                  showSearch
                  onChange={handleProductChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Broken Rice(Kalamachi)">
                    Broken Rice(Kalamachi)
                  </Option>
                  <Option value="Ground Nut">Ground Nut</Option>
                  <Option value="Maize">Maize</Option>
                  <Option value="Mustard">Mustard</Option>
                  <Option value="Mustard Oil Cake">Mustard Oil Cake</Option>
                  <Option value="Rice Bran">Rice Bran</Option>
                  <Option value="Rice Husk">Rice Husk</Option>
                  <Option value="Soybean">Soybean</Option>
                  <Option value="Wheat Bran">Wheat Bran</Option>
                  <Option value="DORB">DORB</Option>
                  <Option value="Rice Bran Oil (Crude Oil)">
                    Rice Bran Oil (Crude Oil)
                  </Option>
                  <Option value="Silky Bran">Silky Bran</Option>
                  <Option value="Rice Polish (Atop & Shiddo)">
                    Rice Polish (Atop & Shiddo)
                  </Option>
                  <Option value="Paddy (Thin)">
                  Paddy (Thin)
                  </Option>
                  <Option value="Paddy (Thick)">
                  Paddy (Thick)
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Product ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Product ID"
                name="price_product_id"
                rules={[{ required: true, message: "Product ID required" }]}
              >
                <Input placeholder="Product ID" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Product Category */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Product Category"
                name="product_category"
                rules={[
                  { required: true, message: "Product category required" },
                ]}
              >
                <Input placeholder="Product Category" disabled />
              </Form.Item>
            </Col>
            {/* product subcategory */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Product Subcategory"
                name="product_subcategory"
                rules={[
                  {
                    required: true,
                    message: "Product subcategory required",
                  },
                ]}
              >
                <Input placeholder="Product subcategory" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* product image */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Product Image"
                name="product_image"
                rules={[
                  {
                    required: true,
                    message: "Product image required",
                  },
                ]}
              >
                <Input placeholder="Product Image" disabled />
              </Form.Item>
            </Col>
            {/* Product Moisture */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Product Moisture"
                name="moisture"
                rules={[
                  {
                    required: true,
                    message: "Please select product moisture level",
                  },
                ]}
              >
                <Select placeholder="Select Moisture Level">
                  <Option value="1%-2%">1%-2%</Option>
                  <Option value="3%-4%">3%-4%</Option>
                  <Option value="5%-6%">5%-6%</Option>
                  <Option value="7%-8%">7%-8%</Option>
                  <Option value="9%-10%">9%-10%</Option>
                  <Option value="11%-12%">11%-12%</Option>
                  <Option value="13%-14%">13%-14%</Option>
                  <Option value="15%-16%">15%-16%</Option>
                  <Option value="17%-18%">17%-18%</Option>
                  <Option value="19%-20%">19%-20%</Option>
                  <Option value="21%-22%">21%-22%</Option>
                  <Option value="23%-more">23%-more</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Spot Price */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Spot Price"
                name="spot_price"
                rules={[
                  {
                    required: true,
                    message: "Please enter spot price",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Spot Price" />
              </Form.Item>
            </Col>

            {/* Delivery Price */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Yesterday Price"
                name="yesterday_price"
                rules={[
                  {
                    required: true,
                    message: "Please enter yesterday price",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Yesterday Price" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Info Source Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Info Source Name"
                name="info_source_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter info source name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Info Source Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Info Source Phone Number"
                name="info_source_number"
                rules={[
                  {
                    required: true,
                    message: "Please enter info source phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="Info Source Phone Number" type="tel" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Info Source Type */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Info Source Type"
                name="info_source_type"
                rules={[
                  {
                    required: true,
                    message: "Please select info source type ",
                  },
                ]}
              >
                <Select placeholder="Select Info Source Type">
                  <Option value="Buyer">Buyer</Option>
                  <Option value="BP">BP</Option>
                  <Option value="Company">Company</Option>
                  <Option value="Shop Keeper">Shop Keeper</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Info Source Bazar */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Info Source Bazar"
                name="info_source_bazar"
                rules={[
                  {
                    required: true,
                    message: "Please enter info source bazar",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Info Source Bazar" />
              </Form.Item>
            </Col>
          </Row>
          <Address />
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default PriceSurveyForm;
