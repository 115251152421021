import React, { useState } from "react";
import "./LoginForm.css";
import logo from "../Assets/logo.png"; // Import your logo image
import { useContext } from "react";
import { loginAccess } from "../../index";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import baseUrl from "../../config";
import authService from "../Authentication/authService";
import authHeader from "../Authentication/authHeader";

const LoginForm = () => {
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =useContext(loginAccess);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  let { from } = location.state || { from: { pathname: "/home" } };
  const history = useHistory();

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Validate input fields
    if (!username || !password) {
      Swal.fire({
        title: "Invalid",
        text: "Please enter a username and password",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const loginData = {
      loginUsername: username,
      loginPassword: password,
    };

    authService
      .login(loginData)
      .then((response) => {
        const results = response?.data;
        if (results.access) {
          // Retrieve the employee profile information
          axios
            .get(`${baseUrl}/employee/profile/`, {
              headers: authHeader(),
            })
            .then((response) => {
              const employeeProfile = response?.data;
              let loggedInUser = employeeProfile.user_info;
              loggedInUser.employee_id = employeeProfile.employee_id
              // Set the logged-in user info into loginAccess context
              setIsLoggedIn({
                loggedInUser: employeeProfile?.user_info,
                isLoggedIn: true,
              });
              setUserPermissions(employeeProfile?.user_permissions)
              localStorage.setItem(
                "loggedInUser",
                JSON.stringify(loggedInUser)
              );
              localStorage.setItem(
                "userGroups",
                JSON.stringify(employeeProfile.user_groups)
              );
              localStorage.setItem(
                "user_permissions",
                JSON.stringify(employeeProfile.user_permissions)
              );
              history.replace(from);
            })
            .catch((error) => {
              console.error("Error1:", error);
              Swal.fire({
                title: "Wrong",
                text: error?.message,
                icon: "error",
                confirmButtonText: "Retry",
              });
              history.push("/login");
            });
        }
      })
      .catch((error) => {
        console.error("Error2:", error);
        Swal.fire({
          title: "Wrong",
          text: "Incorrect username or password",
          icon: "error",
          confirmButtonText: "Retry",
        });
        history.push("/login");
      });
  };
  return (
    <div className="login-box">
      <div className="login-container">
        <div className="logo-box">
          <img src={logo} alt="Logo" />
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label>Username:</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
