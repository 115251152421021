import React, { useContext, useEffect, useState } from "react";
import "../Form.css";
import { loginAccess } from "../../..";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Space,
  Spin,
  Upload,
  Select,
} from "antd";
import Address from "../../CommonProperty/Address";
import {
  EnvironmentOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import awsS3 from "aws-sdk/clients/s3";

export default function WareHouseSurvey() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);
  const [insideImage, setInsideImage] = useState("");
  const [outsideImage, setOutsideImage] = useState("");
  const [roadImage, setRoadImage] = useState("");
  const [uploading, setUploading] = useState(false);

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          form.setFieldsValue({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleSubmit = (data) => {
    data.inside_image = insideImage;
    data.outside_image = outsideImage;
    data.road_image = roadImage;
    setLoading(true);
    fetch(
      "https://script.google.com/macros/s/AKfycbw-0U1Zsfe9UG2aoZFdH86LmpOzXCFDo1NWsFvv1l0YWf1duggF5nFVJD0yaWdfAM_Hww/exec",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status.toLowerCase() === "success") {
          Swal.fire({
            title: "Success",
            text: "Warehouse Survey Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.setFieldValue({
              district: null,
              upazila: null,
              union: null,
            });
            form.resetFields(resetFields);
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Warehouse Survey Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Type of Warehouse"
                name="type_of_warehouse"
                rules={[
                  {
                    required: true,
                    message: "Please select warehouse type",
                  },
                ]}
              >
                <Select placeholder="Select warehouse type">
                  <Option value="Center">Center</Option>
                  <Option value="Chatal">Chatal</Option>
                  <Option value="Warehouse">Warehouse</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Shop Owner Name"
                name="shop_owner_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter shop owner name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Shop Owner Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Phone Number"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="Phone Number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="National ID"
                name="nid_number"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="National ID" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Address />
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* GPS */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="latitude"
                label="Latitude"
                rules={[
                  {
                    required: true,
                    message: "Latitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Latitude" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="longitude"
                label="Longitude"
                rules={[
                  {
                    required: true,
                    message: "Longitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Longitude" />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button
                type="primary"
                icon={<EnvironmentOutlined />}
                onClick={handleGetCurrentLocation}
              >
                Get Current Location
              </Button>
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Photo - Inside"
                name="inside_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Inside photo required",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setInsideImage)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload inside photo"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Photo - Outside"
                name="outside_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Outside photo required",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setOutsideImage)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload outside photo"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Photo - Road"
                name="road_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Road photo required",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setRoadImage)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload road photo"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Center Length (ft)"
                name="center_length"
                rules={[
                  {
                    required: true,
                    message: "Please enter center length",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only english numbers",
                  },
                ]}
              >
                <Input placeholder="Center Length (ft)" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Center Width (ft)"
                name="center_width"
                rules={[
                  {
                    required: true,
                    message: "Please enter center width",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Center Width (ft)" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Center Height (ft)"
                name="center_height"
                rules={[
                  {
                    required: true,
                    message: "Please enter center height",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Center Height (ft)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Area (sqft)"
                name="area"
                rules={[
                  {
                    required: true,
                    message: "Please enter area (sqft)",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only english numbers",
                  },
                ]}
              >
                <Input placeholder="Area (sqft)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Estimated Storage (MT)"
                name="estimated_storage"
                rules={[
                  {
                    required: true,
                    message: "Please enter estimated storage",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Estimated Storage (MT)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Center Rent(BDT)"
                name="center_rent"
                rules={[
                  {
                    required: true,
                    message: "Please enter center rent",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only english numbers",
                  },
                ]}
              >
                <Input placeholder="Center Rent(BDT)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Washroom Facilities"
                name="washroom_facility"
                rules={[
                  {
                    required: true,
                    message: "Please select washroom facility",
                  },
                ]}
              >
                <Select placeholder="Select washroom facility">
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Advance Method (month)"
                name="advance_method_month"
                rules={[
                  {
                    required: true,
                    message: "Please enter advance method month",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only english characters",
                  },
                ]}
              >
                <Input placeholder="Advance Method (month)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Agreement time (Years)"
                name="agreement_time_years"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only english numbers",
                  },
                  {
                    required: true,
                    message: "Please enter agreement time (years)",
                  },
                ]}
              >
                <Input placeholder="Agreement time (Years)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Remarks"
                name="remarks"
                initialValue=""
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only english characters",
                  },
                ]}
              >
                <Input placeholder="Remarks" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
