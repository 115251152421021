import React, { useContext, useEffect, useState } from "react";
import "./HomePage.css"; // Import the CSS file for styling

import { Card, Col, Divider, Row } from "antd";
import { loginAccess } from "..";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import PredefinedRanges from "./DateRangeData/DateRangeData";
import baseUrl from "../config";
import axios from "axios";
import authHeader from "./Authentication/authHeader";
import Swal from "sweetalert2";

const HomePage = () => {
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("loggedInUser"))
  );

  console.log(isLoggedIn);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState([]);

  const handleDateRage = (value) => {
    setValue(value);
  };

  const fetchCountData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseUrl}/employee/employee_registration_count/${userProfile?.employee_id}/`,
        {
          headers: authHeader(),
          params: {
            dateRange: value,
          },
        }
      );
      setCountData(response?.data);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCountData();
  }, [value]);
  return (
    <div className="homepageContainer container">
      {/* <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo-image" />
      </div> */}
      <div className="welcomeHeaderSection">
        <h4>
          Welcome{" "}
          <strong>
            {isLoggedIn?.loggedInUser?.first_name +
              " " +
              isLoggedIn?.loggedInUser?.last_name}
            !
          </strong>
        </h4>
      </div>

      <Divider>
        <h5 style={{ color: "#19801d" }}>
          <strong>ALL STATS</strong>
        </h5>
      </Divider>
      <div className="mb-2">
        <DateRangePicker
          ranges={PredefinedRanges}
          onChange={handleDateRage}
          value={value}
          showOneCalendar
        />
      </div>
      <div className="card_container">
        <Row gutter={[16, 16]} className="rowWithSpace">
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card title="Total Farmer" className="customCardDesign shadow-sm">
              <div className="cardInnerHeader text-center">
                <h6>
                  <strong>Registration</strong>
                </h6>
                <h6>{countData?.farmer_count || 0}</h6>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card title="Total Retailer" className="customCardDesign shadow-sm">
              <div className="cardInnerHeader text-center">
                <h6>
                  <strong>Registration</strong>
                </h6>
                <h6>{countData?.retailer_count || 0}</h6>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card title="Total LSP" className="customCardDesign shadow-sm">
              <div className="cardInnerHeader text-center">
                <h6>
                  <strong>Registration</strong>
                </h6>
                <h6>{countData?.lsp_count || 0}</h6>
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6}>
            <Card title="Total Trader" className="customCardDesign shadow-sm">
              <div className="cardInnerHeader text-center">
                <h6>
                  <strong>Registration</strong>
                </h6>
                <h6>{countData?.trader_count || 0}</h6>
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="rowWithSpace">
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card title="Total ASP" className="customCardDesign shadow-sm">
              <div className="cardInnerHeader text-center">
                <h6>
                  <strong>Registration</strong>
                </h6>
                <h6>{countData?.asp_count || 0}</h6>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card
              title="Total Institutional Buyer"
              className="customCardDesign shadow-sm"
            >
              <div className="cardInnerHeader text-center">
                <h6>
                  <strong>Registration</strong>
                </h6>
                <h6>{countData?.institutional_buyer_count || 0}</h6>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card
              title="Total Order Taking"
              className="customCardDesign shadow-sm"
            >
              <div className="cardInnerHeader text-center">
                <h6>
                  <strong>Order Taken</strong>
                </h6>
                <h6>{countData?.order_taken_count || 0}</h6>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card
              title="Total Order Fulfillment"
              className="customCardDesign shadow-sm"
            >
              <div className="cardInnerHeader text-center">
                <h6>
                  <strong>Order Fulfilled</strong>
                </h6>
                <h6>{countData?.order_fulfilled_count || 0}</h6>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomePage;
