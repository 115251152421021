import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  Row,
  Col,
  Spin,
  Divider,
} from "antd";

import { loginAccess } from "../../..";
import awsS3 from "aws-sdk/clients/s3";
import { UploadOutlined } from "@ant-design/icons";
import baseUrl from "../../../config";
import axios from "axios";
import authHeader from "../../Authentication/authHeader";
import Swal from "sweetalert2";

export default function ProductSourcing() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gdnImageUrl, setGdnImageUrl] = useState("");
  const [weightBillImageUrl, setWeightBillImageUrl] = useState("");
  // const [bankReceiptImageUrl, setBankReceiptImageUrl] = useState("");
  const [nidImageUrl, setNidImageUrl] = useState("");
  const [sellerType, setSellerType] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [sourcingProductList, setSourcingProductList] = useState([]);

  const today = new Date();
  const todaysDate = new Date(today);
  todaysDate.setDate(today.getDate());
  const sourcingDate = todaysDate.toISOString().slice(0, 10);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/trader/sourcingProductList`, {
        headers: authHeader(),
      })
      .then((data) => setSourcingProductList(data?.data));
  }, []);

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };
    const s3 = new awsS3({
      accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
      secretAccessKey: "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",
      region: "ap-southeast-1",
    });

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });
      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSellerType = (value) => {
    form.setFieldsValue({
      phoneNumber: undefined,
      seller_name: undefined,
      seller_id: undefined,
      district: undefined,
      upazila: undefined,
      union: undefined,
      routing_no: undefined,
      seller_bank_name: undefined,
      seller_account_name: undefined,
      seller_account_no: undefined,
    });
    setSellerType(value);
  };

  const handleSellerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      const url =
        sellerType === "Farmer"
          ? `${baseUrl}/api/farmer_info/${phone}/`
          : `${baseUrl}/trader/trader_info/${phone}/`;
      axios
        .get(`${url}`, {
          headers: authHeader(),
        })
        .then((data) => {
          if (Object.entries(data?.data?.bank_info).length === 0) {
            setReadOnly(false);
          } else {
            setReadOnly(true);
          }
          form.setFieldsValue({
            seller_name: data?.data?.name,
            seller_id: data?.data?.farmer_id || data?.data?.trader_id,
            district: data?.data?.district?.district_name,
            upazila: data?.data?.upazila?.upazila_name,
            union: data?.data?.union?.union_name,
            routing_no: data?.data?.bank_info?.routing_number,
            seller_bank_name: data?.data?.bank_info?.bank_name,
            seller_account_name: data?.data?.bank_info?.account_name,
            seller_account_no: data?.data?.bank_info?.account_number,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            seller_name: null,
            seller_id: null,
            district: null,
            upazila: null,
            union: null,
            routing_no: null,
            seller_bank_name: null,
            seller_account_name: null,
            seller_account_no: null,
          });
          Swal.fire({
            title: "Error",
            text: `${sellerType} Not Found`,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleSubmit = (data) => {
    data.seller_nid_image = nidImageUrl;
    data.gdn_image = gdnImageUrl;
    data.weight_bill_image = weightBillImageUrl;
    setLoading(true);
    axios
      .post(
        `${baseUrl}/sourcing/buying_info/`,
        {
          data: data,
        },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"];
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.resetFields(resetFields);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Product Sourcing Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Purchase Date"
                name="purchase_date"
                initialValue={sourcingDate}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Sourcing Type"
                name="sourcing_type"
                rules={[
                  {
                    required: true,
                    message: "Please select the sourcing type",
                  },
                ]}
              >
                <Select placeholder="Select sourcing Type">
                  <Option value="Direct">Direct</Option>
                  <Option value="Warehouse">Warehouse</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Seller Type"
                name="seller_type"
                rules={[
                  {
                    required: true,
                    message: "Please select the seller type",
                  },
                ]}
              >
                <Select
                  value={sellerType}
                  onChange={handleSellerType}
                  placeholder="Select Seller Type"
                >
                  <Option value="Farmer">Farmer</Option>
                  <Option value="Trader">Trader</Option>
                </Select>
              </Form.Item>
            </Col>
            {sellerType && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Seller Phone Number"
                  name="phoneNumber"
                  onChange={handleSellerInfo}
                  rules={[
                    {
                      required: true,
                      message: "Please enter seller's phone number",
                    },
                    {
                      pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                      message: "Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input placeholder="Seller Phone Number" type="tel" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Seller Name"
                name="seller_name"
                rules={[{ required: true, message: "Seller's name required" }]}
              >
                <Input placeholder="Seller Name" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Seller ID"
                name="seller_id"
                rules={[{ required: true, message: "Seller's ID required" }]}
              >
                <Input placeholder="Seller ID" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                rules={[{ required: true, message: "Seller's name required" }]}
              >
                <Input placeholder="District" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Upazila"
                name="upazila"
                rules={[{ required: true, message: "Seller's name required" }]}
              >
                <Input placeholder="Upazila" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Union"
                name="union"
                rules={[{ required: true, message: "Seller's name required" }]}
              >
                <Input placeholder="Union" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Village"
                name="village"
                initialValue=""
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Village Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Seller Bank Name"
                name="seller_bank_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter seller bank name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Bank Name" readOnly={readOnly} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Seller Bank A/C Name"
                name="seller_account_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter seller bank A/C name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Bank A/C Name" readOnly={readOnly} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Seller A/C No"
                name="seller_account_no"
                rules={[
                  {
                    required: true,
                    message: "Please enter seller A/C no",
                  },
                  {
                    pattern: /^[0-9]+$/, // Regular expression to match English characters and spaces
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="A/C No" readOnly={readOnly} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Routing No"
                name="routing_no"
                rules={[
                  {
                    required: true,
                    message: "Please enter routing no",
                  },
                  {
                    pattern: /^[0-9]+$/, // Regular expression to match English characters and spaces
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Routing No" readOnly={readOnly} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Product Name"
                name="product_name"
                rules={[
                  {
                    required: true,
                    message: "Please select product",
                  },
                ]}
              >
                <Select placeholder="Select product name" showSearch>
                  {sourcingProductList?.map((product) => (
                    <Option
                      key={product.sourcing_product_id}
                      value={product.product_name}
                    >
                      {product.product_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Purchase Quantity (Kg)"
                name="purchase_quantity"
                rules={[
                  {
                    required: true,
                    message: "Please enter purchase quantity",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="Purchase Quantity (Kg)" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="GDN No"
                name="gdn_no"
                rules={[
                  {
                    required: true,
                    message: "Please enter gdn no",
                  },
                  {
                    pattern: /^[a-zA-Z0-9,.\-""\/_]+$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="GDN No" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ANL Purchased Rate(Taka/Kg)"
                name="anl_purchased_rate"
                rules={[
                  {
                    required: true,
                    message: "Please enter ANL purchased rate",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/, // Regular expression to match English characters and spaces
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="ANL Purchased Rate" />
              </Form.Item>
            </Col>
          </Row>
          <header className="my-3" style={{ fontSize: 16 }}>
            <strong>Quality Check Info :</strong>
          </header>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Moisture"
                name="moisture"
                rules={[
                  {
                    required: true,
                    message: "Please select moisture level",
                  },
                ]}
              >
                <Select placeholder="Select Moisture Level">
                  <Option value="1%-3%">1%-3%</Option>
                  <Option value="3%-5%">3%-5%</Option>
                  <Option value="5%-7%">5%-7%</Option>
                  <Option value="7%-9%">7%-9%</Option>
                  <Option value="9%-11%">9%-11%</Option>
                  <Option value="11%-13%">11%-13%</Option>
                  <Option value="13%-15%">13%-15%</Option>
                  <Option value="15%-17%">15%-17%</Option>
                  <Option value="17%-19%">17%-19%</Option>
                  <Option value="19%-21%">19%-21%</Option>
                  <Option value="21%-23%">21%-23%</Option>
                  <Option value="23%-more">23%-more</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Dust"
                name="dust"
                rules={[
                  {
                    required: true,
                    message: "Please select dust level",
                  },
                ]}
              >
                <Select placeholder="Select Dust Level">
                  <Option value="N/A">N/A</Option>
                  <Option value="0%">0%</Option>
                  <Option value="0%-1%">0%-1%</Option>
                  <Option value="1%-2%">1%-2%</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Fungus"
                name="fungus"
                rules={[
                  {
                    required: true,
                    message: "Please select fungus level",
                  },
                ]}
              >
                <Select placeholder="Select Moisture Level">
                  <Option value="N/A">N/A</Option>
                  <Option value="0%">0%</Option>
                  <Option value="0%-1%">0%-1%</Option>
                  <Option value="1%-2%">1%-2%</Option>
                  <Option value="2%-3%">2%-3%</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Comment"
                name="comment"
                rules={[
                  {
                    required: true,
                    message: "Please select comment",
                  },
                ]}
              >
                <Select placeholder="Select Comment">
                  <Option value="Grade A">Grade A</Option>
                  <Option value="Grade B">Grade B</Option>
                  <Option value="Grade C">Grade C</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <header className="mb-3" style={{ fontSize: 16 }}>
            <strong>Truck/Trolly Driver Info :</strong>
          </header>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Truck/Trolly Driver Name"
                name="driver_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter driver name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Truck/Trolly Driver Name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Driver Number"
                name="driver_number"
                rules={[
                  {
                    required: true,
                    message: "Please enter driver's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="DriverPhone Number" type="tel" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Number of Sac"
                name="number_of_sac"
                rules={[
                  {
                    required: true,
                    message: "Please enter number of sac",
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Number of Sac" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Truck No"
                name="truck_no"
                initialValue=""
              >
                <Input placeholder="Truck No" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Image of Goods Delivery Note"
                name="gdn_image"
                valuePropName="fileList"
                placeholder="Upload GDN image"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setGdnImageUrl)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading
                      ? "Uploading"
                      : "Upload Goods Delivery Note Image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Image of Weight Bill"
                name="weight_bill_image"
                valuePropName="fileList"
                placeholder="Upload weight bill image"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) =>
                    handleImageUpload(info, setWeightBillImageUrl)
                  }
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload Weight Bill Image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Image of Seller NID"
                name="seller_nid_image"
                valuePropName="fileList"
                placeholder="Upload seller nid image"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setNidImageUrl)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload Seller Nid Image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
