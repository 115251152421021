import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Upload, Button, Row, Col, Spin } from "antd";
import { loginAccess } from "../../..";
import awsS3 from "aws-sdk/clients/s3";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import baseUrl from "../../../config";
import Swal from "sweetalert2";
import authHeader from "../../Authentication/authHeader";

export default function BeefSurvey() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const {
    isLoggedIn,
    userPermissions,
    setIsLoggedIn,
    setUserPermissions
  } = useContext(loginAccess);
  const [farmImageUrl, setFarmImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cowDungSelling, setCowDungSelling] = useState([]);
  const [vaccineType, setVaccineType] = useState([]);
  const [deWormingStatus, setDeWormingStatus] = useState([]);
  const [readyFeedGiven, setReadyFeedGiven] = useState([]);
  const [fundType, setFundType] = useState([]);
  const [weightingCattle, setWeightingCattle] = useState([]);
  const [recipientPay, setRecipientPay] = useState([]);
  const [involvement, setInvolvement] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
            district: data?.data?.district?.district_name,
            upazila: data?.data?.upazila?.upazila_name,
            union: data?.data?.union?.union_name,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
            district: undefined,
            upazila: undefined,
            union: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };
    const s3 = new awsS3({
      accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
      secretAccessKey: "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",
      region: "ap-southeast-1",
    });
    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleWeightingCattle = (value) => {
    setWeightingCattle(value);
  };
  const handleCowDungSelling = (value) => {
    setCowDungSelling(value);
  };

  const handleVaccineType = (value) => {
    setVaccineType(value);
  };
  const handleDeWormingStatus = (value) => {
    setDeWormingStatus(value);
  };

  const handleReadyFeedGiven = (value) => {
    setReadyFeedGiven(value);
  };

  const handleFund = (value) => {
    setFundType(value);
  };
  const handleRecipientPay = (value) => {
    setRecipientPay(value);
  };
  const handleInvolvement = (value) => {
    setInvolvement(value);
  };

  const handleSubmit = (data) => {
    setLoading(true);
    data.farm_photo = farmImageUrl;
    fetch(
      "https://script.google.com/macros/s/AKfycbzWMp43UsLClmWnp8kcj7CFKRurMXyx4P2w9dnydszhSF7EMJkzcz7G_05zA5Eqoow_/exec",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status.toLowerCase() === "success") {
          Swal.fire({
            title: "Success",
            text: "Beef Survey Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            setInvolvement([]);
            setRecipientPay([]);
            setFundType([]);
            setReadyFeedGiven([]);
            setDeWormingStatus([]);
            setVaccineType([]);
            setCowDungSelling([]);
            setWeightingCattle([]);

            form.resetFields(resetFields);
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Error",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Beef Survey Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারির ফোন নম্বর (Phone Number)"
                name="phoneNumber"
                onChange={handleFarmerInfo}
                rules={[
                  {
                    required: true,
                    message: "Please enter farmer's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input
                  placeholder="খামারির ফোন নম্বর (Phone Number)"
                  type="tel"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer ID"
                name="farmer_id"
                // onChange={handleRetailerInfo}
                rules={[
                  {
                    required: true,
                    message: "Farmer ID required",
                  },
                ]}
              >
                <Input placeholder="Farmer ID" type="tel" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Farmer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারির নাম (Farmer Name)"
                name="farmer_name"
                rules={[
                  { required: true, message: "Please enter farmer name" },
                ]}
              >
                <Input placeholder="খামারির নাম" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারের ছবি (Farm Photo)"
                name="farm_photo"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setFarmImageUrl)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload Product Image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                rules={[{ required: true, message: "District required" }]}
              >
                <Input placeholder="District" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Upazila"
                name="upazila"
                rules={[{ required: true, message: "Upazila required" }]}
              >
                <Input placeholder="Upazila" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Union"
                name="union"
                rules={[{ required: true, message: "Union required" }]}
              >
                <Input placeholder="Union" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="খামারের আয়তন (Farm Size) sqkm"
              name="farm_size"
              initialValue={0}
              rules={[
                {
                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Only English numbers",
                },
              ]}
            >
              <Input placeholder="খামারের আয়তন (sqkm)" inputMode="numeric"/>
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="নিজের টাকায় কেনা নাকি লোন নিয়ে কেনা? (Self-Funded / Loan)?"
                name="cow_purchase_fund"
                initialValue=""
              >
                <Select placeholder="Select intereste" onChange={handleFund}>
                  <Option value="Self-Funded">Self-Funded</Option>
                  <Option value="Loan">Loan</Option>
                </Select>
              </Form.Item>
            </Col>

            {fundType.includes("Loan") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="লোন নিয়ে থাকলে সংস্থার নাম? (Loaning Institution Name)?"
                  name="loaning_institution"
                  initialValue=""
                >
                  <Input placeholder="লোন নিয়ে থাকলে সংস্থার নাম?" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="বর্তমানে খামারে মোটাতাজাকরনের গরুর সংখ্যা কত (Number of fattening cows)?"
                name="number_of_fattening_cows"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="বর্তমানে খামারে মোটাতাজাকরনের গরুর সংখ্যা কত?"  inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারে ষাঁড়ের সংখ্যা (Number of bulls)?"
                name="number_of_bulls"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="খামারে ষাঁড়ের সংখ্যা?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারে বলদের সংখ্যা (Number of bullocks)?"
                name="number_of_bullocks"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="খামারে বলদের সংখ্যা?"  inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="মোটাতাজাকরনের জন্যখামারে বকনা/গাভীর সংখ্যা কত (number of heifers/cows)?"
                name="number_of_heifers_or_cows"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="মোটাতাজাকরনের জন্যখামারে বকনা/গাভীর সংখ্যা কত?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরু কেনার পর কি গরুর ওজন মেপে থাকেন (Weighting cattle after buying)?"
                name="weighting_cattle_after_buying"
                initialValue=""
              >
                <Select
                  placeholder="Select Weighting cattle"
                  onChange={handleWeightingCattle}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {weightingCattle.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কি পদ্ধতিতে মাপেন (Measure)?"
                  name="measure_system"
                  initialValue=""
                >
                  <Select placeholder="Select measure system">
                    <Option value="Direct">সরাসরি ওজন করে</Option>
                    <Option value="Measure the chest girth">
                      বুকের বেড় মেপে
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রথমবার ওজন নেয়ার কতদিন পর পুনরায় ওজন মাপেন (Time interval of taking weight)?"
                name="weight_taking_time_interval"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রথমবার ওজন নেয়ার কতদিন পর পুনরায় ওজন মাপেন?" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="আপনি খামারে বছরে কতবার গরু পালন করেন (Times rear cattle in a year)?"
                name="times_rear_cattle_in_year"
                initialValue=""
              >
                <Select placeholder="Select rear cattle in year">
                  <Option value="1">১ বার</Option>
                  <Option value="2">২ বার</Option>
                  <Option value="3">৩ বার</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="মোটাতাজাকরণের জন্য গরু কত মাস পালন করে বিক্রি করেন? (How long do you fatten and sell cows)?"
                name="cows_fatten_then_sell_month"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="মোটাতাজাকরণের জন্য গরু কত মাস পালন করে বিক্রি করেন?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরুগুলোর বর্তমান ওজন আনুমানিক কত (Present weight of cattles)?"
                name="present_weight_of_cattle"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="গরুগুলোর বর্তমান ওজন আনুমানিক কত?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="মোটাতাজাকরনের জন্য পালিত ষাঁড়ের আনুমানিক গড় ওজন কত (Weight of Bull)?"
                name="avg_weight_of_bull"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="মোটাতাজাকরনের জন্য পালিত ষাঁড়ের আনুমানিক গড় ওজন কত?" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="মোটাতাজাকরনের জন্য পালিত বলদের আনুমানিক গড় ওজন কত (weight of bullock)?"
                name="avg_weight_of_bullock"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="মোটাতাজাকরনের জন্য পালিত বলদের আনুমানিক গড় ওজন কত?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="বিক্রি করার সময় কি গরুর ওজন মেপে বিক্রি করেন (Weight cows before sale)?"
              name="weight_cows_before_sale"
              initialValue=""
            >
              <Select placeholder="Select weighting or not">
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরুর গোবর ব্যবস্থাপনা পদ্ধতি কি (Waste Management Method)?"
                name="waste_management_method"
                rules={[
                  {
                    required: true,
                    message: "Please select waste management",
                  },
                ]}
              >
                <Select placeholder="Select waste management" mode="multiple">
                  <Option value="Biogas">বায়োগ্যাস উৎপাদন (Biogas)</Option>
                  <Option value="Sell">বিক্রি করে দেয় (Sell)</Option>
                  <Option value="Organic Fertilizer">
                    জৈব সার উৎপাদন করেন (Organic Fertilizer)
                  </Option>
                  <Option value="Vermicompost">
                    ভার্মি কম্পোস্ট (Vermicompost)
                  </Option>
                  <Option value="Other">অন্যান্য (Other)</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গোবর বিক্রি বা বাবহার করে কোনো আয় হয়(Do you sell cow dung)?"
                name="sell_cow_dung"
                rules={[
                  {
                    required: true,
                    message: "Please select sell",
                  },
                ]}
              >
                <Select
                  placeholder="Select cow dung sell"
                  onChange={handleCowDungSelling}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {cowDungSelling.includes("Yes") && (
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="যদি হয় তাহলে বছরে কত টাকা?"
                name="cow_dung_selling_tk"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="যদি হয় তাহলে বছরে কত টাকা?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          )}
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="আপনি কোন জাতের গরু পালন করছেন (Breed of cattle)?"
                name="breed_of_cattle"
                rules={[
                  {
                    required: true,
                    message: "Please select cattle breeds",
                  },
                ]}
              >
                <Select placeholder="Select cattle breed" mode="multiple">
                  <Option value="Holstein Frisian Cross">
                    হলস্টেইন ফ্রিজিয়ান ক্রস
                  </Option>
                  <Option value="Sahiwal Cross">শাহিওয়াল ক্রস</Option>
                  <Option value="Brahma">ব্রাহমা</Option>
                  <Option value="Local">লোকাল</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="মোটাতাজাকরনের জন্য সাধারণত কত বছর বয়সের গরু পালন করেন (Typical age for fattening cows)?"
                name="typical_age_for_fattening_cows"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="কত বছর বয়সের গরু পালন করেন?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরু ক্রয়ের পর কৃমিনাশক দেন কিনা (De-worming Status)?"
                name="de_worming_status"
                initialValue=""
              >
                <Select
                  placeholder="Select De-worming Status"
                  onChange={handleDeWormingStatus}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {deWormingStatus.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কৃমি নাশক দিতে আপনার গরু প্রতি কত খরচ হয় (Cost of deworming)?"
                  name="cost_of_deworming"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="কৃমি নাশক দিতে আপনার গরু প্রতি কত খরচ হয়?" inputMode="numeric" />
                </Form.Item>
              </Col>
            )}
            {deWormingStatus.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কৃমিনাশকের নাম (Name of dewarmer)?"
                  name="name_of_dewarmer"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English characters",
                    },
                  ]}
                >
                  <Input placeholder="কৃমিনাশকের নাম?" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="টিকা প্রয়োগ করেন কি রোগের বিরুদ্ধে (Vaccination against what disease)?"
                name="vaccination_against_what_disease"
                rules={[
                  {
                    required: true,
                    message: "Please select from the given list",
                  },
                ]}
              >
               <Select placeholder="Select vaccination" mode="multiple">
                  <Option value="FMD">ক্ষুরা</Option>
                  <Option value="Anthrax">তড়কা</Option>
                  <Option value="Black Quarter">বাদলা</Option>
                  <Option value="LSD">লাম্ফি</Option>
                  <Option value="None">None</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="কোন টিকা ব্যবহার করেন (Which vaccine do you use?)?"
                name="vaccine_used"
                initialValue=""
              >
                <Select
                  placeholder="Select vaccine type"
                  onChange={handleVaccineType}
                >
                  <Option value="Govt">সরকারি টিকা</Option>
                  <Option value="Private">কোম্পানির টিকা</Option>
                </Select>
              </Form.Item>
            </Col>
            {vaccineType.includes("Private") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কোম্পানির টিকা হলে কোম্পানির নাম (If Private company name)?"
                  name="vaccine_company_name"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English characters",
                    },
                  ]}
                >
                  <Input placeholder="কোম্পানির টিকা হলে কোম্পানির নাম?" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="টিকা দেয়ার জন্য গরু প্রতি কত খরচ পড়ে (Cost of vaccine)?"
                name="cost_of_vaccine"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="টিকা দেয়ার জন্য গরু প্রতি কত খরচ পড়ে?"  inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="কে টিকা প্রয়োগ করেন (Who give the vaccine)?"
                name="vaccination_provider"
                rules={[{
                  required:true,
                  message:"Please select from the list"
                }]}
              >
                <Select
                  placeholder="Select vaccination provider"
                  mode="multiple"
                >
                  <Option value="Union Vet">ইউনিয়ন পশুচিকিৎসক </Option>
                  <Option value="Govt Vet">সরকারি পশুচিকিৎসক </Option>
                  <Option value="Local Vet">লোকাল পশুচিকিৎসক </Option>
                  <Option value="A.I Service Provider">
                    A.I সেবা প্রদানকারী
                  </Option>
                  <Option value="Polli Vet">পল্লী পশুচিকিৎসক </Option>
                  <Option value="A.I Technitian">A.I প্রযুক্তিবিদ </Option>
                  <Option value="None">None</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="টিকা প্রয়োগকারী কে কোন টাকা দিতে হয় (Does the recipient pay for the vaccine)?"
                name="recipient_pay_for_vaccine"
                initialValue=""
              >
                <Select
                  placeholder="Select recipient pay"
                  onChange={handleRecipientPay}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {recipientPay.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কত টাকা দিতে হয় (Money to pay)?"
                  name="recipient_amount"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English characters",
                    },
                  ]}
                >
                  <Input placeholder="কত টাকা দিতে হয়?" inputMode="numeric" />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="টিকা কিভাবে জোগাড় করে থাকেন (How do you get the vaccine?)?"
                name="how_to_arrange_vaccine"
                initialValue=""
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="টিকা কিভাবে জোগাড় করে থাকেন?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরু মোটাতাজাকরণে ভিটামিন, টনিক ইত্যাদি ব্যবহার করেন (Use any vitamin or tonic)?"
                name="fattening_use_any_vitamin_or_tonic"
                initialValue=""
              >
                <Select placeholder="Select">
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতিটি গরুকে দৈনিক কতটুকু গমের ভুষি খাওয়ান (Daily wheat bran amount per cow)?"
                name="daily_wheat_bran_amount_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রতিটি গরুকে দৈনিক কতটুকু গমের ভুষি খাওয়ান?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দৈনিক গমের ভুষি কেনার জন্য গরু প্রতি কত খরচ হয় (Daily cost of Wheat bran per cow)?"
                name="daily_cost_of_wheat_bran_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দৈনিক গমের ভুষি কেনার জন্য গরু প্রতি কত খরচ হয়?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতিটি গরুকে দৈনিক কতটুকুূ ডাল/ডালের ভুষি খাওয়ান (Daily broken pulse amount per cow)?"
                name="daily_broken_pulse_amount_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রতিটি গরুকে দৈনিক কতটুকুূ ডাল/ডালের ভুষি খাওয়ান?" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দৈনিক ডাল/ডালের  কেনার জন্য গরু প্রতি কত খরচ হয় (Daily cost of Broken pulse per cow)?"
                name="daily_cost_of_broken_pulse_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দৈনিক ডাল/ডালের  কেনার জন্য গরু প্রতি কত খরচ হয়?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতিটি গরুকে দৈনিক কতটুকু তিল/সরিষার/সয়াবিন খৈল খাওয়ান (Daily oil cake amount per cow)?"
                name="daily_oil_cake_amount_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রতিটি গরুকে দৈনিক কতটুকু তিল/সরিষার/সয়াবিন খৈল খাওয়ান?"  inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দৈনিক তিল/সরিষার/সয়াবিন খৈল কেনার জন্য গরু প্রতি কত খরচ হয়? (Daily cost of oil cake per cow)?"
                name="daily_cost_of_oil_cake_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দৈনিক তিল/সরিষার/সয়াবিন খৈল কেনার জন্য গরু প্রতি কত খরচ হয়?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতিটি গরুকে দৈনিক কতটুকু ভুট্টা ভাঙ্গা খাওয়ান (Daily Broken Maize amount per cow)?"
                name="daily_broken_maize_amount_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রতিটি গরুকে দৈনিক কতটুকু ভুট্টা ভাঙ্গা খাওয়ান?" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দৈনিক ভুট্টা ভাঙ্গা কেনার জন্য গরু প্রতি কত খরচ হয় (Daily cost of Broken maize per cow)?"
                name="daily_cost_of_broken_maize_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দৈনিক ভুট্টা ভাঙ্গা কেনার জন্য গরু প্রতি কত খরচ হয়?"  inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতিটি গরুকে দৈনিক কতটুকু চাল/ চালের খুদ খাওয়ান (Daily Broken Rice amount per cow)?"
                name="daily_broken_rice_amount_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রতিটি গরুকে দৈনিক কতটুকু চাল/ চালের খুদ খাওয়ান?"  inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দৈনিক চাল/ চালের খুদ কেনার জন্য গরু প্রতি কত খরচ হয় (Daily cost of Broken rice per cow)?"
                name="daily_cost_of_broken_rice_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দৈনিক চাল/ চালের খুদ কেনার জন্য গরু প্রতি কত খরচ হয়?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতিটি গরুকে দৈনিক কতটুকু অন্যান্য খাদ্য খাওয়ান (Daily other food amount per cow)?"
                name="daily_other_food_amount_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রতিটি গরুকে দৈনিক কতটুকু অন্যান্য খাদ্য খাওয়ান?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দৈনিক অন্যান্য খাদ্য কেনার জন্য গরু প্রতি কত খরচ হয় (Daily cost of other feed per cow)?"
                name="daily_cost_of_other_food_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দৈনিক অন্যান্য খাদ্য কেনার জন্য গরু প্রতি কত খরচ হয়?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="বাড়িতে উপকরণ মিশিয়ে খাবার বানালে কেজি প্রতি কত খরচ পড়ে (Home ingredient mixing cost per kg)?"
              name="home_ingredient_mixing_cost_per_kg"
              initialValue={0}
              rules={[
                {
                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Only English numbers",
                },
              ]}
            >
              <Input placeholder="বাড়িতে উপকরণ মিশিয়ে খাবার বানালে কেজি প্রতি কত খরচ পড়ে?" inputMode="numeric"/>
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতিটি গরুকে দৈনিক কি পরিমান দানাদার খাবার খাওয়ান (Daily grain amount per cow)?"
                name="daily_grain_amount_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রতিটি গরুকে দৈনিক কি পরিমান দানাদার খাবার খাওয়ান?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দানাদার কোথা থেকে কিনেন (Where do you buy grain)?"
                name="where_to_buy_grain"
                initialValue=""
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="দানাদার কোথা থেকে কিনেন?" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতিটি গরুকে দৈনিক কতটুকু ঘাস দেন (Daily grass amount per cow)?"
                name="daily_grass_amount_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রতিটি গরুকে দৈনিক কতটুকু ঘাস দেন?" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ঘাস কিনে খাওয়ালে দৈনিক কত টাকার ঘাস প্রতিটি গরুকে দেন (Daily grass amount per cow when fed)?"
                name="daily_grass_amount_per_cow_when_fed"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="ঘাস কিনে খাওয়ালে দৈনিক কত টাকার ঘাস প্রতিটি গরুকে দেন?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="নিজস্ব ঘাস ক্ষেত থাকলে নিজের গরুকে খাওয়ানোর পর কোন ঘাস বাজারে বিক্রি করেন (Grass sold after feeding cows)?"
                name="grass_sold_amount_after_feeding_cow"
                initialValue=""
              >
                <Select placeholder="Select grass sold">
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দৈনিক গড়ে কত টাকার ঘাস বিক্রি করেন (Average daily grass sales)?"
                name="avg_daily_grass_sales"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দৈনিক গড়ে কত টাকার ঘাস বিক্রি করেন?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরুকে দৈনিক খড় কতটুকু দেন (Amount of hay per day)?"
                name="daily_amount_of_hay_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="গরুকে দৈনিক খড় কতটুকু দেন?"  inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="এলাকায় খড়ের কেজি আনুমানিক কত?(Local hay price per kg)?"
                name="local_hay_price_per_kg"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="এলাকায় খড়ের কেজি আনুমানিক কত?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতিটি গরুকে দৈনিক কত টাকার খড় খাওয়ান (Daily hay cost per cow)?"
                name="daily_hay_cost_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রতিটি গরুকে দৈনিক কত টাকার খড় খাওয়ান?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরুকে UMS/Block (ইউরিয়া,চিটাগুড়,খড়) খাওয়ান (UMS/Block given status)?"
                name="ums_or_block_given_status"
                initialValue=""
              >
                <Select placeholder="Select UMS/Block given?">
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="কি পরিমাণ খাওয়ান (Amount of given UMS)?"
                name="amount_of_given_ums"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="কি পরিমাণ খাওয়ান?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>

          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="গরুকে প্যাকেটজাত ফিড দেন কি না (Ready feed given status)?"
              name="ready_feed_given_status"
              initialValue=""
            >
              <Select
                placeholder="Select is ready feed given?"
                onChange={handleReadyFeedGiven}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          {readyFeedGiven.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="রেডি ফিডের কোম্পানির নাম (Ready Feed Compnay Name)?"
                  name="cow_ready_feed_company"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English characters",
                    },
                  ]}
                >
                  <Input placeholder="রেডি ফিডের কোম্পানির নাম?" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="গরু প্রতি দৈনিক কতটুকু রেডি ফীড দেন (Amount ready feed given per day)?"
                  name="ready_feed_amount_given_per_day"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="গরু প্রতি দৈনিক কতটুকু রেডি ফীড দেন?"  inputMode="numeric"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কেজি প্রতি রেডি ফীডের দাম কত (Ready feed price per kg)?"
                  name="ready_feed_price_per_kg"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English characters",
                    },
                  ]}
                >
                  <Input placeholder="কেজি প্রতি রেডি ফীডের দাম কত?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরু প্রতি দৈনিক কাঁচা ঘাস,খড়ের,দানাদারের, রেডি ফীড বাবদ মোট খরচ কত পড়ে (Total cattle feed cost)?"
                name="total_cattle_feed_cost"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="গরু প্রতি দৈনিক কাঁচা ঘাস,খড়ের,দানাদারের, রেডি ফীড বাবদ মোট খরচ কত পড়ে?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরু কোথায় বিক্রি করেন (Where to sell cows)?"
                name="where_to_sell_cows"
                initialValue=""
              >
                <Select>
                  <Option value="Home">বাড়ি</Option>
                  <Option value="Bazar">হাটে</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="আপনি কি কোন কোম্পানি/ডিস্ট্রিবিউটর/ NGO সাথে কন্টাক্ট এ আছেন(Involve With another Org)?"
              name="involve_with_another_org"
              initialValue=""
            >
              <Select
                placeholder="Select involvement"
                onChange={handleInvolvement}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          {involvement.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কোম্পানি/ডিস্ট্রিবিউটর/NGO নাম?"
                  name="involve_org_name"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="কোম্পানি/ডিস্ট্রিবিউটর/NGO নাম?" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কোম্পানি/ডিস্ট্রিবিউটর/NGO মোবাইল নম্বর"
                  name="involve_org_phone"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                      message: "Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input placeholder="কোম্পানি/ডিস্ট্রিবিউটর/NGO মোবাইল নম্বর" />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="LSP এর থেকে প্রাপ্ত খামারি কিনা?"
                name="farmers_received_from_lsp"
                initialValue=""
              >
                <Select>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Association এর থেকে প্রাপ্ত খামারি কিনা?"
                name="farmers_received_from_association"
                initialValue=""
              >
                <Select>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Retailer এর থেকে প্রাপ্ত খামারি কিনা?"
                name="farmers_received_from_retailer"
                initialValue=""
              >
                <Select>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Partner Organization এর থেকে প্রাপ্ত খামারি কিনা?"
                name="farmers_received_from_partner_org"
                initialValue=""
              >
                <Select>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="চিকিৎসা প্রদাণকারী নাম (Vet Name)?"
                name="vet_name"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="চিকিৎসা প্রদাণকারী নাম?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="চিকিৎসা প্রদাণকারী ফোন নাম্বার (Vet Phone)"
                name="vet_phone"
                rules={[
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="চিকিৎসা প্রদাণকারী ফোন নাম্বার" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ভ্যাক্সিনেশন প্রদাণকারী নাম (Vaccine provider name)?"
                name="vaccine_provider_name"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="ভ্যাক্সিনেশন প্রদাণকারী নাম?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ভ্যাক্সিনেশন প্রদাণকারী ফোন নাম্বার (Vaccine provider Phone)"
                name="vaccine_provider_phone"
                rules={[
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="ভ্যাক্সিনেশন প্রদাণকারী ফোন নাম্বার" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
