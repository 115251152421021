import React from "react";
import construction from "../../Assets/construction.png";
import "../Form.css";
import { Row, Col } from "antd";

export default function CropSurvey() {
  const isMobile = window.innerHeight < 900;

  return (
    <Row justify="center" align="top" style={{ minHeight: '85vh'}}>
      <Col>
        <img
          src={construction}
          alt="Under Construction"
          style={{
            maxWidth: "100%",
            height: isMobile ? 'auto' : `${window.innerHeight}px`,
            width: "auto",
           
          }}
        />
      </Col>
    </Row>
  );
}
