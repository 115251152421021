import React, { useEffect, useState } from "react";
import { Form, Select, Row, Col } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";

export default function Address() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedUpazila, setSelectedUpazila] = useState(null);
  const [selectedUnion, setSelectedUnion] = useState(null);
  const [district, setDistrict] = useState(null);
  const [upazila, setUpazila] = useState(null);
  const [union, setUnion] = useState(null);

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/districts/`, {
        headers: authHeader(),
      })
      .then((response) => setDistrict(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/districts/${selectedDistrict}/upazilas/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setUpazila(response?.data);
      })
      .catch((error) => console.log(error));
  }, [selectedDistrict]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/upazilas/${selectedUpazila}/unions/`, {
        headers: authHeader(),
      })
      .then((response) => setUnion(response?.data))
      .catch((error) => console.log(error));
  }, [selectedUpazila]);

  const handleDistrictChange = (value) => {
    setSelectedUpazila(null);
    setSelectedUnion(null);
    setSelectedDistrict(value);
  };

  const handleUpazilaChange = (value) => {
    setSelectedUnion(null);
    setSelectedUpazila(value);
  };
  return (
    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
      {/* District */}
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          hasFeedback
          label="District"
          name="district"
          rules={[
            {
              required: true,
              message: "Please select district",
            },
          ]}
        >
          <Select
            value={selectedDistrict}
            onChange={handleDistrictChange}
            placeholder="Select District"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {district?.map((district) => (
              <Option key={district?.district_id} value={district?.district_id}>
                {district?.district_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {/* Upazila */}
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          hasFeedback
          label="Upazila"
          name="upazila"
          rules={[{ required: true, message: "Please select upazila" }]}
          dependencies={["district", "selectedDistrict"]}
        >
          <Select
            value={selectedUpazila}
            onChange={handleUpazilaChange}
            placeholder="Select Upazila"
            disabled={!selectedDistrict}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {selectedDistrict &&
              upazila?.map((upazila) => (
                <Option key={upazila?.upazila_id} value={upazila?.upazila_id}>
                  {upazila?.upazila_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      {/* Union */}
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          hasFeedback
          label="Union"
          name="union"
          rules={[{ required: true, message: "Please select union" }]}
          dependencies={["upazila", "selectedUpazila"]}
        >
          <Select
            value={selectedUnion}
            onChange={(value) => setSelectedUnion(value)}
            placeholder="Select Union"
            disabled={!selectedUpazila}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {selectedUpazila &&
              union?.map((union) => (
                <Option key={union?.union_id} value={union?.union_id}>
                  {union?.union_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
}
