import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Upload, Button, Row, Col, Spin } from "antd";
import { loginAccess } from "../../..";
import awsS3 from "aws-sdk/clients/s3";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import baseUrl from "../../../config";
import Swal from "sweetalert2";
import authHeader from "../../Authentication/authHeader";

export default function DairySurvey() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const {
    isLoggedIn,
    userPermissions,
    setIsLoggedIn,
    setUserPermissions
  } = useContext(loginAccess);
  const [farmImageUrl, setFarmImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCattleTypes, setSelectedCattleTypes] = useState([]);
  const [cowDungSelling, setCowDungSelling] = useState([]);
  const [feedBuyingType, setFeedBuyingType] = useState([]);
  const [deWormingStatus, setDeWormingStatus] = useState([]);
  const [selectedConcentrateType, setSelectedConcentrateType] = useState([]);
  const [selectedVaccineNames, setSelectedVaccineNames] = useState([]);
  const [readyFeedGiven, setReadyFeedGiven] = useState([]);
  const [involvement, setInvolvement] = useState([]);
  const [loanTaken, setLoanTaken] = useState([]);
  const [interested, setInterested] = useState([]);
  const [silageGiven, setSilageGiven] = useState([]);
  const [credit, setCredit] = useState([]);
  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          console.log(data);
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
            district: data?.data?.district?.district_name,
            upazila: data?.data?.upazila?.upazila_name,
            union: data?.data?.union?.union_name,
            village: data?.data?.village,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
            district: undefined,
            upazila: undefined,
            union: undefined,
            village: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };
    const s3 = new awsS3({
      accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
      secretAccessKey: "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",
      region: "ap-southeast-1",
    });
    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleCattleTypeChange = (value) => {
    setSelectedCattleTypes(value);
  };
  const handleCowDungSelling = (value) => {
    setCowDungSelling(value);
  };

  const handleFeedBuying = (value) => {
    setFeedBuyingType(value);
  };
  const handleDeWormingStatus = (value) => {
    setDeWormingStatus(value);
  };

  const handleConcentrateChange = (value) => {
    setSelectedConcentrateType(value);
  };
  const handleVaccineName = (value) => {
    setSelectedVaccineNames(value);
  };

  const handleReadyFeedGiven = (value) => {
    setReadyFeedGiven(value);
  };
  const handleInvolvement = (value) => {
    setInvolvement(value);
  };
  const handleLoanTaken = (value) => {
    setLoanTaken(value);
  };

  const handleInterested = (value) => {
    setInterested(value);
  };
  const handleSilageGiven = (value) => {
    setSilageGiven(value);
  };
  const handleCredit = (value) => {
    setCredit(value);
  };

  const handleSubmit = (data) => {
    setLoading(true);
    data.farm_image = farmImageUrl;
    fetch(
      "https://script.google.com/macros/s/AKfycbwaDrtYyFVbjUUmyocrUsCYHLRxZtvwpYXE85sxnOGJ4VHWtNGwrDC3b77PJ09UYDY-yg/exec",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status.toLowerCase() === "success") {
          Swal.fire({
            title: "Success",
            text: "Dairy Survey Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            setSelectedCattleTypes([]);
            setCowDungSelling([]);
            setFeedBuyingType([]);
            setDeWormingStatus([]);
            setSelectedConcentrateType([]);
            setSelectedVaccineNames([]);
            setReadyFeedGiven([]);
            setInvolvement([]);
            setLoanTaken([]);
            setInterested([]);
            setSilageGiven([]);
            setCredit([]);

            form.resetFields(resetFields);
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Error",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Dairy Survey Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারির ফোন নম্বর (Phone Number)"
                name="phoneNumber"
                onChange={handleFarmerInfo}
                rules={[
                  {
                    required: true,
                    message: "Please enter farmer's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input
                  placeholder="খামারির ফোন নম্বর (Phone Number)"
                  type="tel"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer ID"
                name="farmer_id"
                rules={[
                  {
                    required: true,
                    message: "Farmer ID required",
                  },
                ]}
              >
                <Input placeholder="Farmer ID" type="tel" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Farmer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারির নাম (Farmer Name)"
                name="farmer_name"
                rules={[{ required: true, message: "Farmer name required" }]}
              >
                <Input placeholder="খামারির নাম" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারের ছবি (Farm Photo)"
                name="farm_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setFarmImageUrl)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload Product Image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                rules={[{ required: true, message: "District required" }]}
              >
                <Input placeholder="District" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Upazila"
                name="upazila"
                rules={[{ required: true, message: "Upazila required" }]}
              >
                <Input placeholder="Upazila" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Union"
                name="union"
                rules={[{ required: true, message: "Union required" }]}
              >
                <Input placeholder="Union" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Village"
                name="village"
                rules={[{ required: true, message: "Village required" }]}
              >
                <Input placeholder="Village" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারের আয়তন (Farm Size) sqkm"
                name="farm_size"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="খামারের আয়তন (sqft)" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারের গরুর ধরন (Types of Cattle)"
                name="types_of_cattle"
                rules={[
                  {
                    required: true,
                    message: "Please select cattle type",
                  },
                ]}
              >
                <Select
                  placeholder="Select cattle type"
                  mode="multiple"
                  onChange={handleCattleTypeChange}
                >
                  <Option value="Pregnant Cow">
                    গর্ভবতী গাভী (Pregnant Cow)
                  </Option>
                  <Option value="Cross Breed Milking Cow">
                    সংকর দুগ্ধবতী গাভী (Cross Breed Milking Cow)
                  </Option>
                  <Option value="Local Milking Cow">
                    লোকাল দুগ্ধবতী গাভী (Local Milking Cow)
                  </Option>
                  <Option value="Heifer">বকনা (Heifer)</Option>
                  <Option value="Cow Calf">বকনা বাছুর (Cow Calf)</Option>
                  <Option value="Bull Calf">ষাঁড় বাছুর (Bull Calf)</Option>
                  <Option value="Bull">ষাঁড় (Bull)</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {selectedCattleTypes.includes("Pregnant Cow") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="গর্ভবতী গাভীর সংখ্যা (Number of Pregnant Cow)?"
                  name="pregnant_cow_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter pregnant cow number",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="গর্ভবতী গাভীর সংখ্যা" />
                </Form.Item>
              </Col>
            )}
            {selectedCattleTypes.includes("Cross Breed Milking Cow") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="সংকর দুগ্ধবতী গাভী সংখ্যা(Cross breed number for Milking Cow)?"
                  name="cross_milking_cow_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter milking cow number",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="সংকর দুগ্ধবতী গাভী সংখ্যা" />
                </Form.Item>
              </Col>
            )}
            {selectedCattleTypes.includes("Local Milking Cow") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="লোকাল দুগ্ধবতী গাভী সংখ্যা(Local number for Milking Cow)?"
                  name="local_milking_cow_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter local milking cow number",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="লোকাল দুগ্ধবতী গাভী সংখ্যা" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {selectedCattleTypes.includes("Heifer") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="বকনা সংখ্যা (Number of Heifer)?"
                  name="heifer_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter heifer cow number",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="বকনা সংখ্যা" />
                </Form.Item>
              </Col>
            )}
            {selectedCattleTypes.includes("Cow Calf") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="বকনা বাছুর সংখ্যা(Number of Cow Calf)?"
                  name="cow_calf_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter cow calf number",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="বকনা বাছুর সংখ্যা" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {selectedCattleTypes.includes("Bull Calf") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="ষাঁড় বাছুর সংখ্যা(Number of Bull Calf)?"
                  name="bull_calf_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter bull calf number",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="ষাঁড় বাছুর সংখ্যা" />
                </Form.Item>
              </Col>
            )}
            {selectedCattleTypes.includes("Bull") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="ষাঁড়ের সংখ্যা(Number of Bull)?"
                  name="bull_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter bull number",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="ষাঁড়ের সংখ্যা" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গরুর গোবর ব্যবস্থাপনা পদ্ধতি কি (Waste Management Method)?"
                name="waste_management_method"
                rules={[
                  {
                    required: true,
                    message: "Please select waste management",
                  },
                ]}
              >
                <Select placeholder="Select waste management" mode="multiple">
                  <Option value="Biogas">বায়োগ্যাস উৎপাদন (Biogas)</Option>
                  <Option value="Sell">বিক্রি করে দেয় (Sell)</Option>
                  <Option value="Organic Fertilizer">
                    জৈব সার উৎপাদন করেন (Organic Fertilizer)
                  </Option>
                  <Option value="Vermicompost">
                    ভার্মি কম্পোস্ট (Vermicompost)
                  </Option>
                  <Option value="Other">অন্যান্য (Other)</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গোবর বিক্রি বা বাবহার করে কোনো আয় হয়(Do you sell cow dung)?"
                name="sell_cow_dung"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please select sell",
                  },
                ]}
              >
                <Select
                  placeholder="Select cow dung sell"
                  onChange={handleCowDungSelling}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {cowDungSelling.includes("Yes") && (
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="যদি হয় তাহলে বছরে কত টাকা?"
                name="cow_dung_selling_tk"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="যদি হয় তাহলে বছরে কত টাকা?" inputMode="numeric" />
              </Form.Item>
            </Col>
          )}
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খাবার কোথা থেকে কিনেন (Feed Buying place)?"
                name="feed_buying_place"
                initialValue=""
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="খাবার কোথা থেকে কিনেন?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খাবার নগদে কিনেন নাকি বাকিতে (Cash/Due)?"
                name="feed_buy_cash_or_due"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please select buying type",
                  },
                ]}
              >
                <Select
                  placeholder="Select buying type"
                  onChange={handleFeedBuying}
                >
                  <Option value="Cash">Cash</Option>
                  <Option value="Due">Due</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {feedBuyingType.includes("Due") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="বাকি তে কিনলে কি নগদের থেকে বেশি মূল্য দিতে হয় (Price variance)?"
                  name="due_feed_buy_price_variance"
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: "Please select price variance",
                    },
                  ]}
                >
                  <Select placeholder="Select price variance">
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="বাকিতে কিনলে কতদিনের মধ্যে বাকি পরিশোধ করেন? (Due paying days)?"
                  name="due_paying_days"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="বাকিতে কিনলে কতদিনের মধ্যে বাকি পরিশোধ করেন?"  inputMode="numeric"/>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="আপনার গরুকে কৃমিনাশক দেন কিনা (De-worming Status)?"
                name="de_worming_status"
                rules={[
                  {
                    required: true,
                    message: "Please Select De-worming Status",
                  },
                ]}
              >
                <Select
                  placeholder="Select De-worming Status"
                  onChange={handleDeWormingStatus}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {deWormingStatus.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কত বয়সে ১ম বার কৃমিনাশক দেন (Age (month) of first deworming)?"
                  name="month_of_first_deworming"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="কত বয়সে ১ম বার কৃমিনাশক দেন?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {deWormingStatus.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কত মাস পর পর কৃমিনাশক দেন (Time interval of deworming)?"
                  name="time_interval_of_deworming"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="কত মাস পর পর কৃমিনাশক দেন?" inputMode="numeric" />
                </Form.Item>
              </Col>
            )}
            {deWormingStatus.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কৃমি নাশক দিতে আপনার গরু প্রতি কত খরচ হয় (Cost of deworming)?"
                  name="cost_of_deworming"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="কৃমি নাশক দিতে আপনার গরু প্রতি কত খরচ হয়?" inputMode="numeric" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="কত মাস বয়স হলে বাছুরকে দানাদার খাওয়ান (Age of concentrate given(month))?"
                name="calf_age_of_concentrate_given_month"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="কত মাস বয়স হলে বাছুরকে দানাদার খাওয়ান?" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="আপনার বাছুরকে গড়ে দৈনিক দানাদার কি পরিমান দেন (Daily Concentrate Amount (kg))?"
                name="calf_daily_concentrate_amount_kg"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="আপনার বাছুরকে গড়ে দৈনিক দানাদার কি পরিমান দেন?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গড়ে দৈনিক দানাদার ক্রয় করতে কত খরচ পড়ে (Daily Concentrate cost (price))?"
                name="daily_concentrate_cost"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="গড়ে দৈনিক দানাদার ক্রয় করতে কত খরচ পড়ে?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="আপনার গরুকে কি কি দানাদার খাওয়ান (Concentrate types)?"
                name="concentrate_types"
                rules={[
                  {
                    required: true,
                    message: "Please select concentrate type",
                  },
                ]}
              >
                <Select
                  placeholder="Select concentrate type"
                  mode="multiple"
                  onChange={handleConcentrateChange}
                >
                  <Option value="Wheat Bran">গমের ভুষি (Wheat Bran)</Option>
                  <Option value="Broken Pulse">
                    ডালের ভুষি (Broken Pulse)
                  </Option>
                  <Option value="Broken Gram">ছোলা ভাঙা (Broken Gram)</Option>
                  <Option value="Til Oil Cake">তিলের খৈল (Til Oil Cake)</Option>
                  <Option value="Cowpea">কোলাই ভাঙা (Cowpea)</Option>
                  <Option value="Broken Maize">
                    ভুট্টা ভাঙা (Broken Maize)
                  </Option>
                  <Option value="Mineral">খনিজ দ্রব্য (Mineral)</Option>
                  <Option value="Salt">লবন (Salt)</Option>
                  <Option value="Rice Polish">চালের কুড়া (Rice Polish)</Option>
                  <Option value="Others">অন্যান্য (Others)</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {selectedConcentrateType.includes("Wheat Bran") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="গমের ভুষি পরিমান বস্তা প্রতি (Amount BST)?"
                  name="wheat_bran_kg_per_bst"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="গমের ভুষি পরিমান বস্তা প্রতি?" inputMode="numeric" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="গমের ভুষি দাম বস্তা প্রতি (Price BST)?"
                  name="wheat_bran_bst_price"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="গমের ভুষি দাম বস্তা প্রতি?" inputMode="numeric"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="গমের ভুষি দাম কেজি প্রতি (Price Kg)?"
                  name="wheat_bran_price_per_kg"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="গমের ভুষি দাম কেজি প্রতি?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedConcentrateType.includes("Rice Polish") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="চাউলের কুড়া পরিমান বস্তা প্রতি (Amount BST)?"
                  name="rice_polish_kg_per_bst"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="চাউলের কুড়া পরিমান বস্তা প্রতি?" inputMode="numeric"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="চাউলের কুড়া দাম বস্তা প্রতি (Price BST)?"
                  name="rice_polish_bst_price"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="চাউলের কুড়া দাম বস্তা প্রতি?" inputMode="numeric" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="চাউলের কুড়া দাম কেজি প্রতি (Price Kg)?"
                  name="rice_polish_price_per_kg"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="চাউলের কুড়া দাম কেজি প্রতি?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedConcentrateType.includes("Til Oil Cake") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="খৈল পরিমান বস্তা প্রতি (Amount BST)?"
                  name="til_oil_cake_kg_per_bst"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="খৈল পরিমান বস্তা প্রতি?" inputMode="numeric"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="খৈল দাম বস্তা প্রতি (Price BST)?"
                  name="til_oil_cake_bst_price"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="খৈল দাম বস্তা প্রতি?" inputMode="numeric" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="খৈল দাম কেজি প্রতি (Price Kg)?"
                  name="til_oil_cake_price_per_kg"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="খৈল দাম কেজি প্রতি?" inputMode="numeric" />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="বাছুর প্রতি দৈনিক কত টাকা খাবার কিনতে হয় (Daily Cost of feed per Calf(tk))?"
                name="daily_cost_of_feed_per_calf"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="বাছুর প্রতি দৈনিক কত টাকা খাবার কিনতে হয়?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারের বাছুরকে কি কি টিকা দেন (Vaccine Name)?"
                name="calf_vaccine_names"
                rules={[
                  {
                    required: true,
                    message: "Please select vaccine",
                  },
                ]}
              >
                <Select
                  placeholder="Select vaccine name"
                  mode="multiple"
                  onChange={handleVaccineName}
                >
                  <Option value="BQ">বাদলা (BQ)</Option>
                  <Option value="Anthrax">তড়কা (Anthrax)</Option>
                  <Option value="FMD">ক্ষুরারোগ (FMD)</Option>
                  <Option value="LSD">লাম্পি স্কিন ডিজিজ(LSD) </Option>
                  <Option value="HS">গলাফুলা (HS)</Option>
                  <Option value="None">None</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {selectedVaccineNames.includes("BQ") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="বাদলা টিকা দেয়ার জন্য বাছুর প্রতি খরচ কত?"
                  name="bq_cost_per_calf"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="বাদলা দেয়ার জন্য বাছুর প্রতি খরচ কত?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            )}
            {selectedVaccineNames.includes("Anthrax") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="তড়কা টিকা দেয়ার জন্য বাছুর প্রতি খরচ কত?"
                  name="anthrax_cost_per_calf"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="তড়কা দেয়ার জন্য বাছুর প্রতি খরচ কত?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            )}
            {selectedVaccineNames.includes("FMD") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="ক্ষুরারোগ টিকা দেয়ার জন্য বাছুর প্রতি খরচ কত?"
                  name="fmd_cost_per_calf"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="ক্ষুরারোগ দেয়ার জন্য বাছুর প্রতি খরচ কত?" inputMode="numeric" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {selectedVaccineNames.includes("LSD") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="লাম্পি স্কিন ডিজিজ টিকা দেয়ার জন্য বাছুর প্রতি খরচ কত?"
                  name="lsd_cost_per_calf"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="লাম্পি স্কিন ডিজিজ দেয়ার জন্য বাছুর প্রতি খরচ কত?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            )}
            {selectedVaccineNames.includes("HS") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="গলাফুলা টিকা দেয়ার জন্য বাছুর প্রতি খরচ কত?"
                  name="hs_cost_per_calf"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="গলাফুলা দেয়ার জন্য বাছুর প্রতি খরচ কত?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="আপনার গাভীর প্রজনন প্রক্রিয়া কি (Insemination Process)?"
                initialValue=""
                name="cow_insemination_process"
              >
                <Select placeholder="Select insemination process">
                  <Option value="AI">কৃত্রিম (AI)</Option>
                  <Option value="Natural(Bull)">
                    স্বাভাবিক (ষাঁড়) (Natural)
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রথমবার প্রজনন কত বছর বয়সে করান (First Insemination (Year))?"
                name="first_insemination_year"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রথমবার প্রজনন কত বছর বয়সে করান?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রজনন করতে কত টাকা খরচ পড়েছে (Cost)?"
                name="insemination_cost"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="প্রজনন করতে কত টাকা খরচ পড়েছে?" inputMode="numeric"/>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গর্ভবতী গাভীকে দৈনিক দানাদার কি পরিমান দেন (Concentrate amount (kg))?"
                name="pregnant_cow_concentrate_amount"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="গর্ভবতী গাভীকে দৈনিক দানাদার কি পরিমান দেন?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="গর্ভবতী গাভীকে রেডি ফিড দেন কি না (Given ready feed)?"
              initialValue=""
              name="pregnant_cow_ready_feed"
            >
              <Select
                placeholder="Select is ready feed given?"
                onChange={handleReadyFeedGiven}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          {readyFeedGiven.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="রেডি ফিড কি পরিমান দিয়ে থাকেন (Ready feed amount)?"
                  initialValue={0}
                  name="pregnant_cow_ready_feed_amount"
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="রেডি ফিড কি পরিমান দিয়ে থাকেন?" inputMode="numeric"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="রেডি ফিডের কোম্পানির নাম (Ready Feed Compnay Name)?"
                  name="pregnant_cow_ready_feed_company"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English characters",
                    },
                  ]}
                >
                  <Input placeholder="রেডি ফিডের কোম্পানির নাম?" />
                </Form.Item>
              </Col>
            </Row>
          )}
          {readyFeedGiven.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="প্রতি বস্তায় রেডি ফিডের পরিমান কত (Ready feed BST Amount)?"
                  name="ready_feed_kg_per_bst"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="প্রতি বস্তায় রেডি ফিডের পরিমান কত?" inputMode="numeric" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="রেডি ফিডের পন্যের দাম বস্তা প্রতি (Ready feed cost per packet)?"
                  name="ready_feed_packet_price"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English characters",
                    },
                  ]}
                >
                  <Input placeholder="রেডি ফিডের পন্যের দাম বস্তা প্রতি?" inputMode="numeric" />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="গরুকে সাইলেজ খাওয়ান কিনা (Do you feed silage)?"
              name="pregnant_cow_silage_given"
              initialValue=""
            >
              <Select
                placeholder="Select silage given?"
                onChange={handleSilageGiven}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          {silageGiven.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="সাইলেজ দিনে কত টুকু খাওয়ান (Daily feeding amount of silage)?"
                  name="pregnant_cow_daily_silage_amount"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="সাইলেজ দিনে কত টুকু খাওয়ান?" inputMode="numeric"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="সাইলেজ কোথা থেকে সংগ্রহ করে থাকেন (Silage buying place)?"
                  name="silage_buying_place"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English characters",
                    },
                  ]}
                >
                  <Input placeholder="সাইলেজ কোথা থেকে সংগ্রহ করে থাকেন?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            </Row>
          )}
          {silageGiven.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="সাইলেজ কিনতে দৈনিক কত খরচ পরে (Daily cost of buying silage)?"
                  name="daily_cost_of_buying_silage"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="সাইলেজ কিনতে দৈনিক কত খরচ পরে?" inputMode="numeric"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কোন কোম্পানির সাইলেজ কিনেন (Silage buying company name)?"
                  name="silage_buying_compnay_name"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English characters",
                    },
                  ]}
                >
                  <Input placeholder="কোন কোম্পানির সাইলেজ কিনেন?" />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="গর্ভবতী গাভীকে দৈনিক কতটুকু খড় দেন (Daily hay feed)?"
                name="pregnant_cow_daily_hay_feed_amount_kg"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="গর্ভবতী গাভীকে দৈনিক কতটুকু খড় দেন?" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খড় কিনতে কত টাকা খরচ পড়ে (Hay purchase price)?"
                name="hay_purchase_price_per_kg"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="খড় কিনতে কত টাকা খরচ পড়ে?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="প্রতি গর্ভবতী গাভীর জন্য দৈনিক কত টাকা খাবার কিনতে হয় (Per day cost to feed a pregnant cow)?"
                name="daily_cost_feeding_a_pregnant_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="প্রতি গর্ভবতী গাভীর জন্য দৈনিক কত টাকা খাবার কিনতে হয়?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দুগ্ধ প্রদানকারী গাভীকে দৈনিক দানাদার কি পরিমান দেন (Daily Concentrate amount for milking cow)?"
                name="milking_cow_daily_concentrate_kg"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="দুগ্ধ প্রদানকারী গাভীকে দৈনিক দানাদার কি পরিমান দেন?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="দুগ্ধ প্রদানকারী গাভীকে রেডি ফিড দেন কি না (Given ready feed)?"
              name="milking_cow_ready_feed"
              initialValue=""
            >
              <Select placeholder="Select is ready feed given?">
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="রেডি ফিড কি পরিমান দিয়ে থাকেন (daily)?"
                name="milking_cow_daily_ready_feed_amount"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="রেডি ফিড কি পরিমান দিয়ে থাকেন?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দুগ্ধ প্রদানকারী গাভীকে দৈনিক খড় কতটুকু দেন (Daily hay consumption(kg))?"
                name="milking_cow_daily_hay_consumption"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="দুগ্ধ প্রদানকারী গাভীকে দৈনিক খড় কতটুকু দেন?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দুগ্ধ প্রদানকারী গাভীকে সাইলেজ কি পরিমান দেন? (Average silage consumption(kg))"
                initialValue={0}
                name="milking_cow_average_silage_consumption"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দুগ্ধ প্রদানকারী গাভীকে সাইলেজ কি পরিমান দেন?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দুগ্ধ প্রদানকারী গাভী প্রতি দৈনিক কত টাকা খাবার কিনতে হয় (Daily feed cost for a milking cow)?"
                name="per_milking_cow_daily_feed_cost"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দুগ্ধ প্রদানকারী গাভী প্রতি দৈনিক কত টাকা খাবার কিনতে হয়?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দুগ্ধ প্রদানকারী গাভীকে প্রতিদিন কত লিটার পানি দেন (Daily water consumption(Liter))?"
                name="milking_cow_daily_water_consumption_liter"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দুগ্ধ প্রদানকারী গাভীকে প্রতিদিন কত লিটার পানি দেন?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামার থেকে গড়ে কত লিটার দুধ উৎপাদন হয় প্রতিদিন (Avg milk production per day)?"
                name="farm_avg_milk_production_per_day"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="খামার থেকে গড়ে কত লিটার দুধ উৎপাদন হয় প্রতিদিন?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="একটি গাভি থেকে দৈনিক সর্বোচ্চ কত লিটার দুধ পান(Daily maximum milk production)?"
                name="daily_maximum_milk_production_per_cow"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="একটি গাভি থেকে দৈনিক সর্বোচ্চ কত লিটার দুধ পান?" inputMode="numeric"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারে বর্তমানে কইটি গরু দুধ দেয় (Milk producing milking cow)?"
                name="milk_producing_milking_cow_number"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="খামারে বর্তমানে কইটি গরু দুধ দেয়?" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="দুধ কোথায় বিক্রি করেন (Milk selling place)?"
                name="milk_selling_place"
                initialValue=""
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="দুধ কোথায় বিক্রি করেন?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="লিটার প্রতি দুধের বিক্রয়মূল্য কত পান (Milk sellig price per(L))?"
                name="milk_selling_price_per_liter"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="লিটার প্রতি দুধের বিক্রয়মূল্য কত পান?" inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="আপনি কি কোন কোম্পানি/ডিস্ট্রিবিউটর/ NGO সাথে কন্টাক্ট এ আছেন(Involve With another Org)?"
              name="involve_with_another_org"
              initialValue=""
            >
              <Select
                placeholder="Select involvement"
                onChange={handleInvolvement}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          {involvement.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কোম্পানি/ডিস্ট্রিবিউটর/NGO নাম?"
                  name="involve_org_name"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="কোম্পানি/ডিস্ট্রিবিউটর/NGO নাম?" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কোম্পানি/ডিস্ট্রিবিউটর/NGO মোবাইল নম্বর"
                  name="involve_org_phone"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                      message: "Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input placeholder="কোম্পানি/ডিস্ট্রিবিউটর/NGO মোবাইল নম্বর" />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="কোন NGO থেকে ঋণ নিয়েছেন (Taken a loan from any NGO)?"
              name="taken_loan_from_any_ngo"
              initialValue=""
            >
              <Select
                placeholder="Select taken loan or not"
                onChange={handleLoanTaken}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          {loanTaken.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কত টাকা ঋণ নিয়েছেন (Loan amount)?"
                  name="loan_amount"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="কত টাকা ঋণ নিয়েছেন?" inputMode="numeric"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কোন কাজের জন্য নিয়েছেন (Purpose)?"
                  name="loan_taken_purpose"
                  initialValue=""
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English Characters",
                    },
                  ]}
                >
                  <Input placeholder="কোন কাজের জন্য নিয়েছেন?" />
                </Form.Item>
              </Col>
            </Row>
          )}

          {loanTaken.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="সপ্তাহে কিস্তি কত টাকা (Weekly installment)?"
                  name="weekly_installment"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="সপ্তাহে কিস্তি কত টাকা?" inputMode="numeric"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="কত সপ্তাহের কিস্তি বাকি আছে (Weekly installemnts left)?"
                  name="no_of_weekly_installments_left"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                      message: "Only English Characters",
                    },
                  ]}
                >
                  <Input placeholder="কত সপ্তাহের কিস্তি বাকি আছে?" inputMode="numeric"/>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="চিকিৎসা প্রদাণকারী নাম (Vet Name)?"
                initialValue=""
                name="vet_name"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="চিকিৎসা প্রদাণকারী নাম?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="চিকিৎসা প্রদাণকারী ফোন নাম্বার (Vet Phone)"
                initialValue=""
                name="vet_phone"
                rules={[
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="চিকিৎসা প্রদাণকারী ফোন নাম্বার" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ভ্যাক্সিনেশন প্রদাণকারী নাম (Vaccine provider name)?"
                initialValue=""
                name="vaccine_provider_name"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="ভ্যাক্সিনেশন প্রদাণকারী নাম?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ভ্যাক্সিনেশন প্রদাণকারী ফোন নাম্বার (Vaccine provider Phone)"
                name="vaccine_provider_phone"
                initialValue=""
                rules={[
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="ভ্যাক্সিনেশন প্রদাণকারী ফোন নাম্বার" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="A.I প্রদাণকারী নাম (A.I Proivder Name)?"
                initialValue=""
                name="ai_proivder_name"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="A.I প্রদাণকারী নাম?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="A.I প্রদাণকারী মোবাইল নাম্বার (A.I Proivder Phone)"
                name="ai_provider_phone"
                initialValue=""
                rules={[
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="A.I প্রদাণকারী মোবাইল নাম্বার" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="আপনি ANL এর সাথে কাজ করতে আগ্রহী (Interested)?"
                name="interested_to_work_with_agrigate"
                initialValue=""
              >
                <Select
                  placeholder="Select intereste"
                  onChange={handleInterested}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {interested.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="আপনি কি নিয়মিত মিটিং যোগ দিবেন (Attend regular meetings)?"
                  name="attend_regular_meetings"
                  initialValue=""
                >
                  <Select placeholder="Select attend meeting">
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {interested.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="আপনি কিভাবে মূল্য পরিশোধ করতে ইচ্ছুক (How do you intend to pay)?"
                  name="intend_to_pay"
                  initialValue=""
                >
                  <Select onSelect={handleCredit}>
                    <Option value="Credit">বাকি</Option>
                    <Option value="Cash">নগদ</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {credit.includes("Credit") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="সময়মত আপনার বকেয়া পরিশোধ করবেন? (Pay dues on time)?"
                  name="pay_dues_on_time"
                  initialValue=""
                >
                  <Select>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
