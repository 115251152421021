import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Button, Col, Row, Spin, DatePicker } from "antd";
import { loginAccess } from "../../..";
import axios from "axios";
import authHeader from "../../Authentication/authHeader";
import baseUrl from "../../../config";
import Swal from "sweetalert2";

export default function VaccineDemand() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
            district: data?.data?.district?.district_name,
            upazila: data?.data?.upazila?.upazila_name,
            union: data?.data?.union?.union_name,
            village: data?.data?.village,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
            district: undefined,
            upazila: undefined,
            union: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleSubmit = (data) => {
    setLoading(true);
    fetch(
      "https://script.google.com/macros/s/AKfycbzcgzBPyPJFHducGJPTzFtjycQYoUZDcXllbJPIMDNxr6_3hRsExoq39EQ03XU3iN9OzA/exec",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status.toLowerCase() === "success") {
          Swal.fire({
            title: "Success",
            text: "Vaccine Demand Data Submitted Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.resetFields(resetFields);
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Error",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Vaccine Demand Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="Farmer Phone Number"
              name="phoneNumber"
              onChange={handleFarmerInfo}
              rules={[
                {
                  required: true,
                  message: "Please enter farmer's phone number",
                },
                {
                  pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                  message: "Please enter a valid phone number",
                },
              ]}
            >
              <Input placeholder="Phone Number" type="tel" />
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer Name"
                name="farmer_name"
                rules={[
                  {
                    required: true,
                    message: "Farmer name required",
                  },
                ]}
              >
                <Input placeholder="Farmer Name" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer ID"
                name="farmer_id"
                rules={[
                  {
                    required: true,
                    message: "Farmer ID required",
                  },
                ]}
              >
                <Input placeholder="Farmer ID" type="tel" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                rules={[
                  {
                    required: true,
                    message: "District required",
                  },
                ]}
              >
                <Input placeholder="District" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Upazila"
                name="upazila"
                rules={[
                  {
                    required: true,
                    message: "Upazila required",
                  },
                ]}
              >
                <Input placeholder="Upazila Name" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Union Name"
                name="union"
                rules={[
                  {
                    required: true,
                    message: "Union required",
                  },
                ]}
              >
                <Input placeholder="Union Name" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Village Name"
                name="village"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input placeholder="Village Name" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="কয়টা গরুকে ভ্যাকসিন দিবেন?"
                name="how_many_cows_to_vaccinate"
                rules={[
                  {
                    required: true,
                    message: "Union required",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only english numbers",
                  },
                ]}
              >
                <Input placeholder="কয়টা গরুকে ভ্যাকসিন দিবেন?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Vaccine Name"
                name="vaccine_name"
                rules={[
                  {
                    required: true,
                    message: "Please select vaccination",
                  },
                ]}
              >
                 <Select placeholder="Select vaccination" mode="multiple">
                  <Option value="FMD">ক্ষুরা</Option>
                  <Option value="Anthrax">তড়কা</Option>
                  <Option value="Black Quarter">বাদলা</Option>
                  <Option value="LSD">এলএসডি</Option>
                  <Option value="PPR">পিপিআর</Option>
                  <Option value="HS">গলাফোলা</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* Submit Button */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
