import React, { useContext, useEffect, useState } from "react";
import "../Form.css";
import { loginAccess } from "../../..";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Space,
  Upload,
  Spin,
  Select,
} from "antd";
import Address from "../../CommonProperty/Address";
import {
  EnvironmentOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import awsS3 from "aws-sdk/clients/s3";
import axios from "axios";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";
import Swal from "sweetalert2";

export default function TradersForm() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [signboardImage, setSignboardImage] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [chequeImage, setChequeImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sourcingProductList, setSourcingProductList] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/trader/sourcingProductList`, {
        headers: authHeader(),
      })
      .then((data) => setSourcingProductList(data?.data));
  }, []);

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          form.setFieldsValue({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleSubmit = (data) => {
    data.signboard_image = signboardImage;
    data.profile_image = profileImage;
    data.cheque_image = chequeImage;
    setLoading(true);
    axios
      .post(
        `${baseUrl}/trader/traderRegistration/`,
        {
          data: data,
        },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Trader Registration Success",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.resetFields(resetFields);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error_message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Traders Registration Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Trader name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Trader Name"
                name="trader_name"
                rules={[
                  { required: true, message: "Please enter trader name" },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Trader Name" />
              </Form.Item>
            </Col>
            {/* trader phone */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Trader's Phone Number"
                name="trader_phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter trader's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="Trader's Phone Number" type="tel" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Trader NID*/}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="National ID"
                name="nationalID"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+$/, // Regular expression to match English characters and spaces
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input placeholder="National ID" inputMode="numeric" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Trader Image"
                name="profile_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Please upload trader image",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setProfileImage)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload profile image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Signboard Image"
                name="signboard_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) =>
                    handleImageUpload(info, setSignboardImage)
                  }
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload signboard image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Address />

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* GPS */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="latitude"
                label="Latitude"
                rules={[
                  {
                    required: true,
                    message: "Latitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Latitude" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="longitude"
                label="Longitude"
                rules={[
                  {
                    required: true,
                    message: "Longitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Longitude" />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button
                type="primary"
                icon={<EnvironmentOutlined />}
                onClick={handleGetCurrentLocation}
              >
                Get Current Location
              </Button>
            </Form.Item>
          </Col>
          <Form.List name="products">
            {(productFields, { add, remove }) => (
              <>
                {productFields.map((productField, productIndex) => (
                  <Space
                    key={productIndex}
                    style={{
                      marginBottom: 20,
                      border: "dashed 1px gray",
                      padding: "12px",
                      borderRadius: "5px",
                    }}
                  >
                    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                      <Col xs={24} sm={12} md={8} lg={8}>
                        <Form.Item
                          {...productField}
                          label="Product Name"
                          name={[productField.name, "sourcing_product_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing product name",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select product"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {sourcingProductList?.map((product) => (
                              <Option value={product.sourcing_product_id}>
                                {product.product_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={8}>
                        <Form.Item
                          {...productField}
                          label="Product Quantity (TON)"
                          name={[productField.name, "product_quantity"]}
                          initialValue={0}
                          rules={[
                            {
                              pattern: /^[0-9]+(\.[0-9]+)?$/,
                              message: "Only English numbers",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Product Quantity (TON)"
                            inputMode="numeric"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={8}>
                        <Form.Item
                          {...productField}
                          label="Product Price (Per KG)"
                          name={[productField.name, "product_price"]}
                          initialValue={0}
                          rules={[
                            {
                              pattern: /^[0-9]+(\.[0-9]+)?$/,
                              message: "Only English numbers",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Product Price (Per KG)"
                            inputMode="numeric"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item>
                          <Button
                            onClick={() => {
                              remove(productField.name);
                            }}
                            style={{
                              backgroundColor: "#c21807",
                              color: "#ffffff",
                            }}
                          >
                            <strong>Remove</strong>
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))}

                <Col xs={24} sm={12} md={8} lg={11}>
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={() => add()}
                      block
                      size="small"
                      icon={<PlusOutlined />}
                      style={{ color: "#000000" }}
                    >
                      Available Product
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Cheque Image"
                name="cheque_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Cheque image required",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) => handleImageUpload(info, setChequeImage)}
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload cheque image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            {/* Remarks */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Remarks"
                name="remarks"
                initialValue=""
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Remarks" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
