import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Button, Col, Row, Spin, Space } from "antd";
import { loginAccess } from "../../..";
import axios from "axios";
import authHeader from "../../Authentication/authHeader";
import baseUrl from "../../../config";
import Swal from "sweetalert2";
import { PlusOutlined } from "@ant-design/icons";

export default function ProductDemand() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [productPriceList, setProductPriceList] = useState([]);
  const [filteredProductsList, setFilteredProductsList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/contractFarming/projects/`, {
        headers: authHeader(),
      })
      .then((response) => setProjects(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/retailer/productPriceList/`, {
        headers: authHeader(),
      })
      .then((response) => setProductPriceList(response?.data));
  }, []);

  const handleProjectSelect = (value) => {
    setSelectedProject(value);
    form.setFieldsValue({
      phoneNumber: null,
      farmer_name: null,
      farmer_id: null,
      district: null,
      upazila: null,
      union: null,
      village: null,
      nid: null,
      group_name: null,
      bp_name: null,
      project_name: null,
      project_year: null,
      project_type: null,
      products: null,
      crop_or_livestock_name: null,
    });
  };

  const filterProductPriceList = (value, index) => {
    const filteredProducts = productPriceList.filter(
      (product) => product.category.toLowerCase() === value.toLowerCase()
    );
    setFilteredProductsList(filteredProducts);
    const newValues = form.getFieldsValue();
    newValues.products[index] = {
      ...newValues.products[index],
      product_name: undefined,
      quantity: undefined,
      product_total_price: undefined,
    };

    form.setFieldsValue({ products: [...newValues.products] });
  };
  const handleProductSelect = (value) => {
    const selectedProduct = filteredProductsList.find(
      (product) => product.product_id === value
    );
    setSelectedProduct(selectedProduct);
  };

  const calculateTotalPrice = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalPriceSum = products.reduce((sum, product) => {
      return sum + (product.product_total_price || 0); // Handle missing product_total_price
    }, 0);

    form.setFieldValue("total_price", newTotalPriceSum);
  };

  const handleQuantityChange = (value, index) => {
    const calculatedTotalPrice = selectedProduct.selling_price_farmer * value;
    const newValues = form.getFieldsValue();
    newValues.products[index].product_total_price = calculatedTotalPrice;

    form.setFieldsValue(newValues);
    calculateTotalPrice();
  };

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/contract_farmer_info/`, {
          headers: authHeader(),
          params: {
            project_id: selectedProject,
            phone: phone,
          },
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.farmer?.name,
            farmer_id: data?.data?.farmer?.farmer_id,
            district: data?.data?.farmer?.district?.district_name,
            upazila: data?.data?.farmer?.upazila?.upazila_name,
            union: data?.data?.farmer?.union?.union_name,
            village: data?.data?.farmer?.village,
            nid: data?.data?.farmer?.nid,
            group_name: data?.data?.group_name,
            bp_name: data?.data?.bp_name,
            project_name: data?.data?.project?.project_name,
            project_year: data?.data?.project?.year,
            project_type: data?.data?.project?.project_type,
            crop_or_livestock_name: data?.data?.crop_or_livestock_name,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
            district: undefined,
            upazila: undefined,
            union: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleSubmit = (data) => {
    setLoading(true);
    // fetch(
    //   "https://script.google.com/macros/s/AKfycbyZsTnp4XQh72sCGUYDU2Dr6_hrbTLKtI7mL3O4g3ypadHp6F4YWesd8NZ11p6ZY9ut/exec",
    //   {
    //     method: "POST",
    //     body: JSON.stringify(data),
    //   }
    // )
    //   .then((res) => res.json())
    axios
      .post(
        `${baseUrl}/contractFarming/createCFDemand/`,
        {
          data: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data?.status === 201) {
          Swal.fire({
            title: "Success",
            text: data?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"];
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.resetFields(resetFields);
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Error",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "error",
          text: error?.response?.data?.error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Product Demand Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Projects"
                name="project_id"
                rules={[
                  {
                    required: true,
                    message: "Please select project name",
                  },
                ]}
              >
                <Select
                  placeholder="Select project name"
                  onChange={handleProjectSelect}
                >
                  {projects?.map((project) => (
                    <Option
                      value={project?.project_id}
                      key={project?.project_id}
                    >
                      {project?.project_name +
                        " - " +
                        project?.project_type +
                        " - " +
                        project?.year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {selectedProject ? (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Farmer Phone Number"
                  name="phoneNumber"
                  onChange={handleFarmerInfo}
                  rules={[
                    {
                      required: true,
                      message: "Please enter farmer's phone number",
                    },
                    {
                      pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                      message: "Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input placeholder="Phone Number" type="tel" />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer ID"
                name="farmer_id"
                rules={[
                  {
                    required: true,
                    message: "Farmer ID required",
                  },
                ]}
              >
                <Input placeholder="Farmer ID" type="tel" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer Name"
                name="farmer_name"
                rules={[
                  {
                    required: true,
                    message: "Farmer name required",
                  },
                ]}
              >
                <Input placeholder="Farmer Name" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Nid"
                name="nid"
                rules={[
                  {
                    required: true,
                    message: "Nid required",
                  },
                ]}
              >
                <Input placeholder="Nid" readOnly />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                rules={[
                  {
                    required: true,
                    message: "District required",
                  },
                ]}
              >
                <Input placeholder="District" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Upazila"
                name="upazila"
                rules={[
                  {
                    required: true,
                    message: "Upazila required",
                  },
                ]}
              >
                <Input placeholder="Upazila Name" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Union Name"
                name="union"
                rules={[
                  {
                    required: true,
                    message: "Union required",
                  },
                ]}
              >
                <Input placeholder="Union Name" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Village Name"
                name="village"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input placeholder="Village Name" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Project Name"
                name="project_name"
                rules={[
                  {
                    required: true,
                    message: "Project name required",
                  },
                ]}
              >
                <Input placeholder="Project Name" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Project Type"
                name="project_type"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Project type required",
                  },
                ]}
              >
                <Input placeholder="Project Type" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Project Year"
                name="project_year"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Project year required",
                  },
                ]}
              >
                <Input placeholder="Project Year" readOnly />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Group Name"
                name="group_name"
                rules={[
                  {
                    required: true,
                    message: "Group required",
                  },
                ]}
              >
                <Input placeholder="Group Name" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="BP Name"
                name="bp_name"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "BP name required",
                  },
                ]}
              >
                <Input placeholder="BP Name" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Crop/Livestock Name"
                name="crop_or_livestock_name"
                rules={[
                  {
                    required: true,
                    message: "Crop/Livestock required",
                  },
                ]}
              >
                <Input placeholder="Crop/Livestock Name" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="products">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{
                      marginBottom: 20,
                      border: "dashed 1px gray",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Row gutter={[8, 16, 24, 32]}>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...restField}
                          label="Product Category"
                          name={[name, `product_category`]}
                          rules={[
                            {
                              required: true,
                              message: "Product Category is required",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Product Category"
                            onChange={(value) =>
                              filterProductPriceList(value, index)
                            }
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="Livestock">Livestock</Option>
                            <Option value="Medicine">Medicine</Option>
                            <Option value="Seed">Seed</Option>
                            <Option value="PGR">PGR</Option>
                            <Option value="Pesticides">Pesticides</Option>
                            <Option value="Micro-Nutrient">
                              Micro-Nutrient
                            </Option>
                            <Option value="Herbicides">Herbicides</Option>
                            <Option value="Fungicides">Fungicides</Option>
                            <Option value="Feed">Feed</Option>
                            <Option value="Bio Fertilizer">
                              Bio Fertilizer
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={18} sm={8} md={8} lg={6}>
                        <Form.Item
                          {...restField}
                          label="Product Name"
                          name={[name, `product_id`]}
                          rules={[
                            {
                              required: true,
                              message: "Select product name",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select product name"
                            showSearch
                            onChange={(value) =>
                              handleProductSelect(value, index)
                            }
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {filteredProductsList?.map((item) => (
                              <Option
                                key={item.product_id}
                                value={item.product_id}
                              >
                                {item.product_name_english}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={18} sm={8} md={8} lg={6}>
                        <Form.Item
                          {...restField}
                          label="Quantity"
                          name={[name, `quantity`]}
                          rules={[
                            {
                              required: true,
                              message: "Purchase quantity required",
                            },
                            {
                              pattern: /^[0-9]+$/,
                              message: "Only english number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Quantity"
                            onChange={(e) =>
                              handleQuantityChange(e.target.value, index)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={18} sm={8} md={8} lg={6}>
                        <Form.Item
                          {...restField}
                          label="Product Total Price"
                          name={[name, `product_total_price`]}
                          rules={[
                            {
                              required: true,
                              message: "Product Total Price required",
                            },
                            {
                              pattern: /^[0-9]+(\.[0-9]+)?$/,
                              message: "Only english number",
                            },
                          ]}
                        >
                          <Input placeholder="Product Total Price" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item>
                          <Button
                            onClick={() => {
                              form.setFieldsValue({
                                total_price: undefined,
                                due: undefined,
                                total_paid: undefined,
                              });
                              remove(name);
                              calculateTotalPrice();
                            }}
                            style={{
                              backgroundColor: "#c21807",
                              color: "#ffffff",
                            }}
                          >
                            <strong>Remove</strong>
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))}
                <Col xs={24} sm={12} md={8} lg={11}>
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={() => add()}
                      block
                      size="small"
                      icon={<PlusOutlined />}
                      style={{ color: "#000000" }}
                    >
                      Add product
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="Total Price"
              name="total_price"
              rules={[
                {
                  required: true, // Regular expression to match English characters and spaces
                  message: "Total price missing",
                },
              ]}
            >
              <Input placeholder="Total Price" disabled />
            </Form.Item>
          </Col>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          {/* Submit Button */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
