import React from "react";
import PersistentDrawerLeft from "./sidebar";
export default function SidebarLayout({ children }) {
    return (
        <div>
          <PersistentDrawerLeft />
          {children}
        </div>
      );
}

