import React, { useContext, useEffect, useState } from "react";
import "./Consultation.css";
import { Row, Col, Table, Input, Button, Tabs } from "antd";
import axios from "axios";
import baseUrl from "../../../config";
import { loginAccess } from "../../..";
import authHeader from "../../Authentication/authHeader";
import CropFollowupModal from "./ConsultationModal/CropFollowupModal";
import LivestockFollowupModal from "./ConsultationModal/LivestockFollowupModal";

export default function History() {
  const { Search } = Input;
  const [tabValue, setTabValue] = useState();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);

  const [cropFollowupCurrentPage, setCropFollowupCurrentPage] = useState(1);
  const [livestockFollowupCurrentPage, setLivestockFollowupCurrentPage] =
    useState(1);

  const [cropFollowupTotalItems, setCropFollowupTotalItems] = useState(0);
  const [livestockFollowupTotalItems, setLivestockFollowupTotalItems] =
    useState(0);

  const [cropFollowups, setCropFollowups] = useState([]);
  const [livestockFollowups, setLivestockFollowups] = useState([]);

  const [cropFollowupFilter, setCropFollowupFilter] = useState("");
  const [livestockFollowupFilter, setLivestockFollowupFilter] = useState("");

  const [cropModalVisible, setCropModalVisible] = useState(false);
  const [livestockModalVisible, setLivestockModalVisible] = useState(false);

  const [selectedData, setSelectedData] = useState("");

  const today = new Date();
  const todaysDate = new Date(today);
  todaysDate.setDate(today.getDate());
  const checkingDate = todaysDate.toISOString().slice(0, 10);

  // <== followup consultation lists fetch useEffect start==>
  const fetchFoCropFollowup = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/consultation/foCropCompletedConsultation/${isLoggedIn?.loggedInUser?.employee_id}`,
        {
          headers: authHeader(),
          params: {
            page: cropFollowupCurrentPage,
            filter: cropFollowupFilter,
          },
        }
      );
      setCropFollowups(response?.data?.results);
      setCropFollowupTotalItems(response?.data?.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFoLivestockFollowup = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/consultation/foLivestockCompletedConsultation/${isLoggedIn?.loggedInUser?.employee_id}`,
        {
          headers: authHeader(),
          params: {
            page: livestockFollowupCurrentPage,
            filter: livestockFollowupFilter,
          },
        }
      );
      setLivestockFollowups(response?.data?.results);
      setLivestockFollowupTotalItems(response?.data?.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchFoCropFollowup();
  }, [isLoggedIn?.loggedInUser?.employee_id, cropFollowupFilter]);

  useEffect(() => {
    fetchFoLivestockFollowup();
  }, [isLoggedIn?.loggedInUser?.employee_id, livestockFollowupFilter]);

  // <== followup consultation lists fetch useEffect end==>

  const cropFollowupColumns = [
    {
      title: "Crop Consultation",
      width: "65%",
      render: (record) => {
        const date = record?.followup_date;
        const ticket = record?.ticket?.ticket_id;
        const status = record?.followup_status;
        return (
          <>
            <p>
              <strong>Date: {date}</strong>
              <br />
              {ticket}
              <br />
              Status: {status}
            </p>
          </>
        );
      },
    },
    
  ];
  const livestockFollowupColumns = [
    {
      title: "Livestock Consultation",
      width: "65%",
      render: (record) => {
        const date = record?.followup_date;
        const ticket = record?.ticket?.ticket_id;
        const status = record?.followup_status;
        return (
          <>
            <p>
              <strong>Date: {date}</strong>
              <br />
              {ticket}
              <br />
              Status: {status}
            </p>
          </>
        );
      },
    },
  ];

  const onChange = (key) => {
    setTabValue(key);
  };
  const handleCropFollowupPageChange = (value) => {
    setCropFollowupCurrentPage(value);
  };
  const handleLivestockFollowupPageChange = (value) => {
    setLivestockFollowupCurrentPage(value);
  };
  const handleCropFollowupSearch = (value) => {
    setCropFollowupFilter(value);
  };
  const handleLivestockFollowupSearch = (value) => {
    setLivestockFollowupFilter(value);
  };
  const handleCropModal = (record) => {
    setCropModalVisible(true);
    setSelectedData(record);
  };
  const handleCropFollowupModalCancel = () => {
    setCropModalVisible(false);
    setSelectedData(null);
  };
  const handleLivestockModal = (record) => {
    setLivestockModalVisible(true);
    setSelectedData(record);
  };
  const handleLivestockFollowupModalCancel = () => {
    setLivestockModalVisible(false);
    setSelectedData(null);
  };
  const items = [
    {
      key: "Crop",
      label: "Crop",
      children: (
        <div className="">
          <div className="d-flex justify-content-end me-2">
            <Row>
              <Col>
                <Search
                  placeholder="search here..."
                  allowClear
                  onSearch={handleCropFollowupSearch}
                  enterButton={
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#38512e",
                        borderColor: "#38512e",
                      }}
                    >
                      Search
                    </Button>
                  }
                />
              </Col>
            </Row>
          </div>
          <Table
            className="border mt-2"
            columns={cropFollowupColumns}
            dataSource={cropFollowups}
            rowKey={(record) => record.crop_consultation_id}
            pagination={{
              current: cropFollowupCurrentPage,
              total: cropFollowupTotalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handleCropFollowupPageChange,
            }}
          />
        </div>
      ),
    },
    {
      key: "Livestock",
      label: "Livestock",
      children: (
        <div className="">
          <div className="d-flex justify-content-end me-2">
            <Row>
              <Col>
                <Search
                  placeholder="search here..."
                  allowClear
                  onSearch={handleLivestockFollowupSearch}
                  enterButton={
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#38512e",
                        borderColor: "#38512e",
                      }}
                    >
                      Search
                    </Button>
                  }
                />
              </Col>
            </Row>
          </div>
          <Table
            className="border mt-2"
            columns={livestockFollowupColumns}
            dataSource={livestockFollowups}
            rowKey={(record) => record.livestock_consultation_id}
            pagination={{
              current: livestockFollowupCurrentPage,
              total: livestockFollowupTotalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handleLivestockFollowupPageChange,
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CropFollowupModal
        visible={cropModalVisible}
        onCancel={handleCropFollowupModalCancel}
        data={selectedData}
        fetchFoCropFollowup={fetchFoCropFollowup}
      />
      <LivestockFollowupModal
        visible={livestockModalVisible}
        onCancel={handleLivestockFollowupModalCancel}
        data={selectedData}
        fetchFoLivestockFollowup={fetchFoLivestockFollowup}
      />
      <div className="consultationContainer">
        <header
          className="mb-5 mt-4"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          History
        </header>
        <div className="m-2">
          <Tabs defaultActiveKey="Crop" items={items} onChange={onChange} />
        </div>
      </div>
    </>
  );
}
