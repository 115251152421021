import React from "react";
import { Form, Input, Button, Row, Col, Space, Upload } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
export default function ByProductAdd({ parentName, removeProduct }) {
  const [form] = Form.useForm();
  return (
    <Form.List name={[parentName, "by_products"]}>
      {(byProductFields, { add: addByProduct, remove: removeByProduct }) => (
        <>
          {byProductFields.map((byProductField) => (
            <Space
              key={byProductField.key}
              style={{
                marginBottom: 20,
                border: "solid 1px gray",
                padding: "12px",
                borderRadius: "5px",
                marginLeft: 10,
              }}
            >
              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <Form.Item
                    {...byProductField}
                    label="Byproduct Name"
                    name={[byProductField.name, "byproduct_name"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing byproduct name",
                      },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: "Only English numbers",
                      },
                    ]}
                  >
                    <Input placeholder="Byproduct Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <Form.Item
                    {...byProductField}
                    label="Byproduct Quantity (TON)"
                    name={[byProductField.name, "byproduct_quantity"]}
                    initialValue={0}
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only English numbers",
                      },
                    ]}
                  >
                    <Input placeholder="Byproduct Quantity (TON)" inputMode="numeric" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <Form.Item
                    {...byProductField}
                    label="Byproduct Price (Per KG)"
                    name={[byProductField.name, "byproduct_price"]}
                    initialValue={0}
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only English numbers",
                      },
                    ]}
                  >
                    <Input placeholder="Byproduct Price (Per KG)" inputMode="numeric" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item>
                    <Button
                      onClick={() => {
                        removeByProduct(byProductField.name);
                      }}
                      style={{
                        backgroundColor: "#c21807",
                        color: "#ffffff",
                      }}
                      icon={<MinusOutlined />}
                    >
                      Byproduct
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          ))}

          <Col xs={24} sm={12} md={8} lg={11}>
            <Form.Item>
              <Button
                type="default"
                onClick={() => addByProduct()}
                block
                size="small"
                icon={<PlusOutlined />}
                style={{ color: "#000000" }}
              >
                Add Byproduct
              </Button>
            </Form.Item>
          </Col>
        </>
      )}
    </Form.List>
  );
}
