import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import FarmerKYCForm from "./KYC/FarmerKYCForm";
import RetailerKYCForm from "./KYC/RetailerKYCForm";
import LSPKYCForm from "./KYC/LSPKYCForm";
import { loginAccess } from "../..";
import useMediaQuery from '@mui/material/useMediaQuery';

  const MainKYCForm = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
      useContext(loginAccess);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
      <div className="page-container">
        <div className="content-container">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant={isMobile ? "scrollable" : "standard"} // Use scrollable on mobile
                scrollButtons={isMobile ? "auto" : false} // Show scroll buttons on mobile
              >
                {userPermissions?.includes("view_farmer_kyc_tab") && (
                  <Tab value={0} label="Farmer" {...a11yProps(0)} />
                )}
                {userPermissions?.includes("view_retailer_kyc_tab") && (
                  <Tab value={1} label="Retailer" {...a11yProps(1)} />
                )}
                {userPermissions?.includes("view_lsp_kyc_tab") && (<Tab value={2} label="LSP" {...a11yProps(2)} />)}
                {/* {userPermissions?.includes("view_asp_kyc_tab") && (<Tab value={3} label="ASP" {...a11yProps(3)} />)} */}
              </Tabs>
            </Box>
            {userPermissions?.includes("view_farmer_kyc_tab") && ( 
            <TabPanel value={value} index={0}>
              <FarmerKYCForm />
            </TabPanel>
            )}
            {userPermissions?.includes("view_retailer_kyc_tab") && (
            <TabPanel value={value} index={1}>
              <RetailerKYCForm />
            </TabPanel>
            )}
            {userPermissions?.includes("view_lsp_kyc_tab") && (
            <TabPanel value={value} index={2}>
              <LSPKYCForm />
            </TabPanel>
            )}
          </Box>
        </div>
      </div>
    );
  };

  function TabPanel(props) {
    let { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  MainKYCForm.propTypes = {
    children: PropTypes.node,
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
  };

  export default MainKYCForm;
