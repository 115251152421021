import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Upload, Button, Row, Col, Spin } from "antd";
import { loginAccess } from "../../..";
import awsS3 from "aws-sdk/clients/s3";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import baseUrl from "../../../config";
import Swal from "sweetalert2";
import authHeader from "../../Authentication/authHeader";

export default function PoultrySurvey() {
  const [uploading, setUploading] = useState(false);
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [involvement, setInvolvement] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
            district: data?.data?.district?.district_name,
            upazila: data?.data?.upazila?.upazila_name,
            union: data?.data?.union?.union_name,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
            district: undefined,
            upazila: undefined,
            union: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleInvolveWithCompany = (value) => {
    setInvolvement(value);
  };

  const handleSubmit = (data) => {
    setLoading(true);
    fetch(
      "https://script.google.com/macros/s/AKfycbzOVqLm1_rggnBPqz0AL7xCBy8mzxT48gVxFW2dLPEyXQn40SApcVxZepPOTsQECZtpqw/exec",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status.toLowerCase() === "success") {
          Swal.fire({
            title: "Success",
            text: "Poultry Survey Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            setInvolvement([]);
            form.resetFields(resetFields);
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Error",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Poultry Survey Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারির ফোন নম্বর (Phone Number)"
                name="phoneNumber"
                onChange={handleFarmerInfo}
                rules={[
                  {
                    required: true,
                    message: "Please enter farmer's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input
                  placeholder="খামারির ফোন নম্বর (Phone Number)"
                  type="tel"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer ID"
                name="farmer_id"
                rules={[
                  {
                    required: true,
                    message: "Farmer ID required",
                  },
                ]}
              >
                <Input placeholder="Farmer ID" type="tel" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Farmer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারির নাম (Farmer Name)"
                name="farmer_name"
                rules={[
                  { required: true, message: "Please enter farmer name" },
                ]}
              >
                <Input placeholder="খামারির নাম" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                rules={[{ required: true, message: "District required" }]}
              >
                <Input placeholder="District" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Upazila"
                name="upazila"
                rules={[{ required: true, message: "Upazila required" }]}
              >
                <Input placeholder="Upazila" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Union"
                name="union"
                rules={[{ required: true, message: "Union required" }]}
              >
                <Input placeholder="Union" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="কতদিন ধরে পোল্ট্রি ফার্ম চালাচ্ছেন? (How long have you run your poultry farm?)"
                name="farm_running_time"
                initialValue=""
              >
                <Input placeholder="কতদিন ধরে পোল্ট্রি ফার্ম চালাচ্ছেন?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="ফার্মে কতটি মুরগি আছে? (How many chickens are on your farm?)"
                name="no_of_chickens_in_farm"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input
                  placeholder="ফার্মে কতটি মুরগি আছে?"
                  inputMode="numeric"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="কোন ধরনের মুরগি পালন করেন? (যেমন: ব্রয়লার, লেয়ার) (What type of chickens do you raise? (e.g., broiler, layer))"
                name="type_of_chicken"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Select placeholder="Select type" mode="multiple">
                  <Option value="Broilers">Broilers</Option>
                  <Option value="Layers">Layers</Option>
                  <Option value="Sonali">Sonali</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="খামারের আয়তন (Farm Size) sqkm"
                name="farm_size"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only English numbers",
                  },
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Input placeholder="খামারের আয়তন (sqkm)" inputMode="numeric" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="মাসে কতটি ডিম বা কত কেজি মুরগির মাংস উৎপাদন করেন? (How many eggs or how much chicken meat do you produce monthly?)"
                name="number_of_eggs_or_meat_kg"
                initialValue=""
              >
                <Input placeholder="মাসে কতটি ডিম বা কত কেজি মুরগির মাংস উৎপাদন করেন?" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="আপনি কোথায় এবং কীভাবে আপনার পণ্য বিক্রি করেন? (Where and how do you sell your products?)"
                name="sell_produce"
                initialValue=""
              >
                <Input placeholder="আপনি কোথায় এবং কীভাবে আপনার পণ্য বিক্রি করেন?" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="কোন কোম্পানির সাথে কন্ট্রাক্ট ফার্মিং করেন? (Involve with any company?)"
                name="involve_with_any_company"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Select
                  placeholder="Select"
                  onChange={handleInvolveWithCompany}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {involvement.includes("Yes") && (
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="যদি হ্যা হয় তবে কোন কোম্পানি? (If Yes, Company name?)"
                name="company_name"
              >
                <Input placeholder="যদি হ্যা হয় তবে কোন কোম্পানি?" />
              </Form.Item>
            </Col>
          )}
          {involvement.includes("No") && (
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="আপনি কন্ট্রাক্ট ফার্মিং করতে আগ্রহী? (Are you interested in working with us if no?)"
                name="interested_in_work_with_agrigate"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Select placeholder="Select">
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
          )}

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
