import React, { useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { loginAccess } from '../..';

const PrivateRoute = ({ children, ...rest }) => {
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions }= useContext(loginAccess);
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location.pathname === "/login" ? "/" : location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;