import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Button, Col, Row, Spin } from "antd";
import { loginAccess } from "../../..";
import axios from "axios";
import authHeader from "../../Authentication/authHeader";
import baseUrl from "../../../config";
import Swal from "sweetalert2";

const FarmerKYCForm = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const predefinedKharif1Options = [
    "Rice(Aus)",
    "Sesame",
    "Summer vegetables",
    "Fallow land",
  ];
  const predefinedKharif2Options = [
    "Rice(Amon)",
    "Summer vegetables",
    "Fallow land",
  ];
  const predefinedRobiOptions = [
    "Maize",
    "Soybean",
    "Rice(Boro)",
    "Mustard",
    "Potato",
    "Winter vegetables",
    "Fallow land",
  ];
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [hasLand, setHasLand] = useState([]);
  const [hasFarm, setHasFarm] = useState([]);
  const [customKharif1Options, setCustomKharif1Options] = useState([]);
  const [customKharif2Options, setCustomKharif2Options] = useState([]);
  const [customRobiOptions, setCustomRobiOptions] = useState([]);
  const [inputKharif1Value, setInputKharif1Value] = useState("");
  const [inputKharif2Value, setInputKharif2Value] = useState("");
  const [inputRobiValue, setInputRobiValue] = useState("");
  const [selectedKharif1Options, setSelectedKharif1Options] = useState([]);
  const [selectedKharif2Options, setSelectedKharif2Options] = useState([]);
  const [selectedRobiOptions, setSelectedRobiOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
            district: data?.data?.district?.district_name
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };
  const handleHasLand = (value) => {
    setHasLand(value);
  };

  const handleHasFarm = (value) => {
    setHasFarm(value);
  };

  const handleAddOption = () => {
    if (
      inputKharif1Value.trim() !== "" &&
      !customKharif1Options.includes(inputKharif1Value)
    ) {
      setCustomKharif1Options([...customKharif1Options, inputKharif1Value]);
      setInputKharif1Value("");
    }
    if (
      inputKharif2Value.trim() !== "" &&
      !customKharif2Options.includes(inputKharif2Value)
    ) {
      setCustomKharif2Options([...customKharif2Options, inputKharif2Value]);
      setInputKharif2Value("");
    }
    if (
      inputRobiValue.trim() !== "" &&
      !customRobiOptions.includes(inputRobiValue)
    ) {
      setCustomRobiOptions([...customRobiOptions, inputRobiValue]);
      setInputRobiValue("");
    }
  };
  const handleSelectedKharif1 = (selectedOptions) => {
    setSelectedKharif1Options(selectedOptions);
  };
  const handleSelectedKharif2 = (selectedOptions) => {
    setSelectedKharif2Options(selectedOptions);
  };

  const handleSelectedRobi = (selectedOptions) => {
    setSelectedRobiOptions(selectedOptions);
  };

  const handleSubmit = (data) => {
    data.kharif1 = selectedKharif1Options;
    data.kharif2 = selectedKharif2Options;
    data.robi = selectedRobiOptions;
    setLoading(true);
    fetch(
      "https://script.google.com/macros/s/AKfycbwrkh3sj3VQlZlp4sLvEBp1SklTnQqVQYSZ0dFcJWaqiOkG4qo-m4rWOUCBnu4FWnf9VQ/exec",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status.toLowerCase() === "success") {
          Swal.fire({
            title: "Success",
            text: "Farmer Kyc Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );

            form.resetFields(resetFields);
            setHasLand([]);
            setHasFarm([]);
            setSelectedKharif1Options([]);
            setSelectedKharif2Options([]);
            setSelectedRobiOptions([]);

          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "error",
            text: "Error",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Farmer KYC Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="Farmer Phone Number"
              name="phoneNumber"
              onChange={handleFarmerInfo}
              rules={[
                {
                  required: true,
                  message: "Please enter farmer's phone number",
                },
                {
                  pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                  message: "Please enter a valid phone number",
                },
              ]}
            >
              <Input placeholder="Phone Number" type="tel" />
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer Name"
                name="farmer_name"
                rules={[
                  {
                    required: true,
                    message: "Farmer name required",
                  },
                ]}
              >
                <Input placeholder="Farmer Name" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Farmer ID"
                name="farmer_id"
                rules={[
                  {
                    required: true,
                    message: "Farmer ID required",
                  },
                ]}
              >
                <Input placeholder="Farmer ID" type="tel" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                rules={[
                  {
                    required: true,
                    message: "Farmer District is required",
                  },
                ]}
              >
                <Input placeholder="District" type="tel" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Bank Name"
                name="bank_name"
                initialValue=""
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only english characters",
                  },
                ]}
              >
                <Input placeholder="Bank Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Branch name"
                name="branch_name"
                initialValue=""
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only english characters",
                  },
                ]}
              >
                <Input placeholder="Branch name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Bank Account Name"
                name="bank_account_name"
                initialValue=""
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?|[a-zA-Z]+$/,
                    message: "Only english characters",
                  },
                ]}
              >
                <Input placeholder="Bank Account Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Bank Account No."
                name="account_no"
                initialValue={0}
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only english numbers",
                  },
                ]}
              >
                <Input placeholder="Bank Account No." inputMode="numeric"/>
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="Routing No."
              name="routing_no"
              initialValue={0}
              rules={[
                {
                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Only english numbers",
                },
              ]}
            >
              <Input placeholder="Routing No."  inputMode="numeric"/>
            </Form.Item>
          </Col>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Has Land"
                name="has_land"
                rules={[
                  {
                    required: true,
                    message: "Select has land?",
                  },
                ]}
              >
                <Select placeholder="Select has land" onChange={handleHasLand}>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {hasLand.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Land Type"
                  name="land_type"
                  rules={[
                    {
                      required: true,
                      message: "Select land type",
                    },
                  ]}
                >
                  <Select placeholder="Select land type">
                    <Option value="High Land">High Land</Option>
                    <Option value="Medium High Land">Medium High Land</Option>
                    <Option value="Low Land">Low Land</Option>
                    <Option value="None">None</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          {hasLand.includes("Yes") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Own Land Size"
                  name="own_land_size"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only english characters",
                    },
                  ]}
                >
                  <Input placeholder="Own Land Size" inputMode="numeric" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Lease Land Size"
                  name="lease_land_size"
                  initialValue={0}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only english numbers",
                    },
                  ]}
                >
                  <Input placeholder="Lease Land Size"  inputMode="numeric"/>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Has Farm"
                name="has_farm"
                rules={[
                  {
                    required: true,
                    message: "Select has farm?",
                  },
                ]}
              >
                <Select placeholder="Select has farm" onChange={handleHasFarm}>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {hasFarm.includes("Yes") && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Farm Type"
                  name="farm_type"
                  rules={[
                    {
                      required: true,
                      message: "Select farm type",
                    },
                  ]}
                >
                  <Select placeholder="Select farm type" mode="multiple">
                    <Option value="Dairy Farm">Dairy Farm</Option>
                    <Option value="Beef Farm">Beef Farm</Option>
                    <Option value="Poultry Farm">Poultry Farm</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Kharif1 Season Products"
                name="kharif1"
                
              >
                <Select
                  placeholder="Select kharif1 products"
                  mode="multiple"
                  value={selectedKharif1Options}
                  onChange={handleSelectedKharif1}
                  initialValue=""
                >
                  {predefinedKharif1Options.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                  {customKharif1Options.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
                <Input
                  value={inputKharif1Value}
                  onChange={(e) => setInputKharif1Value(e.target.value)}
                  initialValue=""
                  addonAfter={
                    <Button onClick={handleAddOption} type="link">
                      Add
                    </Button>
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Kharif2 Season Products"
                name="kharif2"
                initialValue=""
              >
                <Select
                  placeholder="Select kharif2 products"
                  mode="multiple"
                  value={selectedKharif2Options}
                  onChange={handleSelectedKharif2}
                >
                  {predefinedKharif2Options.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                  {customKharif2Options.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
                <Input
                  value={inputKharif2Value}
                  onChange={(e) => setInputKharif2Value(e.target.value)}
                  onPressEnter={handleAddOption}
                  addonAfter={
                    <Button onClick={handleAddOption} type="link">
                      Add
                    </Button>
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Robi Season Products"
                name="robi"
                initialValue=""
              >
                <Select
                  placeholder="Select robi products"
                  mode="multiple"
                  value={selectedRobiOptions}
                  onChange={handleSelectedRobi}
                >
                  {predefinedRobiOptions.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                  {customRobiOptions.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
                <Input
                  value={inputRobiValue}
                  onChange={(e) => setInputRobiValue(e.target.value)}
                  onPressEnter={handleAddOption}
                  addonAfter={
                    <Button onClick={handleAddOption} type="link">
                      Add
                    </Button>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* Submit Button */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default FarmerKYCForm;
