import React, { useContext } from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useLocation, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppReistrationIcon from "@mui/icons-material/AppRegistration";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PollIcon from "@mui/icons-material/Poll";
import MedicationIcon from "@mui/icons-material/Medication";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import LogoutIcon from "@mui/icons-material/Logout";
import AgrigateLogo from "../Assets/logo.png";
import { loginAccess } from "../..";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ReceiptIcon from '@mui/icons-material/Receipt';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (menu) => {
    if (menu.toLowerCase() === "login") {
    } else {
      setOpen(false);
      history.push(`/${menu.toLowerCase()}`);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(null);
  };

  // Get the name of the current route based on the location
  const getCurrentRouteName = () => {
    const path = location?.pathname;
    switch (path) {
      case "/":
        return "Agrigate App";
      case "/home":
        return "Agrigate App";
      case "/registration":
        return "Registration Form";
      case "/kyc":
        return "KYC Form";
      case "/survey":
        return "Survey Form";
      case "/consultation":
        return "Consultation Form";
      case "/sourcing":
        return "Sourcing Form";
      // case "/sales":
      //   return "Sales";
      case "/orderTaking":
        return "Order Taking";
      case "/contractFarming":
        return "Contract Farming";
      case "/history":
        return "Work History";
      default:
        return "";
    }
  };

  // Check if a menu item is active
  const isMenuItemActive = (menu) => {
    const path = location.pathname;
    return path.toLowerCase().includes(menu.toLowerCase());
  };

  return (
    <Box sx={{ display: "flex", m: 0 }}>
      {/* Set margin to 0 */}
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "#689f38" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={AgrigateLogo}
            alt="Agrigate Logo"
            style={{ marginRight: "10px", height: "30px" }}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {getCurrentRouteName()}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding onClick={() => handleMenuClick("Home")}>
            <ListItemButton
              component={Link}
              to="/home"
              sx={{
                backgroundColor: isMenuItemActive("Home")
                  ? "#addc88"
                  : "transparent",
              }}
            >
              <ListItemIcon>
                <img
                  src={AgrigateLogo}
                  alt="Agrigate Logo"
                  style={{ height: "24px" }}
                />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <Divider />
          {userPermissions?.includes("view_registration_menu") && (
            <ListItem
              disablePadding
              onClick={() => handleMenuClick("Registration")}
            >
              <ListItemButton
                component={Link}
                to="/registration"
                sx={{
                  backgroundColor: isMenuItemActive("Registration")
                    ? "#addc88"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <AppReistrationIcon />
                </ListItemIcon>
                <ListItemText primary="Registration" />
              </ListItemButton>
            </ListItem>
          )}

          {userPermissions?.includes("view_kyc_menu") && (
            <ListItem disablePadding onClick={() => handleMenuClick("KYC")}>
              <ListItemButton
                component={Link}
                to="/kyc"
                sx={{
                  backgroundColor: isMenuItemActive("KYC")
                    ? "#addc88"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <HowToRegIcon />
                </ListItemIcon>
                <ListItemText primary="KYC" />
              </ListItemButton>
            </ListItem>
          )}
          {userPermissions?.includes("view_survey_menu") && (
            <ListItem disablePadding onClick={() => handleMenuClick("Survey")}>
              <ListItemButton
                component={Link}
                to="/survey"
                sx={{
                  backgroundColor: isMenuItemActive("Survey")
                    ? "#addc88"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <PollIcon />
                </ListItemIcon>
                <ListItemText primary="Survey" />
              </ListItemButton>
            </ListItem>
          )}
          {userPermissions?.includes("view_consultation_menu_ops") && (
            <ListItem
              disablePadding
              onClick={() => handleMenuClick("Consultation")}
            >
              <ListItemButton
                component={Link}
                to="/consultation"
                sx={{
                  backgroundColor: isMenuItemActive("Consultation")
                    ? "#addc88"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <MedicationIcon />
                </ListItemIcon>
                <ListItemText primary="Consultation" />
              </ListItemButton>
            </ListItem>
          )}
          {userPermissions?.includes("view_product_sourcing_menu_ops") && (
            <ListItem
              disablePadding
              onClick={() => handleMenuClick("Sourcing")}
            >
              <ListItemButton
                component={Link}
                to="/sourcing"
                sx={{
                  backgroundColor: isMenuItemActive("Sourcing")
                    ? "#addc88"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText primary="Sourcing" />
              </ListItemButton>
            </ListItem>
          )}

          {userPermissions?.includes("view_contract_farming_menu") && (
            <ListItem
              disablePadding
              onClick={() => handleMenuClick("contractFarming")}
            >
              <ListItemButton
                component={Link}
                to="/contractFarming"
                sx={{
                  backgroundColor: isMenuItemActive("contractFarming")
                    ? "#addc88"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <HistoryEduIcon />
                </ListItemIcon>
                <ListItemText primary="Contract Farming" />
              </ListItemButton>
            </ListItem>
          )}
{/* 
          {userPermissions?.includes("view_ops_sales_menu") && (
            <ListItem
              disablePadding
              onClick={() => handleMenuClick("sales")}
            >
              <ListItemButton
                component={Link}
                to="/sales"
                sx={{
                  backgroundColor: isMenuItemActive("sales")
                    ? "#addc88"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Sales" />
              </ListItemButton>
            </ListItem>
          )} */}

          {userPermissions?.includes("view_order_taking_menu") && (
            <ListItem
              disablePadding
              onClick={() => handleMenuClick("OrderTaking")}
            >
              <ListItemButton
                component={Link}
                to="/orderTaking"
                sx={{
                  backgroundColor: isMenuItemActive("OrderTaking")
                    ? "#addc88"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <ContactPhoneIcon />
                </ListItemIcon>
                <ListItemText primary="Order Taking" />
              </ListItemButton>
            </ListItem>
          )}

          <ListItem disablePadding onClick={() => handleMenuClick("HelpDesk")}>
            <ListItemButton
              component={Link}
              to="/helpdesk"
              sx={{
                backgroundColor: isMenuItemActive("HelpDesk")
                  ? "#addc88"
                  : "transparent",
              }}
            >
              <ListItemIcon>
                <HelpCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Help Desk" />
            </ListItemButton>
          </ListItem>

          {userPermissions?.includes("view_work_history_menu") && (
            <ListItem disablePadding onClick={() => handleMenuClick("History")}>
              <ListItemButton
                component={Link}
                to="/history"
                sx={{
                  backgroundColor: isMenuItemActive("History")
                    ? "#addc88"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <WorkHistoryIcon />
                </ListItemIcon>
                <ListItemText primary="Work History" />
              </ListItemButton>
            </ListItem>
          )}
          <Divider />
          <ListItem disablePadding onClick={handleLogout}>
            <ListItemButton component={Link} to="/login">
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
