import React, { useContext, useEffect, useState } from "react";
import "./Order.css";
import { Form, Input, Select, Button, Row, Col, Spin, Space } from "antd";
import { loginAccess } from "../../..";
import axios from "axios";
import baseUrl from "../../../config";
import Swal from "sweetalert2";
import { PlusOutlined } from "@ant-design/icons";
import authHeader from "../../Authentication/authHeader";

export default function OrderTaking() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);
  const [productPriceList, setProductPriceList] = useState([]);
  const [filteredProductsList, setFilteredProductsList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orderType, setOrderType] = useState([]);
  const [district, setDistrict] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  const handleOrderType = (value) => {
    setOrderType(value);
    form.setFieldsValue({
      products: [],
    });
  };
  useEffect(() => {
    axios
      .get(`${baseUrl}/api/districts/`, {
        headers: authHeader(),
      })
      .then((response) => setDistrict(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/contractFarming/projects/`, {
        headers: authHeader(),
      })
      .then((response) => setProjects(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleRetailerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/retailer/retailer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            retailer_name: data?.data?.name,
            retailer_id: data?.data?.retailer_id,
            district: data?.data?.district?.district_name,
          });
          fetch(
            `https://script.google.com/macros/s/AKfycbxdfV8K01yaMhRXW7hbjp6qWs3xAKnpiydzymTU4nZcVSoIB8ubluG8yc5SZmbRLPlE/exec?phoneNumber=${phone}`
          )
            .then((response) => response.json())
            .then((data) => {
              if (!data?.error) {
                form.setFieldsValue({
                  bazar_name: data?.bazar_name,
                  route_no: data?.route_no,
                });
              } else {
                Swal.fire({
                  title: "Error",
                  text: data?.error,
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }

              console.log(data);
            });
        })
        .catch((error) => {
          form.setFieldsValue({
            retailer_name: undefined,
            retailer_id: undefined,
            district: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Retailer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
            district: data?.data?.district?.district_name,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
            district: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/retailer/productPriceList/`, {
        headers: authHeader(),
      })
      .then((response) => setProductPriceList(response?.data));
  }, []);

  const filterProductPriceList = (value, index) => {
    const filteredProducts = productPriceList.filter(
      (product) => product.category.toLowerCase() === value.toLowerCase()
    );
    setFilteredProductsList(filteredProducts);
    const newValues = form.getFieldsValue();
    newValues.products[index] = {
      ...newValues.products[index],
      product_name: null,
      quantity: undefined,
      product_total_price: undefined,
    };

    form.setFieldsValue({ products: [...newValues.products] });
  };

  const handleProductSelect = (value, index) => {
    const selectedProduct = filteredProductsList.find(
      (product) => product.product_id === value
    );
    setSelectedProduct(selectedProduct);
  };
  const handleProjectInfo = (value) => {
    const selectedProjectDetails = projects?.find(
      (project) => project.project_id === value
    );
    setSelectedProject(selectedProjectDetails);
  };

  const handleQuantityChange = (value, index) => {
    if (orderType.toLowerCase() === "retailer order") {
      const calculatedTotalPrice =
        selectedProduct.selling_price_retailer * value;
      const newValues = form.getFieldsValue();
      newValues.products[index].product_total_price = calculatedTotalPrice;

      form.setFieldsValue(newValues);
      calculateTotalPrice();
    } else {
      const calculatedTotalPrice = selectedProduct.selling_price_farmer * value;
      const newValues = form.getFieldsValue();
      newValues.products[index].product_total_price = calculatedTotalPrice;

      form.setFieldsValue(newValues);
      calculateTotalPrice();
    }
  };

  const calculateTotalPrice = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalPriceSum = products.reduce((sum, product) => {
      return sum + (product.product_total_price || 0); // Handle missing product_total_price
    }, 0);

    form.setFieldValue("total_price", newTotalPriceSum);
  };

  // const handleTotalPaidChange = () => {
  //   const totalPaid = form.getFieldValue('total_paid');
  //   const totalPrice = form.getFieldValue('total_price');

  //   const due = totalPrice - totalPaid;

  //   form.setFieldsValue({
  //     due: due.toFixed(2), // Adjust decimal places as needed
  //   });
  // };

  const today = new Date();
  const sevenDaysFromToday = new Date(today);
  const todaysDate = new Date(today);
  sevenDaysFromToday.setDate(today.getDate() + 7);
  todaysDate.setDate(today.getDate());
  const expectedDeliveryDate = sevenDaysFromToday.toISOString().slice(0, 10);
  const orderTakingDate = todaysDate.toISOString().slice(0, 10);

  const handleSubmit = (data) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/retailer/orderTaking/`,
        {
          data: data,
        },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        console.log(response?.data);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: "Order taken successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
            const formFields = form.getFieldsValue();
            const resetFields = Object.keys(formFields).filter(
              (field) => !excludedFields.includes(field)
            );
            form.resetFields(resetFields);
            setOrderType([]);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Order Taking Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Order Taking Date"
                name="order_date"
                rules={[
                  { required: true, message: "Order taking date required" },
                ]}
                initialValue={orderTakingDate}
              >
                <Input placeholder="Order Taking Date" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Order Type"
                name="order_type"
                rules={[
                  {
                    required: true,
                    message: "Please select order type",
                  },
                ]}
              >
                <Select
                  placeholder="Select order type"
                  onChange={handleOrderType}
                >
                  <Option value="Retailer Order">Retailer Order</Option>
                  <Option value="Farmer Order">Farmer Order</Option>
                  <Option value="Contract Farming Order">
                    Contract Farming Order
                  </Option>
                  <Option value="Agrigate Center Order">
                    Agrigate Center Order
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {orderType.includes("Farmer Order") && (
            <>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Farmer Phone Number"
                  name="phoneNumber"
                  onChange={handleFarmerInfo}
                  rules={[
                    {
                      required: true,
                      message: "Please enter farmer's phone number",
                    },
                    {
                      pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                      message: "Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input placeholder="Phone Number" type="tel" />
                </Form.Item>
              </Col>
              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Farmer Name"
                    name="farmer_name"
                    rules={[
                      {
                        required: true,
                        message: "Farmer name required",
                      },
                    ]}
                  >
                    <Input placeholder="Farmer Name" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Farmer ID"
                    name="farmer_id"
                    rules={[
                      {
                        required: true,
                        message: "Farmer ID required",
                      },
                    ]}
                  >
                    <Input placeholder="Farmer ID" type="tel" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="District"
                    name="district"
                    rules={[
                      {
                        required: true,
                        message: "District is required",
                      },
                    ]}
                  >
                    <Input placeholder="District" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {orderType.includes("Retailer Order") && (
            <>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Retailer Phone Number"
                  name="phone_number"
                  onChange={handleRetailerInfo}
                  rules={[
                    {
                      required: true,
                      message: "Please enter retailer's phone number",
                    },
                    {
                      pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                      message: "Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input placeholder="Retailer Phone Number" type="tel" />
                </Form.Item>
              </Col>
              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Retailer Name"
                    name="retailer_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter retailer's name",
                      },
                    ]}
                  >
                    <Input placeholder="Retailer Name" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Retailer ID"
                    name="retailer_id"
                    rules={[
                      {
                        required: true,
                        message: "Retailer ID is required",
                      },
                    ]}
                  >
                    <Input placeholder="Retailer ID" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="District"
                    name="district"
                    rules={[
                      {
                        required: true,
                        message: "District is required",
                      },
                    ]}
                  >
                    <Input placeholder="District" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Bazar Name"
                    name="bazar_name"
                    initialValue=""
                  >
                    <Input placeholder="Bazar Name" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Route No"
                    name="route_no"
                    initialValue=""
                  >
                    <Input placeholder="Route No" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {orderType.includes("Contract Farming Order") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item hasFeedback label="Project Name" name="project_id">
                  <Select
                    placeholder="Select Project"
                    onChange={handleProjectInfo}
                  >
                    {projects?.map((project, index) => (
                      <Option value={project?.project_id} key={index}>
                        {project?.project_name +
                          " - " +
                          project?.project_type +
                          " - " +
                          project?.year}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="District"
                  name="district"
                  rules={[
                    {
                      required: true,
                      message: "Please select district",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select District"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {selectedProject?.districts?.map((district, index) => (
                      <Option key={index} value={district}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Group Name"
                  name="group_name"
                  rules={[
                    {
                      required: true,
                      message: "Please select group name",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select District"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {selectedProject?.group_names?.map((group_name, index) => (
                      <Option key={index} value={group_name}>
                        {group_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          {orderType.includes("Agrigate Center Order") && (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="District"
                  name="district"
                  rules={[
                    {
                      required: true,
                      message: "Please select district",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select District"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {district?.map((district) => (
                      <Option
                        key={district?.district_id}
                        value={district?.district_name}
                      >
                        {district?.district_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Agrigate Center Name"
                  name="agrigate_center_name"
                  rules={[
                    {
                      required: true,
                      message: "Please select Agrigate center",
                    },
                  ]}
                >
                  <Select placeholder="Select Agrigate center">
                    <Option value="Birganj Center">Birganj Center</Option>
                    <Option value="Hatibandha Center">hatibandha Center</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.List name="products">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{
                      marginBottom: 20,
                      border: "dashed 1px gray",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Row gutter={[8, 16, 24, 32]}>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...restField}
                          label="Item Category"
                          name={[name, `item_category`]}
                          rules={[
                            {
                              required: true,
                              message: "Item Category is required",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Item Category"
                            onChange={(value) =>
                              filterProductPriceList(value, index)
                            }
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="Seed">Seed</Option>
                            <Option value="PGR">PGR</Option>
                            <Option value="Pesticides">Pesticides</Option>
                            <Option value="Micro-Nutrient">
                              Micro-Nutrient
                            </Option>
                            <Option value="Herbicides">Herbicides</Option>
                            <Option value="Fungicides">Fungicides</Option>
                            <Option value="Feed">Feed</Option>
                            <Option value="Bio Fertilizer">
                              Bio Fertilizer
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={18} sm={8} md={8} lg={6}>
                        <Form.Item
                          {...restField}
                          label="Product Name"
                          name={[name, `product_id`]}
                          rules={[
                            {
                              required: true,
                              message: "Select product name",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select product name"
                            showSearch
                            onChange={(value) =>
                              handleProductSelect(value, index)
                            }
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {filteredProductsList?.map((item) => (
                              <Option
                                key={item.product_id}
                                value={item.product_id}
                              >
                                {item.product_name_english}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={18} sm={8} md={8} lg={6}>
                        <Form.Item
                          {...restField}
                          label="Quantity"
                          name={[name, `quantity`]}
                          rules={[
                            {
                              required: true,
                              message: "Purchase quantity required",
                            },
                            {
                              pattern: /^[0-9]+$/,
                              message: "Only english number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Quantity"
                            onChange={(e) =>
                              handleQuantityChange(e.target.value, index)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={18} sm={8} md={8} lg={6}>
                        <Form.Item
                          {...restField}
                          label="Product Total Price"
                          name={[name, `product_total_price`]}
                          rules={[
                            {
                              required: true,
                              message: "Product Total Price required",
                            },
                            {
                              pattern: /^[0-9]+(\.[0-9]+)?$/,
                              message: "Only english number",
                            },
                          ]}
                        >
                          <Input placeholder="Product Total Price" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item>
                          <Button
                            onClick={() => {
                              form.setFieldsValue({
                                total_price: undefined,
                                due: undefined,
                                total_paid: undefined,
                              });
                              remove(name);
                              calculateTotalPrice();
                            }}
                            style={{
                              backgroundColor: "#c21807",
                              color: "#ffffff",
                            }}
                          >
                            <strong>Remove</strong>
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))}
                <Col xs={24} sm={12} md={8} lg={11}>
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={() => add()}
                      block
                      size="small"
                      icon={<PlusOutlined />}
                      style={{ color: "#000000" }}
                    >
                      Add product
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              hasFeedback
              label="Total Price"
              name="total_price"
              rules={[
                {
                  required: true, // Regular expression to match English characters and spaces
                  message: "Total price missing",
                },
              ]}
            >
              <Input placeholder="Total Price" disabled />
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Expected Delivery Date"
                name="expected_delivery_date"
                initialValue={expectedDeliveryDate}
              >
                <Input placeholder="Expected Delivery Date" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Remarks"
                name="remarks"
                initialValue=""
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/, // Regular expression to match English characters and spaces
                    message: "Only English Characters",
                  },
                ]}
              >
                <Input placeholder="Remarks" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
