import React, { useContext, useEffect, useState } from "react";
import "../Form.css";
import { loginAccess } from "../../..";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Space,
  Spin,
  Upload,
  Select,
} from "antd";
import Address from "../../CommonProperty/Address";
import {
  EnvironmentOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ByProductAdd from "../../CommonProperty/ByProductAdd";
import Swal from "sweetalert2";
import awsS3 from "aws-sdk/clients/s3";

export default function AgroProcessorSurvey() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { isLoggedIn, userPermissions, setIsLoggedIn, setUserPermissions } =
    useContext(loginAccess);
  const [loading, setLoading] = useState(false);
  const [signboardImage, setSignboardImage] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      employee_full_name:
        isLoggedIn?.loggedInUser?.first_name +
        " " +
        isLoggedIn?.loggedInUser?.last_name,
      employee_id: isLoggedIn?.loggedInUser?.employee_id,
    });
  }, [form, isLoggedIn]);

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          form.setFieldsValue({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = (data) => {
    if (data?.products?.length > 0) {
      data.signboard_image = signboardImage;
      setLoading(true);
      fetch(
        "https://script.google.com/macros/s/AKfycbyeL9yZy7PkXuAw7zKHOrrpwucqSQ2AKLelTCR6_3PnWT0dT55YdW01IvWLOHl5Xv3t/exec",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status.toLowerCase() === "success") {
            Swal.fire({
              title: "Success",
              text: "Agro Processor Mill Survey Successful",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              const excludedFields = ["employee_id", "employee_full_name"]; // Add the names/IDs of disabled fields to exclude
              const formFields = form.getFieldsValue();
              const resetFields = Object.keys(formFields).filter(
                (field) => !excludedFields.includes(field)
              );

              form.resetFields(resetFields);
            });
          } else {
            setLoading(false);
            Swal.fire({
              title: "error",
              text: "Error",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          Swal.fire({
            title: "error",
            text: error,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } else {
      Swal.fire({
        title: "error",
        text: "Please add at least one product",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <div className="form-container">
      <header
        className="mb-5 mt-4"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Agro Processor Survey Form
      </header>
      <div className="container m-auto">
        <Form
          className="register-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Name of The Mill"
                name="mill_name"
                rules={[
                  { required: true, message: "Please enter mill name" },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Mill Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Mill Type"
                name="mill_type"
                rules={[
                  {
                    required: true,
                    message: "Please select mill type",
                  },
                ]}
              >
                <Select placeholder="Select Mill Type">
                  <Option value="Rice Mill">Rice Mill</Option>
                  <Option value="Feed Mill">Feed Mill</Option>
                  <Option value="Flour Mill">Flour Mill</Option>
                  <Option value="Oil Mill">Oil Mill</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* proprietor */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Proprietor"
                name="proprietor"
                rules={[
                  {
                    required: true,
                    message: "Please enter proprietor",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input placeholder="Proprietor" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* GPS */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="latitude"
                label="Latitude"
                rules={[
                  {
                    required: true,
                    message: "Latitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Latitude" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                name="longitude"
                label="Longitude"
                rules={[
                  {
                    required: true,
                    message: "Longitude required",
                  },
                ]}
              >
                <Input disabled placeholder="Longitude" />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button
                type="primary"
                icon={<EnvironmentOutlined />}
                onClick={handleGetCurrentLocation}
              >
                Get Current Location
              </Button>
            </Form.Item>
          </Col>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* manager's phone number */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Manager's Phone Number"
                name="manager_phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter manager's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="Manager's Phone Number" type="tel" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Signboard Image"
                name="signboard_image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG, or HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) =>
                    handleImageUpload(info, setSignboardImage)
                  }
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                  >
                    {uploading ? "Uploading" : "Upload signboard image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Mill Size */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Size of Mill"
                name="mill_size"
                rules={[
                  {
                    required: true,
                    message: "Please select mill size",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio.Button value="Big">Big</Radio.Button>
                  <Radio.Button value="Medium">Medium</Radio.Button>
                  <Radio.Button value="Small">Small</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            {/* Automation type */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Automation Type"
                name="automation_type"
                rules={[
                  {
                    required: true,
                    message: "Please select automation type",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio.Button value="Automated">Automated</Radio.Button>
                  <Radio.Button value="Semi_Automated">
                    Semi-Automated
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="products">
            {(productFields, { add: addProduct, remove: removeProduct }) => (
              <>
                {productFields.map((productField, productIndex) => (
                  <Space
                    key={productField.key}
                    style={{
                      marginBottom: 20,
                      border: "dashed 1px gray",
                      padding: "12px",
                      borderRadius: "5px",
                    }}
                  >
                    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...productField}
                          label="Product Name"
                          name={[productField.name, "product_name"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing product name",
                            },
                            {
                              pattern: /^[A-Za-z\s]+$/,
                              message: "Only English characters",
                            },
                          ]}
                        >
                          <Input placeholder="Product Name" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...productField}
                          label="Product Quantity (TON)"
                          name={[productField.name, "product_quantity"]}
                          initialValue={0}
                          rules={[
                            {
                              pattern: /^[0-9]+$/,
                              message: "Only English numbers",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Product Quantity (TON)"
                            inputMode="numeric"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item
                          {...productField}
                          label="Product Price (Per KG)"
                          name={[productField.name, "product_price"]}
                          initialValue={0}
                          rules={[
                            {
                              pattern: /^[0-9]+$/,
                              message: "Only English numbers",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Product Price (Per KG)"
                            inputMode="numeric"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item>
                          <Button
                            onClick={() => {
                              removeProduct(productField.name);
                            }}
                            style={{
                              backgroundColor: "#c21807",
                              color: "#ffffff",
                            }}
                          >
                            <strong>Remove</strong>
                          </Button>
                        </Form.Item>
                      </Col>
                      <ByProductAdd
                        parentName={productField.name}
                        removeProduct={removeProduct}
                      />
                    </Row>
                  </Space>
                ))}

                <Col xs={24} sm={12} md={8} lg={11}>
                  <Form.Item>
                    <Button
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      type="default"
                      onClick={() => addProduct()}
                      block
                      size="small"
                      icon={<PlusOutlined />}
                      style={{ color: "#000000" }}
                    >
                      Add Product
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>
          <Address />
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Field Officer Name */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer Name"
                name="employee_full_name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* Field Officer ID */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Field Officer ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please select field officer ID" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
